import { t } from 'i18next';
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../styled-ui';

const StyledText = styled.div`
  max-width: calc(100% - 32px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledIcon = styled.i`
  cursor: pointer;
  padding: var(--xsmall-padding);
  color: var(--red-main);
  font-size: var(--heading-small-font-size);

  > svg {
    display: flex;
  }
`;

const StyledInput = styled.input`
  font-family: inherit !important;
  font-size: inherit !important;
  line-height: inherit !important;
  font-weight: inherit !important;
  padding: 0;
  border: none;
  box-shadow: none;
  outline: none;
  min-width: 100%;
  background: var(--beige-main);
  margin-right: var(--xsmall-padding);
  caret-color: var(--red-main);
`;

type EditableTextProps = {
  value: string;
  saveValue: (text: string) => void;
};

export const EditableText: React.FC<EditableTextProps> = ({
  value,
  saveValue,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [text, setText] = useState(value);
  const textRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const hiddenSpanRef = useRef<HTMLElement>(null);
  const [textWidth, setTextWidth] = useState<number>(0);

  useEffect(() => {
    if (textRef.current && !isEditMode) {
      setTextWidth(textRef.current.offsetWidth);
    } else if (inputRef.current && isEditMode) {
      inputRef.current.style.width = `${textWidth}px`;
    }
  }, [isEditMode, textWidth]);

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (hiddenSpanRef.current) {
      hiddenSpanRef.current.innerHTML = e.target.value.replace(/\s/g, '&nbsp;');
      e.target.style.width = `${hiddenSpanRef.current.offsetWidth}px`;
    }
    setText(e.target.value);
  };

  const handleSave = () => {
    saveValue(text);
    setIsEditMode(false);
  };

  return !isEditMode ? (
    <div className="flex align-items-center w-full">
      <StyledText
        className="title"
        ref={textRef}
        onClick={() => setIsEditMode(true)}
      >
        {text}
      </StyledText>
      <Tooltip
        position="right"
        target=".title"
        content={t('generic.clickToEdit')}
      />
      {!text.length && (
        <>
          <StyledIcon className="pi pi-exclamation-circle info" />
          <Tooltip
            position="right"
            target=".info"
            content={t('error.enterName')}
          />
        </>
      )}
    </div>
  ) : (
    <FlexContainer justify="flex-start" className="relative" width="100%">
      <StyledInput
        ref={inputRef}
        autoFocus
        value={text}
        onChange={handleTextChange}
        onBlur={handleSave}
      />
    </FlexContainer>
  );
};
