import { like } from '@/api/helpers';
import { FiltersType, ListingRequest, ListingResponse } from '@/api/types';
import { ITEMS_PER_LAZY_REQUEST, partialRequests } from '@/utils/helpers';
import { debounce } from 'lodash';
import { InputText } from 'primereact/inputtext';
import {
  MultiSelect,
  MultiSelectAllEvent,
  MultiSelectChangeEvent,
  MultiSelectProps,
} from 'primereact/multiselect';
import { Skeleton } from 'primereact/skeleton';
import {
  VirtualScroller,
  VirtualScrollerLazyEvent,
} from 'primereact/virtualscroller';
import React, {
  HTMLAttributes,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

type LazyLoadingMultiselectProps = {
  fetchService?: (params: ListingRequest) => Promise<ListingResponse<any>>;
  handleOnChange: (values: any[]) => void;
  selectedValues: any[];
  selectedValueFilterBy?: string;
  additionalFilters?: FiltersType;
  optionFilter?: (value: string) => FiltersType;
  refetchKey?: number;
  isDisabled?: boolean;
  maxSelectedItems?: number;
} & MultiSelectProps &
  HTMLAttributes<HTMLSelectElement>;

export const LazyLoadingMultiselect: React.FC<LazyLoadingMultiselectProps> = ({
  fetchService,
  handleOnChange,
  selectedValues = [],
  selectedValueFilterBy = 'id',
  additionalFilters,
  optionFilter = (value) => [like('name', value)],
  refetchKey = 0,
  isDisabled,
  maxSelectedItems,
  ...rest
}) => {
  const ref = useRef<MultiSelect>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const [skip, setSkip] = useState(0);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState<FiltersType>([]);
  const [filterValue, setFilterValue] = useState<string>('');
  const [selectAll, setSelectAll] = useState(false);
  const [allItemsCount, setAllItemsCount] = useState(0);

  const handleFetchItems = async (
    filter?: FiltersType,
    isFilterOrRefetch?: boolean,
  ) => {
    setIsLoading(true);

    if (isFilterOrRefetch) {
      setSkip(0);
    }

    const request = fetchService
      ? await fetchService({
          filters: [...(additionalFilters ?? []), ...(filter ?? [])],
          skip: isFilterOrRefetch ? 0 : skip,
          take: ITEMS_PER_LAZY_REQUEST,
        })
      : undefined;

    const fetchedData = request?.result ?? [];
    setAllItemsCount(request?.count || 0);

    if (isFilterOrRefetch) {
      setData([]);
    }

    setCount(request?.count ?? 0);
    setData((prevData) => [...prevData, ...fetchedData]);

    setIsLoading(false);
  };

  useEffect(() => {
    if (!refetchKey) return;

    const handleRefetch = async () => {
      await handleFetchItems(undefined, true);
    };

    handleRefetch();
  }, [refetchKey]);

  useEffect(() => {
    handleFetchItems();
    setSkip(skip + ITEMS_PER_LAZY_REQUEST);
  }, []);

  useEffect(() => {
    setData(data);
  }, [selectedValues, data]);

  useEffect(() => {
    handleFetchAllItems();
  }, [selectAll]);

  const onLazyLoad = (event: VirtualScrollerLazyEvent) => {
    if (!isLoading && Number(event.last) >= data.length && skip <= count) {
      setSkip(skip + ITEMS_PER_LAZY_REQUEST);
      handleFetchItems(filter);
    }
  };

  const onFilter = useMemo(
    () =>
      debounce((event) => {
        setSkip(0);
        setFilter(optionFilter(event?.target?.value));
        handleFetchItems(optionFilter(event?.target?.value), true);
        setSkip(skip + ITEMS_PER_LAZY_REQUEST);

        if (data?.length > ITEMS_PER_LAZY_REQUEST && ref.current) {
          const vs: VirtualScroller = (ref.current as any).getVirtualScroller();
          vs?.scrollInView(1, 'to-end');
        }
      }, 500),
    [],
  );

  const loadingTemplate = () => (
    <div className="flex align-items-center p-2">
      <Skeleton height="32px" />
    </div>
  );

  const filterTemplate = (
    <div className="p-multiselect-filter-container w-full">
      <span className="p-input-icon-right w-full">
        <i className="pi pi-search" />
        <InputText
          className="p-multiselect-filter p-inputtext p-component"
          placeholder={rest.placeholder}
          autoFocus
          defaultValue={filterValue}
          onChange={onFilter}
          onBlur={(e) => setFilterValue(e.target.value)}
        />
      </span>
    </div>
  );

  const virtualScrollerOptions = () => {
    if (data?.length > 8) {
      return {
        itemSize: 50,
        lazy: true,
        onScrollIndexChange: onLazyLoad,
        onLazyLoad: () => setData(data),
        loadingTemplate,
        showLoader: true,
        loading: isLoading,
        step: ITEMS_PER_LAZY_REQUEST,
        autoSize: true,
      };
    }

    return undefined;
  };

  const handleFetchAllItems = async () => {
    if (!fetchService) return;

    if (selectAll && data.length !== allItemsCount) {
      setIsLoading(true);

      const fetchedData = await partialRequests(
        [...(additionalFilters ?? [])],
        fetchService,
      );

      setData(fetchedData);
      handleOnChange(fetchedData);

      setIsLoading(false);
    } else if (selectAll && data.length === allItemsCount) {
      handleOnChange(data);
    } else {
      handleOnChange([]);
    }
  };

  return (
    <MultiSelect
      ref={ref}
      dataKey="id"
      filterBy="name"
      value={selectedValues}
      dropdownIcon={isLoading ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'}
      options={data}
      disabled={isDisabled}
      virtualScrollerOptions={virtualScrollerOptions()}
      filterTemplate={filterTemplate}
      onChange={(e: MultiSelectChangeEvent) => {
        setSelectAll(allItemsCount === e.value.length);
        handleOnChange(e.value);
      }}
      onSelectAll={(e: MultiSelectAllEvent) => setSelectAll(!e.checked)}
      selectAll={selectAll}
      maxSelectedLabels={maxSelectedItems}
      // workaround as maxSelectedLabels is not working properly with display='chip'
      display={
        !maxSelectedItems ||
        (maxSelectedItems && selectedValues.length <= maxSelectedItems)
          ? 'chip'
          : 'comma'
      }
      {...(rest as MultiSelectProps)}
    />
  );
};
