import { AppUnauthorized } from '@/app';
import { client } from '@/client';
import { AppRoutesPaths, LAST_SELECTED_LANGUAGE } from '@/common/constants';
import { keycloakLanguages } from '@/common/constants/languages';
import { LanguageContext } from '@/common/context/LanguageContext';
import { LoadingPage } from '@/pages';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { Navigate } from 'react-router-dom';

type AppKeycloakRedirectIfNotLoggedInProps = {
  children: React.ReactNode;
};

export const AuthKeycloakRedirectIfNotLogged: React.FC<
  AppKeycloakRedirectIfNotLoggedInProps
> = ({ children }) => {
  const auth = useAuth();
  const { t } = useTranslation();
  const [isInterceptorSet, setIsInterceptorSet] = useState<boolean>(false);
  const { changeLanguage } = useContext(LanguageContext);
  const queryParams = new URLSearchParams(location.search);

  const getKeyCloakToken = (): string => {
    return auth.user?.access_token as string;
  };

  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading
    ) {
      const login_hint = queryParams.get('login_hint') || undefined;
      const ui_locales = queryParams.get('ui_locales') || undefined;

      // Preserve the selected language from the login page between the auth redirects
      if (ui_locales) {
        for (const [key, lang] of Object.entries(keycloakLanguages)) {
          if (lang.value === ui_locales) {
            changeLanguage(key);
            localStorage.setItem(LAST_SELECTED_LANGUAGE, key);
          }
        }
      }

      auth.signinRedirect({
        login_hint,
        ui_locales,
      });
    }
  }, [
    auth.isAuthenticated,
    auth.activeNavigator,
    auth.isLoading,
    auth.signinRedirect,
  ]);

  useEffect(() => {
    if (!auth.isAuthenticated) return;

    const interceptor = client.axiosRef.interceptors.request.use((config) => {
      const token = getKeyCloakToken();

      (config.headers as any).set('Authorization', `Bearer ${token}`);

      return config;
    });

    setIsInterceptorSet(true);

    return () => {
      client.axiosRef.interceptors.request.eject(interceptor);
    };
  }, [auth.isAuthenticated, auth?.user?.access_token]);

  if (!isInterceptorSet) {
    return <LoadingPage message={t('user.loading')} />;
  }

  if (auth.activeNavigator === 'signinRedirect') {
    return <AppUnauthorized text={t('auth.signIn.redirect')} />;
  }

  if (!auth.isAuthenticated) {
    return <Navigate to={AppRoutesPaths.UNAUTHORIZED_ROUTE} />;
  }

  return <>{children}</>;
};
