import { LanguagesEnum } from '@/api/enums';
import { handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import { CourseFormState, EfrontCourse, MaterialType } from '@/client/courses';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { MaterialsForm } from '@/components/resources/forms';
import { useSaveCourse } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { FlexContainer } from '@/ui/styled-ui';
import {
  generateCourseTranslationsInitialValues,
  materialByTypeCreatePath,
  materialByTypeListPath,
  parseModifiedCourseFiles,
  parseModifiedCourseTranslations,
  queryStateConverter,
  updateMaterialByTypePath,
} from '@/utils/helpers';
import { AxiosError } from 'axios';
import { FormikValues } from 'formik';
import { MenuItem } from 'primereact/menuitem';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const getBreadcrumbs = (
  type: MaterialType,
  account: Account,
  t: TranslationFunctionType,
): MenuItem[] => {
  const isResource = type === MaterialType.RESOURCE;
  const isPoster = type === MaterialType.POSTER;
  return [
    {
      label: account?.name,
      url: !account?.isSystem
        ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id)
        : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
      template: AppBreadCrumbTemplate,
    },
    {
      label: isResource
        ? t('resources')
        : isPoster
        ? t('posters')
        : t('custom.materials'),
      url: materialByTypeListPath(type),
      className: 'active',
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('generic.createNew'),
      url: materialByTypeCreatePath(type),
      template: AppBreadCrumbTemplate,
    },
  ];
};

export const MaterialCreatePage: React.FC<{ type: MaterialType }> = ({
  type,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const account = useAppSelector(selectCurrentAccount);

  const [formInitialValues] = useState<CourseFormState>({
    courseTranslations: generateCourseTranslationsInitialValues(),
    courseLanguages: [LanguagesEnum.EN],
    courseThumbnail: '',
    courseFiles: [
      {
        [LanguagesEnum.EN]: {
          file: null,
        },
      },
    ],
    courseCreatedAt: undefined,
    courseLastUpdatedAt: undefined,
    type: type ?? MaterialType.RESOURCE,
    trial: false,
    isStandardCatalogue: false,
    releaseDate: null,
    customContent: false,
    customAccount: null,
    selfHosted: true,
    hubspotProperty: undefined,
    hubspotDateProperty: undefined,
    categories: undefined,
  });

  const createCourse = useSaveCourse();

  const handleSubmit = async (data: FormikValues) => {
    try {
      if (!account) return;

      const response = await createCourse.create({
        courseTranslations: parseModifiedCourseTranslations(
          data.courseTranslations,
        ),
        courseLanguages: data.courseLanguages,
        courseThumbnail: data.courseThumbnail ? data.courseThumbnail : null,
        courseFiles: parseModifiedCourseFiles(data.courseFiles, {
          selfHosted: data.selfHosted,
          courseLanguages: data.courseLanguages,
        }),
        courseCreatedAt: data.courseCreatedAt,
        courseLastUpdatedAt: data.courseLastUpdatedAt,
        type: data.type,
        trial: data.trial,
        isStandardCatalogue: data.isStandardCatalogue,
        releaseDate: data.releaseDate,
        account:
          type === MaterialType.CUSTOM_MATERIAL && !account.isSystem
            ? account.id
            : data.customContent && !!data.customAccount
            ? data.customAccount
            : null,
        eFrontCourseIds: data.eFrontCourses?.map(
          (course: EfrontCourse) => course.id,
        ),
        hubspotProperty: data.hubspotProperty,
        hubspotDateProperty: data.hubspotDateProperty,
        categories: data.categories,
      });

      toast?.success(t('toast.success'), t('material.created'));

      navigate(updateMaterialByTypePath(type, response.id));
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  return (
    <>
      <AppBreadCrumb model={getBreadcrumbs(type, account as Account, t)} />
      <FlexContainer justify="flex-start">
        <MaterialsForm
          initialValues={formInitialValues}
          onSubmit={handleSubmit}
          state={queryStateConverter(createCourse)}
          isCreate
        />
      </FlexContainer>
    </>
  );
};
