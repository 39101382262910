import { DataTableColumnType } from '../types';

export const getSelectableColumns = (
  columns: DataTableColumnType[],
  alwaysVisibleColumns: string[],
) => {
  return columns
    .filter(
      (column) =>
        !alwaysVisibleColumns.includes(column.exportName || column.field),
    )
    .map((column) => {
      return {
        label: column?.iconLabel || column.header,
        value: column?.exportName || column.field,
      };
    });
};
