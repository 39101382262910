import { processListingParams } from '@/api/helpers';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { omit } from 'lodash';
import { getExceptionFromAxiosError } from '../helpers';
import { MessageResponseModel } from '../models';
import {
  DeleteSurveyRequest,
  ListSurveyCategoriesResponse,
  ListSurveyCategoryQuestionsRequest,
  ListSurveyCategoryQuestionsResponse,
  ListSurveyCategoryRecommendationsRequest,
  ListSurveyCategoryRecommendationsResponse,
  ListSurveyFeedbackRequest,
  ListSurveyFeedbackResponse,
  ListSurveysRequest,
  ListSurveysResponse,
  SecurityCultureSendSurveyRequest,
  SecurityCultureSendSurveyResponse,
  SecurityCultureSurvey,
  SecurityCultureUploadSurveyRequest,
  SurveyCategory,
  SurveyCategoryEnum,
} from './types';

export const securityCultureService = (client: AxiosInstance) => {
  const SURVEY_BASE_URL = (accountId?: string) =>
    `/accounts/${accountId}/surveys`;
  const SURVEY_CATEGORIES_URL = (accountId?: string, surveyId?: string) =>
    `/accounts/${accountId}/surveys/${surveyId}/categories`;

  const sendServeyMails = async (
    request: SecurityCultureSendSurveyRequest,
  ): Promise<SecurityCultureSendSurveyResponse> => {
    try {
      const result = await client.post<
        SecurityCultureSendSurveyResponse,
        AxiosResponse<SecurityCultureSendSurveyResponse>
      >(
        `${SURVEY_BASE_URL(request.accountId)}/send`,
        omit(request, 'accountId'),
        {
          withCredentials: true,
        },
      );
      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const uploadSurvey = async (
    request: SecurityCultureUploadSurveyRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(SURVEY_BASE_URL(request.accountId), omit(request, 'accountId'), {
        withCredentials: true,
      });
      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const editSurvey = async (
    request: SecurityCultureUploadSurveyRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.patch<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(
        `${SURVEY_BASE_URL(request.accountId)}/${request.id}`,
        omit(request, 'accountId'),
        {
          withCredentials: true,
        },
      );
      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const deleteSurvey = async (
    request: DeleteSurveyRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.delete(
        `${SURVEY_BASE_URL(request.accountId)}/${request.surveyId}`,
        {
          withCredentials: true,
        },
      );

      return new MessageResponseModel(result.data);
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getSurveys = async (
    accountId: string | undefined,
    params: ListSurveysRequest,
  ): Promise<ListSurveysResponse> => {
    try {
      const result = await client.get<
        ListSurveysResponse,
        AxiosResponse<ListSurveysResponse>
      >(SURVEY_BASE_URL(accountId), {
        withCredentials: true,
        params: processListingParams(params),
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getSurvey = async (
    accountId: string | undefined,
    surveyId: string | undefined,
  ): Promise<SecurityCultureSurvey> => {
    try {
      const result = await client.get<
        SecurityCultureSurvey,
        AxiosResponse<SecurityCultureSurvey>
      >(`${SURVEY_BASE_URL(accountId)}/${surveyId}`, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getSurveyCategories = async (
    accountId: string | undefined,
    surveyId: string | undefined,
  ): Promise<ListSurveyCategoriesResponse> => {
    try {
      const result = await client.get<
        ListSurveyCategoriesResponse,
        AxiosResponse<ListSurveyCategoriesResponse>
      >(SURVEY_CATEGORIES_URL(accountId, surveyId), {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getSurveyCategory = async (
    accountId: string,
    surveyId: string,
    categoryName: SurveyCategoryEnum,
  ): Promise<SurveyCategory> => {
    try {
      const result = await client.get<
        SurveyCategory,
        AxiosResponse<SurveyCategory>
      >(`${SURVEY_CATEGORIES_URL(accountId, surveyId)}/${categoryName}`, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getSurveyCategoryQuestions = async (
    params: ListSurveyCategoryQuestionsRequest,
  ): Promise<ListSurveyCategoryQuestionsResponse> => {
    try {
      const result = await client.get<
        ListSurveyCategoryQuestionsResponse,
        AxiosResponse<ListSurveyCategoryQuestionsResponse>
      >(
        `${SURVEY_CATEGORIES_URL(params.accountId, params.surveyId)}/${
          params.categoryName
        }/questions`,
        {
          withCredentials: true,
          params: processListingParams(params),
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getSurveyCategoryRecommendations = async (
    params: ListSurveyCategoryRecommendationsRequest,
  ): Promise<ListSurveyCategoryRecommendationsResponse> => {
    try {
      const result = await client.get<
        ListSurveyCategoryRecommendationsResponse,
        AxiosResponse<ListSurveyCategoryRecommendationsResponse>
      >(
        `${SURVEY_CATEGORIES_URL(params.accountId, params.surveyId)}/${
          params.categoryName
        }/recommendations`,
        {
          withCredentials: true,
          params: processListingParams(params),
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getSurveyFeedback = async (
    params: ListSurveyFeedbackRequest,
  ): Promise<ListSurveyFeedbackResponse> => {
    try {
      const result = await client.get<
        ListSurveyFeedbackResponse,
        AxiosResponse<ListSurveyFeedbackResponse>
      >(`${SURVEY_BASE_URL(params.accountId)}/${params.surveyId}/feedback`, {
        withCredentials: true,
        params: processListingParams(params),
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getSurveyTargets = async (accountId?: string): Promise<any> => {
    try {
      const result = await client.get<
        ListSurveyFeedbackResponse,
        AxiosResponse<ListSurveyFeedbackResponse>
      >(`/accounts/${accountId}/survey-targets`, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const surveyEmailNotifyUrl = (accountId: string) =>
    `${client.defaults.baseURL}accounts/${accountId}/surveys/notify`;

  const surveyNotifyUrl = (surveyId: string, accountId: string) =>
    `${client.defaults.baseURL}accounts/${accountId}/surveys/${surveyId}/notify`;

  return {
    sendServeyMails,
    uploadSurvey,
    editSurvey,
    deleteSurvey,
    getSurveys,
    getSurvey,
    getSurveyCategories,
    getSurveyTargets,
    surveyEmailNotifyUrl,
    surveyNotifyUrl,
    getSurveyCategory,
    getSurveyCategoryQuestions,
    getSurveyCategoryRecommendations,
    getSurveyFeedback,
  };
};
