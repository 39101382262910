import { processListingParams } from '@/api/helpers';
import { getExceptionFromAxiosError } from '@/client/helpers';
import {
  ListLearnerCoursesRequest,
  ListLearnerCoursesResponse,
  UpdateCourseAttemptRequest,
} from '@/client/learner-course';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import {
  CourseAttempt,
  CourseStatusAttemptEnum,
  FilterableCourseAttemptStatus,
} from '../courses';

export const learnerService = (client: AxiosInstance) => {
  const LEARNER_COURSE_BASE_URL = '/me/courses';

  const getLearnerCourses = async (
    params: ListLearnerCoursesRequest & {
      type?: FilterableCourseAttemptStatus;
    },
  ): Promise<ListLearnerCoursesResponse> => {
    try {
      const result = await client.get<
        ListLearnerCoursesResponse,
        AxiosResponse<ListLearnerCoursesResponse>
      >(LEARNER_COURSE_BASE_URL, {
        withCredentials: true,
        params: processListingParams(params),
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getLearnerCourseLastAttempt = async (
    courseId?: string,
  ): Promise<CourseAttempt> => {
    try {
      const result = await client.get<
        CourseAttempt,
        AxiosResponse<CourseAttempt>
      >(`${LEARNER_COURSE_BASE_URL}/${courseId}/attempt`, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getLearnerCourseAttempt = async (
    courseId?: string,
    attemptId?: string,
  ): Promise<CourseAttempt> => {
    try {
      const result = await client.get<
        CourseAttempt,
        AxiosResponse<CourseAttempt>
      >(`${LEARNER_COURSE_BASE_URL}/${courseId}/attempt/${attemptId}`, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const createLearnerCourseNewAttempt = async (
    courseId: string,
    status: CourseStatusAttemptEnum = CourseStatusAttemptEnum.IN_PROGRESS,
  ): Promise<CourseAttempt> => {
    try {
      return (
        await client.post(`${LEARNER_COURSE_BASE_URL}/${courseId}/attempt`, {
          status,
          withCredentials: true,
        })
      ).data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const updateCourseAttempt = async (
    params: UpdateCourseAttemptRequest,
  ): Promise<CourseAttempt> => {
    try {
      const result = await client.patch<
        CourseAttempt,
        AxiosResponse<CourseAttempt>
      >(
        `${LEARNER_COURSE_BASE_URL}/${params.courseId}/attempt/${params.attemptId}`,
        { ...params.payload, language: params.language },
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  return {
    getLearnerCourses,
    getLearnerCourseLastAttempt,
    getLearnerCourseAttempt,
    createLearnerCourseNewAttempt,
    updateCourseAttempt,
  };
};
