import { useEffect } from 'react';

import { AccountTypeEnum } from '@/client/accounts';
import { useAppSelector } from '@/hooks/store';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { clarity } from 'react-microsoft-clarity';
import { useLocation } from 'react-router-dom';

const ClarityTracking = () => {
  const currentUser = useAppSelector(selectCurrentUser);
  const currentAccount = useAppSelector(selectCurrentAccount);
  const location = useLocation();

  // Temporary allow Clarity for Jysk Fynske Medier, for debugging course completions
  const jyskFynskeMedierId = '6fb57e71-086f-45d9-a410-58b570b904ce';

  if (
    process.env.REACT_APP_CLARITY_PROJECT_ID &&
    (currentUser?.freeTrialEndsAt ||
      currentUser?.account.id === jyskFynskeMedierId)
  ) {
    clarity.init(process.env.REACT_APP_CLARITY_PROJECT_ID);
  }

  // Set clarity tags based on currentUser and currentAccount
  useEffect(() => {
    if (clarity.hasStarted() && currentUser && currentAccount) {
      clarity.setTag('UserType', currentUser.role.code);
      clarity.setTag('AccountName', currentAccount.name);
      clarity.setTag('FreeTrial', currentAccount.isFreeTrial ? 'Yes' : 'No');
      clarity.setTag(
        'TestAccount',
        currentAccount.type === AccountTypeEnum.DEMO ? 'Yes' : 'No',
      );
    }
  }, [location.pathname, currentUser, currentAccount]);

  return null;
};

export default ClarityTracking;
