import { FlexContainer } from '@/ui/styled-ui';
import {
  bigPadding,
  colorBlackMain,
  colorRedLight,
  colorRedMain,
  headingMediumFontSize,
} from '@/utils/helpers/css-variables.helper';
import { Chart as Chartjs } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from 'primereact/chart';
import React, { useEffect, useState } from 'react';
import { tooltipDefaultSettings } from '../charts.helper';

Chartjs.register(ChartDataLabels);

export type BarChartProps = {
  id: string;
  value: number;
  secondaryValue: number;
  width?: string;
  height?: string;
  displayXAxis?: boolean;
  tooltip?: string;
  defaultTooltip?: string;
  benchmarkTooltip?: string;
  maxValue?: number;
};

export const BarChart: React.FC<BarChartProps> = ({
  id,
  value,
  secondaryValue,
  width = '180',
  height = 'auto',
  displayXAxis,
  tooltip,
  defaultTooltip,
  benchmarkTooltip,
  maxValue,
}) => {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const docStyle = getComputedStyle(document.documentElement);

  useEffect(() => {
    const data = {
      labels: [id],
      datasets: [
        {
          backgroundColor: [colorRedMain(docStyle)],
          data: [value],
        },
        {
          backgroundColor: [colorRedLight(docStyle)],
          data: [secondaryValue],
        },
      ],
    };
    const options = {
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          color: [colorBlackMain(docStyle)],
          font: {
            weight: 'bold',
            size: headingMediumFontSize(docStyle),
          },
          anchor: 'end',
          align: 'top',
          display: (context: any) => {
            return context.dataset.data[context.dataIndex] > 0;
          },
        },
        tooltip: {
          ...tooltipDefaultSettings(),
          padding: 8,
          bodyFont: { size: 12 },
          callbacks: {
            title: () => null,
            label: (tooltipItem: any) =>
              tooltipItem.datasetIndex === 0
                ? tooltip
                  ? tooltip
                  : defaultTooltip
                : benchmarkTooltip,
          },
        },
      },
      min: 0,
      max: 100,
      barPercentage: 0.5,
      categoryPercentage: 0.7,
      scales: {
        x: {
          ticks: {
            display: false,
          },
          border: {
            display: displayXAxis,
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          beginAtZero: true,
          max: maxValue,
          ticks: {
            display: false,
          },
          border: {
            display: false,
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
      },
      layout: {
        padding: {
          top: bigPadding(docStyle)?.replace('px', ''),
        },
      },
      responsive: false,
      maintainAspectRatio: false,
    };

    setChartData(data);
    setChartOptions(options);
  }, [value, secondaryValue]);

  return (
    <FlexContainer
      direction="column"
      width="min-content"
      className="canvas-wrapper"
    >
      <Chart
        id={id}
        type="bar"
        data={chartData}
        options={chartOptions}
        className="chart"
        width={width}
        height={height}
      />
    </FlexContainer>
  );
};
