import {
  equal,
  getQueryTransformation,
  handleAxiosError,
  like,
  nested,
  notEqual,
} from '@/api/helpers';
import {
  ChangeEnrollDateFormValues,
  CourseEntityScheduleEnum,
  CourseSchedule,
  CourseScheduleType,
  DeleteScheduleRequest,
  MaterialType,
} from '@/client/courses';
import { FilterNamesEnum, getFiltersFromColumns } from '@/client/helpers';
import { Subjects } from '@/client/users';
import {
  RedirectPaths,
  RedirectPathsEnum,
  TableNamesEnum,
} from '@/common/constants';
import { DialogContext } from '@/common/context';
import { ChangeEnrollDateModal } from '@/components/courses/modals/ChangeEnrollDateModal';
import {
  DataTable,
  DataTableActions,
  DataTableColumnsMultiselect,
  DataTableColumnType,
  DataTableFilters,
  DataTableToolbar,
  FilterTypeEnum,
} from '@/components/tables/crud';
import {
  useAccountSchedules,
  useChangeScheduleDate,
  useChangeScheduleEnrollType,
  useDeleteSchedule,
} from '@/hooks/query';
import { useQueryParams } from '@/hooks/query.hook';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUserState } from '@/store/features/users';
import { DateFormats } from '@/system-settings/enums';
import { AppChip } from '@/ui/chip';
import DatatableThumbnail from '@/ui/datatable-thumbnail/DatatableThumbnail';
import { FormatDate } from '@/ui/date';
import { FlexContainer } from '@/ui/styled-ui';
import {
  branchAdminCheck,
  coursesEnrollToOptions,
  dateAccordingToDST,
  getScheduleEntity,
} from '@/utils/helpers';
import { displayMiltipleItems } from '@/utils/helpers/ui.helper';
import { AxiosError } from 'axios';
import { debounce } from 'lodash';
import { default as moment } from 'moment';
import { DataTableRowClickEvent } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { MenuItem } from 'primereact/menuitem';
import React, { FormEvent, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AccountEnrollmentActionHeader } from './AccountEnrollmentActionHeader';
import { EnrollmentTabs } from './EnrollmentTabs';

export const AccountCourseSchedulePage: React.FC<{ isMaterial?: boolean }> = ({
  isMaterial,
}) => {
  const { t } = useTranslation();
  const { setDialogData } = useContext(DialogContext);
  const { canUseFeature } = useFeatureFlag();
  const toast = useToast();
  const { user } = useAppSelector(selectCurrentUserState);
  const account = useAppSelector(selectCurrentAccount);
  const navigate = useNavigate();

  const { skip, take, sort, apiFilters, onFilter, onSort, onPage, setSkip } =
    useTable();

  const [selectedSchedule, setSelectedSchedule] = useState<
    CourseSchedule | undefined
  >(undefined);

  const [multiSearchValue, setMultiSearchValue] = useState('');
  const debouncedSetMultiSearchValue = useMemo(
    () =>
      debounce((event: FormEvent) => {
        setSkip(0);
        setMultiSearchValue((event.target as HTMLInputElement).value);
      }, 300),
    [],
  );

  const isBranchAdmin = branchAdminCheck(user, account);

  const {
    isLoading: isScheduleLoading,
    schedules,
    refetch,
  } = useAccountSchedules(
    {
      take,
      skip,
      filters: [
        ...apiFilters,
        ...(multiSearchValue.length
          ? [nested('course', [like('name', multiSearchValue)])]
          : []),
        nested(
          'course',
          isMaterial
            ? [notEqual('type', MaterialType.COURSE)]
            : [equal('type', MaterialType.COURSE)],
        ),
      ],
      sort:
        sort && sort.length > 0 && sort[0] !== 'created'
          ? [sort.join(',')]
          : ['date,asc'],
      enabled: true,
    },
    account?.id,
  );

  const changeScheduleDate = useChangeScheduleDate();
  const handleChangeScheduleDate = async (data: ChangeEnrollDateFormValues) => {
    if (selectedSchedule && data.date) {
      try {
        await changeScheduleDate.edit({
          scheduleId: selectedSchedule?.id,
          scheduleType: selectedSchedule?.entity.type,
          date: data.date,
          type: CourseScheduleType.STATIC,
        });

        await refetch();
        setSelectedSchedule(undefined);
        toast?.success(
          t('toast.success'),
          t('courses.schedule.date.change.success'),
        );
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
      }
    }
  };

  const deleteSchedule = useDeleteSchedule();
  const handleDeleteSchedule = async (payload: DeleteScheduleRequest) => {
    try {
      await deleteSchedule.delete(payload);

      await refetch();
      toast?.success(
        t('toast.success'),
        t(`courses.${payload.entity.type}.unenroll.success`),
      );
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const changeEnrollMethod = useChangeScheduleEnrollType();
  const handleChangeEnrollMethod = async (
    courseSchedule: CourseSchedule | undefined,
  ) => {
    if (courseSchedule?.id) {
      try {
        await changeEnrollMethod.edit({
          scheduleId: courseSchedule.id,
          scheduleType: courseSchedule.entity.type,
          autoEnroll: !courseSchedule.autoEnroll,
          type: CourseScheduleType.STATIC,
        });
        await refetch();
        toast?.success(
          t('toast.success'),
          t('courses.enrollType.change.success'),
        );
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
      }
    }
  };

  const menuItems = (
    courseSchedule: CourseSchedule,
    courseId?: string,
    courseName?: string,
  ) => {
    const menu: MenuItem[] = [];

    if (courseSchedule?.entity.type !== CourseEntityScheduleEnum.USER) {
      menu.push({
        label: courseSchedule?.autoEnroll
          ? t('courses.enrollType.change.manual')
          : t('courses.enrollType.change.auto'),
        icon: 'pi pi-sync',
        command: () =>
          setDialogData({
            type: 'confirmation',
            show: true,
            header: t('dialog.enrollType.change'),
            message: t('dialog.enrollType.warning'),
            onAccept: async () => {
              await handleChangeEnrollMethod(courseSchedule);
            },
          }),
      });
    }

    if (!courseSchedule?.executedAt) {
      menu.push(
        {
          label: t('courses.change.enrollDate'),
          icon: 'pi pi-calendar',
          command: () => setSelectedSchedule({ ...courseSchedule }),
        },
        {
          label: t('generic.deleteSchedule'),
          icon: 'pi pi-calendar-times',
          command: () =>
            setDialogData({
              type: 'confirmation',
              show: true,
              header: t('dialog.deleteSchedule'),
              message: t(
                `dialog.deleteSchedule.${courseSchedule?.entity.type}`,
                {
                  name: courseSchedule.entity.name,
                },
              ),
              onAccept: async () => {
                await handleDeleteSchedule({
                  id: courseSchedule?.id,
                  source: courseSchedule?.entity.type,
                  entity: {
                    courseId,
                    courseName,
                    ...courseSchedule,
                  },
                });
              },
            }),
        },
      );
    }
    return menu;
  };

  const columns: DataTableColumnType[] = [
    {
      field: 'course.name',
      header: isMaterial ? t('material') : t('course'),
      sortable: true,
      filterable: false,
      style: {
        minWidth: '200px',
        maxWidth: '300px',
      },
      render: (row: CourseSchedule) => (
        <FlexContainer gap={16}>
          {row?.course?.thumbnailUrl && (
            <DatatableThumbnail
              className={
                moment(row?.course?.releaseDate).isAfter(moment(), 'day')
                  ? 'upcoming-course'
                  : ''
              }
              url={row.course.thumbnailUrl}
            />
          )}
          <FlexContainer
            direction="column"
            gap={8}
            className="align-self-center"
          >
            {row?.course?.name}
          </FlexContainer>
        </FlexContainer>
      ),
    },
    {
      field: 'date',
      header: t('generic.enrollDate'),
      sortable: true,
      filterable: true,
      filters: {
        label: t('filter.byDate'),
        field: FilterNamesEnum.SCHEDULE_UPCOMING_DATE,
        type: FilterTypeEnum.SELECT_UPCOMING_PAST,
        placeholder: t('filter.date'),
      },
      className: 'no-padding',
      render: (row: CourseSchedule) =>
        row?.date ? (
          <FormatDate
            format={DateFormats.TIMEDATE}
            date={dateAccordingToDST(row.date).toDate()}
          />
        ) : (
          <div>&#8212;</div>
        ),
    },
    {
      field: 'unEnrolledAt',
      header: t('generic.unEnrolledAt'),
      sortable: true,
      filterable: true,
      filters: {
        label: t('filter.unEnrolled'),
        field: FilterNamesEnum.SCHEDULE_UNENROLLED,
        type: FilterTypeEnum.SELECT_YES_NO,
        placeholder: t('filter.unEnrolled'),
      },
      className: 'no-padding',
      render: (row: CourseSchedule) =>
        row?.unEnrolledAt ? (
          <FormatDate format={DateFormats.TIMEDATE} date={row.unEnrolledAt} />
        ) : (
          <div>&#8212;</div>
        ),
    },
    ...(account?.courseDueDays
      ? [
          {
            field: 'dueDate',
            header: t('generic.dueDate'),
            sortable: true,
            filterable: false,
            className: 'no-padding',
            render: (row: CourseSchedule) =>
              row?.dueDate ? (
                <FormatDate format={DateFormats.TIMEDATE} date={row.dueDate} />
              ) : (
                <div>&#8212;</div>
              ),
          } as DataTableColumnType,
        ]
      : []),
    {
      field: 'entity.name',
      header: t('generic.enrollingTo'),
      sortable: true,
      filterable: true,
      filters: {
        field: FilterNamesEnum.ENTITY_NAME,
        type: FilterTypeEnum.TEXT,
      },
      className: 'no-padding',
      render: (row: CourseSchedule) =>
        row?.entity?.name ? (
          displayMiltipleItems(row.entity.name, t)
        ) : (
          <div>&#8212;</div>
        ),
    },
    {
      field: 'entity.type',
      header: t('generic.entity'),
      sortable: true,
      filterable: true,
      filters: {
        field: FilterNamesEnum.ENTITY_TYPE,
        type: FilterTypeEnum.MULTI_SELECT,
        options: coursesEnrollToOptions(t, user, account, true),
      },
      className: 'no-padding',
      render: (row: CourseSchedule) =>
        row?.entity.type ? (
          getScheduleEntity(row.entity.type)
        ) : (
          <div>&#8212;</div>
        ),
    },
    {
      field: 'usersCount',
      header: t('users'),
      sortable: true,
      filterable: false,
      className: 'no-padding',
      render: (row: CourseSchedule) => (row?.usersCount ? row.usersCount : 0),
    },
    {
      field: 'scheduledBy.name',
      header: t('generic.scheduledBy'),
      sortable: true,
      filterable: true,
      filters: {
        field: FilterNamesEnum.SCHEDULED_BY,
        type: FilterTypeEnum.TEXT,
      },
      className: 'no-padding',
      render: (row: CourseSchedule) =>
        row?.scheduledBy.id ? row.scheduledBy?.name : <div>&#8212;</div>,
    },
  ];

  if (!canUseFeature(Subjects.TRAINING_PLANNER)) {
    columns.push(
      {
        field: 'autoEnroll',
        header: t('schedule.newUsers.enroll'),
        sortable: true,
        filterable: true,
        filters: {
          label: t('filter.byEnrollType'),
          field: FilterNamesEnum.COURSE_SCHEDULE_AUTO_ENROLL,
          type: FilterTypeEnum.SELECT_AUTO_MANUAL,
          placeholder: t('filter.enrollType'),
        },
        className: 'no-padding',
        render: (row: CourseSchedule) => (
          <>
            {row?.entity.type ? (
              row.entity.type === CourseEntityScheduleEnum.USER ? (
                <div>&#8212;</div>
              ) : row.autoEnroll ? (
                <AppChip label={t('generic.auto')} type="primary" />
              ) : (
                <AppChip label={t('generic.manual')} type="secondary" />
              )
            ) : (
              <div>&#8212;</div>
            )}
          </>
        ),
      },
      {
        field: 'actions',
        header: t('generic.actions'),
        sortable: false,
        filterable: false,
        className: 'no-padding',
        style: {
          width: '80px',
          textAlign: 'center',
        },
        render: (row: CourseSchedule) => (
          <DataTableActions
            menuItems={menuItems(row, row?.course?.id, row?.course?.name)}
            disabled={
              menuItems(row)?.length < 1 ||
              (row?.entity.type === CourseEntityScheduleEnum.ACCOUNT &&
                isBranchAdmin)
            }
          />
        ),
      },
    );
  }

  // Set the preselected columns
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const defaultVisibleColumns = columns.map((column) => column.field);
  const alwaysVisibleColumns = ['name', 'actions'];
  //

  const { params } = useQueryParams(getQueryTransformation<{ name: string }>());

  const toolbar = (
    <DataTableToolbar justify="space-between">
      <FlexContainer
        justify="space-between"
        gap={8}
        align="flex-start"
        wrap="wrap"
      >
        <DataTableFilters
          filters={getFiltersFromColumns(columns)}
          onFilter={onFilter}
          defaultValues={
            !params.filters
              ? { [FilterNamesEnum.SCHEDULE_UPCOMING_DATE]: 'yes' }
              : params.filters
          }
          tableName={
            isMaterial
              ? TableNamesEnum.MATERIAL_ACCOUNT_SCHEDULE
              : TableNamesEnum.COURSES_ACCOUNT_SCHEDULE
          }
          className="flex-initial"
        />
        <div className="p-input-icon-left flex-auto min-w-300">
          <InputText
            className="w-full"
            onInput={debouncedSetMultiSearchValue}
            placeholder={
              isMaterial ? t('material.search.name') : t('courses.search.name')
            }
            autoComplete="off"
          />
          <i className="pi pi-search" />
        </div>
      </FlexContainer>
      <DataTableColumnsMultiselect
        columns={columns}
        tableName={
          isMaterial
            ? TableNamesEnum.MATERIAL_ACCOUNT_SCHEDULE
            : TableNamesEnum.COURSES_ACCOUNT_SCHEDULE
        }
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        defaultVisibleColumns={defaultVisibleColumns}
        alwaysVisibleColumns={alwaysVisibleColumns}
      />
    </DataTableToolbar>
  );

  const onRowClick = (e: DataTableRowClickEvent) => {
    if (e?.data?.course?.id) {
      navigate(
        RedirectPaths[
          isMaterial
            ? e?.data?.course?.type === MaterialType.RESOURCE
              ? RedirectPathsEnum.RESOURCES_EDIT
              : RedirectPathsEnum.CUSTOM_MATERIALS_EDIT
            : RedirectPathsEnum.COURSES_CONTENT
        ](e.data.course.id),
      );
    }
  };

  return (
    <>
      {account && (
        <>
          <AccountEnrollmentActionHeader
            account={account}
            onTriggerRefetch={refetch}
            isMaterial={isMaterial}
          />
          {canUseFeature(Subjects.TRAINING_PLANNER) && <EnrollmentTabs />}
          <DataTable
            dataKey="dataId"
            rowGroupMode="rowspan"
            groupRowsBy="course.name"
            data={schedules?.result.map((x, i) => ({
              ...x,
              dataId: `${x.entity.id}-${x.course?.id}-${i}`,
            }))}
            count={schedules?.count as number}
            isLoading={isScheduleLoading}
            toolbar={toolbar}
            columns={columns}
            visibleColumns={visibleColumns}
            onPage={onPage}
            rows={take}
            onRowClick={onRowClick}
            skip={skip}
            onSort={onSort}
            sort={sort}
            classNames="rowspan-datatable with-actions"
          />
          <ChangeEnrollDateModal
            date={selectedSchedule?.date}
            visible={!!selectedSchedule}
            onSubmit={handleChangeScheduleDate}
            onHide={() => setSelectedSchedule(undefined)}
          />
        </>
      )}
    </>
  );
};
