import { equal } from '@/api/helpers';
import { CourseStatusAttemptEnum, MaterialType } from '@/client/courses';
import { useLearnerCourses } from '@/hooks/query';
import { LoadingPage } from '@/pages/LoadingPage';
import React, { createContext, useContext } from 'react';
import { useTranslation } from 'react-i18next';

type LearnerContextProps = {
  hasMaterials: boolean;
  hasResources: boolean;
  hasCustomMaterials: boolean;
  coursesToDo: number;
  customMaterialsToDo: number;
};

const LearnerContext = createContext<LearnerContextProps>({
  hasMaterials: false,
  hasResources: false,
  hasCustomMaterials: false,
  coursesToDo: 0,
  customMaterialsToDo: 0,
});

type LearnerProviderProps = {
  children: React.ReactNode;
  isLoading?: boolean;
};

export const LearnerProvider: React.FC<LearnerProviderProps> = ({
  children,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { isLoading: areMaterialsToDoLoading, learnerCourses: materialsToDo } =
    useLearnerCourses({
      take: 0,
      skip: 0,
      type: CourseStatusAttemptEnum.IN_PROGRESS,
      filters: [equal('type', MaterialType.CUSTOM_MATERIAL)],
    });

  const {
    isLoading: areMaterialsCompletedLoading,
    learnerCourses: materialsCompleted,
  } = useLearnerCourses({
    take: 0,
    skip: 0,
    type: CourseStatusAttemptEnum.COMPLETED,
    filters: [equal('type', MaterialType.CUSTOM_MATERIAL)],
  });

  const { isLoading: areCoursesToDoLoading, learnerCourses: coursesToDo } =
    useLearnerCourses({
      take: 0,
      skip: 0,
      type: CourseStatusAttemptEnum.IN_PROGRESS,
      filters: [equal('type', MaterialType.COURSE)],
    });

  const { isLoading: areResourcesLoading, learnerCourses: resources } =
    useLearnerCourses({
      take: 0,
      skip: 0,
      filters: [equal('type', MaterialType.RESOURCE)],
    });

  return (
    <>
      {isLoading ||
      areMaterialsToDoLoading ||
      areMaterialsCompletedLoading ||
      areCoursesToDoLoading ||
      areResourcesLoading ? (
        <LoadingPage message={t('generic.loading')} />
      ) : (
        <LearnerContext.Provider
          value={{
            hasMaterials:
              !!resources?.count ||
              !!materialsToDo?.count ||
              !!materialsCompleted?.count,
            hasResources: !!resources?.count,
            hasCustomMaterials:
              !!materialsToDo?.count || !!materialsCompleted?.count,
            coursesToDo: coursesToDo?.count ?? 0,
            customMaterialsToDo: materialsToDo?.count ?? 0,
          }}
        >
          {children}
        </LearnerContext.Provider>
      )}
    </>
  );
};

// Custom hook to use the SCORM context
export const useLearner = () => useContext(LearnerContext);
