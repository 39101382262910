import { TableNamesEnum, TableNamesKeys } from '@/common/constants';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { selectCurrentAccount } from '@/store/features/account';
import {
  selectSelectedColumns,
  setSelectedColumns,
} from '@/store/features/columns';
import { selectCurrentUser } from '@/store/features/users';
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ColumnsMultiselectOptionType, DataTableColumnType } from './types';
import { getSelectableColumns } from './utils';

export const StyledMultiselect = styled(MultiSelect)`
  &.p-multiselect {
    background: transparent;
    border: none;
    &:not(.p-disabled).p-focus {
      border: none;
      box-shadow: none;
      .p-multiselect-trigger::before {
        color: var(--red-main);
      }
    }
    .p-multiselect-label-container {
      display: none;
    }
    .p-multiselect-trigger {
      padding: 0 var(--small-padding);
      &::before {
        content: '\f0db';
        font-family: var(--icon-font-family);
        font-weight: 700;
        font-size: 18px;
        color: var(--black-main);
      }
    }
    svg {
      display: none;
    }
  }
`;

export type DataTableColumnsMultiselectProps = {
  columns: DataTableColumnType[];
  visibleColumns: string[];
  setVisibleColumns: React.Dispatch<React.SetStateAction<string[]>>;
  tableName: TableNamesEnum;
  alwaysVisibleColumns: string[];
  defaultVisibleColumns: string[];
};

export const DataTableColumnsMultiselect: React.FC<
  DataTableColumnsMultiselectProps
> = ({
  columns,
  visibleColumns,
  setVisibleColumns,
  tableName,
  alwaysVisibleColumns,
  defaultVisibleColumns,
}) => {
  const dispatch = useAppDispatch();
  const allOpenColumns = useAppSelector(selectSelectedColumns);
  const currentAccount = useAppSelector(selectCurrentAccount);
  const user = useAppSelector(selectCurrentUser);

  const options: ColumnsMultiselectOptionType[] = getSelectableColumns(
    columns,
    alwaysVisibleColumns,
  );
  const preselectedOpenColumns = allOpenColumns
    ? allOpenColumns[
        `${TableNamesKeys[tableName]}-${currentAccount?.id}-${user?.id}`
      ] ?? defaultVisibleColumns
    : defaultVisibleColumns;

  useEffect(() => {
    setVisibleColumns(preselectedOpenColumns);
  }, [JSON.stringify(preselectedOpenColumns)]);

  const handleSelectedColumnsChange = (e: MultiSelectChangeEvent) => {
    const modifiedOptions = [
      ...e.value.filter(
        (option: any) => !alwaysVisibleColumns.includes(option),
      ),
      ...alwaysVisibleColumns,
    ];

    dispatch(
      setSelectedColumns({
        ...allOpenColumns,
        [`${TableNamesKeys[tableName]}-${currentAccount?.id}-${user?.id}`]:
          modifiedOptions,
      }),
    );
  };

  return (
    <>
      {!!options.length && (
        <StyledMultiselect
          options={options}
          value={visibleColumns}
          optionLabel="label"
          optionValue="value"
          onChange={handleSelectedColumnsChange}
        />
      )}
    </>
  );
};
