export const isOutsideOfEfront = () =>
  process.env.REACT_APP_OUTSIDE_EFRONT === 'true';

export const isUserManagementEnabled = () =>
  process.env.REACT_APP_ENABLE_USER_MANAGEMENT === 'true';

export const isPhishingEnabled = () =>
  process.env.REACT_APP_ENABLE_PHISHING_CAMPAIGNS === 'true';

export const parseEnviourmentUrl = (): {
  keycloakUrl: string;
  apiUrl: string;
} => {
  // Force the config urls so plugins work
  if (process.env?.REACT_APP_FORCE_CONFIG_URLS === 'true') {
    return {
      keycloakUrl: process.env.REACT_APP_KEYCLOAK_URL as string,
      apiUrl: process.env.REACT_APP_API_URL as string,
    };
  }

  if (window.location.hostname.endsWith('cyber-pilot.local')) {
    return {
      keycloakUrl: process.env.REACT_APP_KEYCLOAK_URL as string,
      apiUrl: process.env.REACT_APP_API_URL as string,
    };
  }

  switch (window.location.hostname) {
    case 'localhost': {
      return {
        keycloakUrl: process.env.REACT_APP_KEYCLOAK_URL as string,
        apiUrl: process.env.REACT_APP_API_URL as string,
      };
    }
    case 'app.cyberpilot.io': {
      return {
        keycloakUrl: 'https://login.app.cyberpilot.io/',
        apiUrl: 'https://api.app.cyberpilot.io/',
      };
    }
    case 'awareness.itm8.com': {
      return {
        keycloakUrl: 'https://login.awareness.itm8.com/',
        apiUrl: 'https://api.awareness.itm8.com/',
      };
    }
    default: {
      const domainSplitted =
        window.location.hostname.replace('www.', '').split('.') || [];
      domainSplitted.shift();

      return {
        keycloakUrl: `https://login.${domainSplitted.join('.')}/`,
        apiUrl: `https://api.${domainSplitted.join('.')}/`,
      };
    }
  }
};
