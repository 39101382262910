import {
  colorPurpleDark,
  platformFontFamily,
  smallFontSize,
  xxxSmallBorderRadius,
} from '@/utils/helpers/css-variables.helper';

export const tooltipDefaultSettings = () => {
  const docStyle = getComputedStyle(document.documentElement);
  return {
    displayColors: false,
    padding: { top: 8, bottom: 8, left: 16, right: 16 },
    backgroundColor: colorPurpleDark(docStyle),
    cornerRadius: xxxSmallBorderRadius(docStyle)?.replace('px', ''),
    bodyFont: {
      size: smallFontSize(docStyle),
      family: platformFontFamily(docStyle),
    },
  };
};
