import { Conditions } from '@/api/enums';
import { equal } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Course, MaterialType } from '@/client/courses';
import { getFiltersFromColumns } from '@/client/helpers';
import { Actions, Subjects, SystemRoles } from '@/client/users';
import {
  RedirectPaths,
  RedirectPathsEnum,
  TableNamesEnum,
} from '@/common/constants';
import {
  DataTable,
  DataTableActions,
  DataTableColumnsMultiselect,
  DataTableColumnType,
  DataTableFilters,
  DataTableToolbar,
  FilterTypeEnum,
} from '@/components/tables/crud';
import { useCourses } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { usePermission } from '@/hooks/usePermission';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import { AppChip } from '@/ui/chip';
import DatatableThumbnail from '@/ui/datatable-thumbnail/DatatableThumbnail';
import { FormatDate } from '@/ui/date';
import { FlexContainer } from '@/ui/styled-ui';
import { branchAdminCheck, getCourseLanguages } from '@/utils/helpers';
import { debounce } from 'lodash';
import moment from 'moment';
import { DataTableRowClickEvent } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { MenuItem } from 'primereact/menuitem';
import React, { FormEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const CoursesPage = () => {
  const { t } = useTranslation();
  const { can } = usePermission();
  const account = useAppSelector(selectCurrentAccount);
  const currentUser = useAppSelector(selectCurrentUser);
  const isBranchAdmin = branchAdminCheck(currentUser, account);
  const navigate = useNavigate();
  const { canUseFeature } = useFeatureFlag();

  const { skip, take, sort, apiFilters, onSort, setSkip, onPage, onFilter } =
    useTable();

  const [multiSearchValue, setMultiSearchValue] = useState('');
  const debouncedSetMultiSearchValue = useMemo(
    () =>
      debounce((event: FormEvent) => {
        setSkip(0);
        setMultiSearchValue((event.target as HTMLInputElement).value);
      }, 500),
    [],
  );

  const { isLoading, courses } = useCourses({
    take,
    skip,
    search: multiSearchValue,
    filters: [
      ...apiFilters,
      // If not System, show only the available courses for your account
      ...(!account?.isSystem
        ? [
            {
              field: 'availableToAccounts',
              condition: Conditions.EQUAL,
              value: account?.id,
            },
          ]
        : []),
      equal('type', MaterialType.COURSE),
    ],
    sort: sort && sort.length > 0 ? [sort.join(',')] : [],
  });

  const menuItems = (course: Course) => {
    const menu: MenuItem[] = [
      {
        label: t('course.view.content'),
        icon: 'pi pi-pencil',
        command: () => {
          navigate(RedirectPaths[RedirectPathsEnum.COURSES_CONTENT](course.id));
        },
      },
    ];

    if (account?.isSystem) {
      menu.push({
        label: t('course.view.schedule'),
        icon: 'pi pi-calendar',
        command: () => {
          navigate(
            RedirectPaths[RedirectPathsEnum.COURSES_SCHEDULE](course.id),
          );
        },
      });

      if (can(Actions.READ, Subjects.COURSE_ACCOUNT_SCHEDULES)) {
        menu.push({
          label: t('course.view.accounts'),
          icon: 'pi pi-building',
          command: () => {
            navigate(
              RedirectPaths[RedirectPathsEnum.COURSES_ACCOUNTS](course.id),
            );
          },
        });
      }
    }

    // if (
    //   can(Actions.READ, Subjects.COURSE_BRANCH_SCHEDULES) &&
    //   !account?.isSystem
    // ) {
    //   menu.push({
    //     label: t('course.view.branches'),
    //     icon: 'pi pi-sitemap',
    //     command: () => {
    //       navigate(
    //         RedirectPaths[RedirectPathsEnum.COURSES_BRANCHES](course.id),
    //       );
    //     },
    //   });
    // }

    // if (
    //   can(Actions.READ, Subjects.COURSE_GROUP_SCHEDULES) &&
    //   !account?.isSystem &&
    //   !isBranchAdmin
    // ) {
    //   menu.push({
    //     label: t('course.view.groups'),
    //     icon: 'pi pi-users',
    //     command: () => {
    //       navigate(RedirectPaths[RedirectPathsEnum.COURSES_GROUPS](course.id));
    //     },
    //   });
    // }

    if (
      can(Actions.READ, Subjects.COURSE_USER_SCHEDULES) &&
      !account?.isSystem
    ) {
      menu.push({
        label: t('course.view.users'),
        icon: 'pi pi-user',
        command: () => {
          navigate(RedirectPaths[RedirectPathsEnum.COURSES_USERS](course.id));
        },
      });
    }

    return menu;
  };

  const columns: DataTableColumnType[] = [
    {
      field: 'name',
      header: t('course'),
      sortable: true,
      filterable: false,
      render: (row: Course) => {
        return (
          <FlexContainer gap={12}>
            {row?.courseThumbnail?.signedUrl && (
              <DatatableThumbnail
                className={
                  moment(row?.releaseDate).isAfter(moment(), 'day')
                    ? 'upcoming-course'
                    : ''
                }
                url={row?.courseThumbnail?.signedUrl}
              />
            )}
            <FlexContainer direction="column" align="flex-start">
              <span>
                <span className="mr-2">{row?.name}</span>
                {row?.account && account?.isSystem && (
                  <AppChip label={t('generic.custom')} type="primary" />
                )}
              </span>

              {!!row?.courseLanguages?.length && (
                <span className="secondary-text">
                  <span>{t('courses.available.languages')}: </span>
                  {getCourseLanguages(row?.courseLanguages)}
                </span>
              )}
            </FlexContainer>
          </FlexContainer>
        );
      },
    },
    /*
    {
      field: 'courseCreatedAt',
      header: t('generic.creation'),
      sortable: false,
      filterable: false,
      render: (row: Course) =>
        row?.courseCreatedAt ? (
          <FormatDate date={row?.courseCreatedAt} />
        ) : (
          <FormatDate date={row?.created} />
        ),
    },
    */
  ];

  if (!account?.isSystem && canUseFeature(Subjects.COURSE_STATISTICS)) {
    columns.push({
      field: 'totalNumberOfEnrollments',
      header: t('courses.statistics.total_enrollments'),
      sortable: true,
      filterable: false,
      render: (row: Course) => (
        <span>{row.totalNumberOfEnrollments || '-'}</span>
      ),
    });

    columns.push({
      field: 'completionPercentage',
      header: t('courses.statistics.completion_percentage'),
      sortable: true,
      filterable: false,
      render: (row: Course) => (
        <span>
          {row.completionPercentage ? `${row.completionPercentage}%` : '-'}
        </span>
      ),
    });
  }

  if (account?.isSystem) {
    columns.push({
      field: 'courseLastUpdatedAt',
      header: t('generic.last.updated'),
      sortable: false,
      filterable: false,
      render: (row: Course) =>
        row?.courseLastUpdatedAt ? (
          <FormatDate date={row?.courseLastUpdatedAt} />
        ) : (
          t('generic.never')
        ),
    });

    columns.push({
      field: 'customContent',
      header: t('courses.custom.content'),
      sortable: false,
      filterable: true,
      filters: { type: FilterTypeEnum.SELECT_YES_NO },
      render: (row: Course) =>
        row?.account ? (
          <AppChip label={t('dialog.yes')} type="primary" />
        ) : (
          <AppChip label={t('dialog.no')} type="secondary" />
        ),
      // TODO: We can also ask BE to return the name of the Account, not only the id, for better UX
      // row?.account ? <span>{row?.account?.name}</span> : <span>&#8212;</span>,
    });
  }

  columns.push({
    field: 'actions',
    header: t('generic.actions'),
    sortable: false,
    filterable: false,
    style: {
      width: '80px',
      textAlign: 'center',
    },
    render: (row: Course) => (
      <DataTableActions
        disabled={menuItems(row).length < 1}
        menuItems={menuItems(row)}
      />
    ),
  });

  // Set the preselected columns
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const defaultVisibleColumns = columns
    .filter((column) => column.field !== 'id')
    .map((column) => column.field);
  const alwaysVisibleColumns = ['name', 'actions'];
  //

  const filters = getFiltersFromColumns(columns);

  const toolbar = (
    <DataTableToolbar>
      <FlexContainer
        justify="space-between"
        gap={8}
        align="flex-start"
        wrap="wrap"
      >
        {!!filters.length && (
          <DataTableFilters
            filters={filters}
            onFilter={onFilter}
            tableName={TableNamesEnum.COURSES_LIST}
            className="flex-initial"
          />
        )}
        <div className="p-input-icon-left flex-auto min-w-300">
          <InputText
            className="w-full"
            onInput={debouncedSetMultiSearchValue}
            placeholder={t('courses.search.name')}
            autoComplete="off"
          />
          <i className="pi pi-search" />
        </div>
      </FlexContainer>
      <DataTableColumnsMultiselect
        columns={columns}
        tableName={TableNamesEnum.COURSES_LIST}
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        defaultVisibleColumns={defaultVisibleColumns}
        alwaysVisibleColumns={alwaysVisibleColumns}
      />
    </DataTableToolbar>
  );

  const pathItems = [
    {
      label: account?.name,
      url: !account?.isSystem
        ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id as string)
        : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('courses'),
      url: RedirectPaths[RedirectPathsEnum.COURSES](),
      template: AppBreadCrumbTemplate,
    },
  ];

  const onRowClick = (e: DataTableRowClickEvent) =>
    navigate(RedirectPaths[RedirectPathsEnum.COURSES_CONTENT](e.data.id));

  return (
    <>
      <AppBreadCrumb model={pathItems} />
      <FlexContainer justify="space-between" className="mb-4">
        <h1 className="mb-0">{t('courses')}</h1>
        {can(Actions.CREATE, Subjects.COURSES) &&
          (account?.isSystem ||
            (canUseFeature(Subjects.CUSTOM_COURSE_CREATION) &&
              !isBranchAdmin)) && (
            <AppButton
              label={t('button.createNew')}
              severity="secondary"
              onClick={() => {
                navigate(RedirectPaths[RedirectPathsEnum.COURSES_CREATE]());
              }}
            />
          )}
      </FlexContainer>
      <DataTable
        data={courses?.result}
        count={courses?.count as number}
        isLoading={isLoading}
        toolbar={toolbar}
        columns={columns}
        visibleColumns={visibleColumns}
        onPage={onPage}
        rows={take}
        skip={skip}
        onSort={onSort}
        sort={sort}
        onRowClick={onRowClick}
      />
    </>
  );
};
