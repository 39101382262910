import { Conditions } from '@/api/enums';
import { contains, equal, like } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { MaterialType, PosterCategory } from '@/client/courses';
import { HubspotProperty } from '@/client/hubspot/types';
import { Actions, Subjects } from '@/client/users';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { GridTable } from '@/components/grid-table';
import { DataTableToolbar } from '@/components/tables/crud';
import { useCourses } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { usePermission } from '@/hooks/usePermission';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import { PosterCard } from '@/ui/poster-card';
import { CardGridContainer, FlexContainer } from '@/ui/styled-ui';
import { postersColumnsWidth } from '@/utils/helpers';
import { hubspotTimestamp, hubspotTrack } from '@/utils/hubspot';
import { debounce } from 'lodash';
import { InputText } from 'primereact/inputtext';
import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { PosterCategoriesTabs } from '../dashboards/learner/posters/PosterCategoriesTabs';
import { MaterialTabs } from './MaterialTabs';

const StyledCardGridContainer = styled(CardGridContainer)`
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 0;

  @media screen and (max-width: ${postersColumnsWidth.THREE}px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: ${postersColumnsWidth.TWO}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: ${postersColumnsWidth.ONE}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const PostersPage: React.FC<{ category?: PosterCategory }> = ({
  category,
}) => {
  const { t } = useTranslation();
  const { can } = usePermission();
  const navigate = useNavigate();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const [multiSearchValue, setMultiSearchValue] = useState('');
  const debouncedSetMultiSearchValue = useMemo(
    () =>
      debounce((event: FormEvent) => {
        setSkip(0);
        setMultiSearchValue((event.target as HTMLInputElement).value);
      }, 300),
    [],
  );

  const { skip, take, apiFilters, onGridPageChange, onGridRowChange, setSkip } =
    useTable();

  const { isLoading, courses: posters } = useCourses({
    take: take,
    skip: skip,
    filters: [
      ...apiFilters,
      ...(!currentAccount?.isSystem
        ? [
            {
              field: 'availableToAccounts',
              condition: Conditions.EQUAL,
              value: currentAccount?.id,
            },
          ]
        : []),
      equal('type', MaterialType.POSTER),
      ...(category ? [contains('categories', [category])] : []),
      ...(multiSearchValue ? [like('name', multiSearchValue)] : []),
    ],
    staleTime: Infinity,
  });

  useEffect(() => {
    hubspotTrack(HubspotProperty.POSTERS_VIEWED, hubspotTimestamp());
  }, []);

  useEffect(() => {
    setSkip(0);
  }, [category]);

  const pathItems = [
    {
      label: currentAccount?.name,
      url: !currentAccount?.isSystem
        ? RedirectPaths[RedirectPathsEnum.ACCOUNT](currentAccount?.id as string)
        : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](currentAccount?.id),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('posters'),
      url: RedirectPaths[RedirectPathsEnum.POSTERS](),
      template: AppBreadCrumbTemplate,
    },
  ];

  const toolbar = (
    <DataTableToolbar justify="space-between">
      <div className="p-input-icon-left flex-auto min-w-300">
        <InputText
          className="w-full"
          onInput={debouncedSetMultiSearchValue}
          placeholder={t('generic.name.search')}
          autoComplete="off"
        />
        <i className="pi pi-search" />
      </div>
    </DataTableToolbar>
  );

  return (
    <>
      <AppBreadCrumb model={pathItems} />
      <FlexContainer justify="space-between" className="mb-4">
        <h1 className="mb-0">{t('posters')}</h1>
        {can(Actions.CREATE, Subjects.COURSES) && currentAccount?.isSystem && (
          <AppButton
            label={t('button.createNew')}
            severity="secondary"
            onClick={() => {
              navigate(RedirectPaths[RedirectPathsEnum.POSTER_CREATE]());
            }}
          />
        )}
      </FlexContainer>
      <MaterialTabs hasPosters />
      <GridTable
        isLoading={isLoading}
        count={posters?.count}
        take={take}
        skip={skip}
        onPageChange={onGridPageChange}
        onRowsChange={onGridRowChange}
        toolbarContent={toolbar}
        rowsPerPage={[6, 12]}
      >
        <FlexContainer direction="column" align="flex-start">
          <PosterCategoriesTabs />
          {!!posters?.count && (
            <StyledCardGridContainer>
              {posters?.result.map((poster) => (
                <PosterCard key={poster.id} poster={poster} withActions />
              ))}
            </StyledCardGridContainer>
          )}
        </FlexContainer>
      </GridTable>
    </>
  );
};
