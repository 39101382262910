export const enum HubspotProperty {
  ID = 'id',
  EMAIL = 'email',
  FIRSTNAME = 'firstname',
  LASTNAME = 'lastname',
  COMPANY = 'company',
  USER_LOGIN = 'user_login',
  ADMIN_DASHBOARD_OPENED = 'admin_dashboard_opened',
  REPORTS_OPENED = 'reports_view',
  PHISHING_CAMPAIGN_VIEW = 'phishing_campaign_view',
  NEW_USER_CREATED = 'new_user__created',
  NEW_USER_ENROLLED_TO_A_COURSE = 'new_user_enrolled_to_a_course',
  VISITED_SECURITY_CULTURE = 'visited_security_culture__cp_app_',
  POSTERS_VIEWED = 'posters_viewed',
  POSTERS_DOWNLOADED = 'posters_downloaded',
}

export const enum HubspotValue {
  YES = 'Yes',
  NO = 'No',
}

export enum HubspotEventType {
  GENERIC = 'generic',
  COURSE = 'course',
}

export enum HubspotValueType {
  BOOLEAN = 'boolean',
  DATE = 'date',
}

export type TrackHubspotEventRequest = {
  type: HubspotEventType;
  event: HubspotProperty;
  value: HubspotValue | string;
  valueType?: HubspotValueType;
};
