const DEFAULT_SCRIPT_ID = 'heap-script';

const getHeapScript = (id: string) => `
  window.heapReadyCb=window.heapReadyCb||[],window.heap=window.heap||[],heap.load=function(e,t){window.heap.envId=e,window.heap.clientConfig=t=t||{},window.heap.clientConfig.shouldFetchServerConfig=!1;var a=document.createElement("script");a.type="text/javascript",a.async=!0,a.src="https://cdn.us.heap-api.com/config/"+e+"/heap_config.js";var r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(a,r);var n=["init","startTracking","stopTracking","track","resetIdentity","identify","getSessionId","getUserId","getIdentity","addUserProperties","addEventProperties","removeEventProperty","clearEventProperties","addAccountProperties","addAdapter","addTransformer","addTransformerFn","onReady","addPageviewProperties","removePageviewProperty","clearPageviewProperties","trackPageview"],i=function(e){return function(){var t=Array.prototype.slice.call(arguments,0);window.heapReadyCb.push({name:e,fn:function(){heap[e]&&heap[e].apply(heap,t)}})}};for(var p=0;p<n.length;p++)heap[n[p]]=i(n[p])};
  heap.load("${id}");
`;

const initializeHeap = () => {
  if (!process.env.REACT_APP_HEAP_PROJECT_ID) {
    console.log('Could not load Heap. No Project ID found.');
    return;
  }

  if (!document) {
    return;
  }

  const hasScript = !!document.getElementById(DEFAULT_SCRIPT_ID);

  if (hasScript) {
    return;
  }

  const script = document.createElement('script');

  script.id = DEFAULT_SCRIPT_ID;
  script.innerHTML = getHeapScript(process.env.REACT_APP_HEAP_PROJECT_ID);
  script.async = true;

  document.body.appendChild(script);
};

export default initializeHeap;
