import { processListingParams } from '@/api/helpers';
import { ListingRequest } from '@/api/types';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { omit } from 'lodash';
import { getExceptionFromAxiosError } from '../helpers';
import { MessageResponseModel } from '../models';
import {
  AccountFeatureFlag,
  ListAccountFeatureFlagRequest,
  ListAccountFeatureFlagResponse,
  ListFeatureFlagResponse,
  UpdateAccountFeatureFlagsRequest,
  UpdateFeatureFlagsRequest,
} from './types';

export const featuresFlagsService = (client: AxiosInstance) => {
  const FEATURE_FLAGS_BASE_URL = 'feature-flags';
  //accounts/feature-flags

  const getFeatureFlags = async (
    params: ListingRequest,
  ): Promise<ListFeatureFlagResponse> => {
    try {
      const result = await client.get<
        ListingRequest,
        AxiosResponse<ListFeatureFlagResponse>
      >(`/${FEATURE_FLAGS_BASE_URL}`, {
        withCredentials: true,
        params: processListingParams(params),
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getAccountFeatureFlags = async (
    accountId: string | undefined,
    params: ListAccountFeatureFlagRequest,
  ): Promise<AccountFeatureFlag[]> => {
    try {
      const result = await client.get<
        ListAccountFeatureFlagRequest,
        AxiosResponse<AccountFeatureFlag[]>
      >(`/accounts/${accountId}/${FEATURE_FLAGS_BASE_URL}`, {
        withCredentials: true,
        params: processListingParams(params),
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const updateAccountFeatureFlags = async (
    request: UpdateAccountFeatureFlagsRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.patch<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(
        `/accounts/${request.accountId}/${FEATURE_FLAGS_BASE_URL}`,
        omit(request, 'accountId'),
        {
          withCredentials: true,
        },
      );
      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const updateFeatureFlags = async (
    featureId: string,
    request: UpdateFeatureFlagsRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.patch<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(`/${FEATURE_FLAGS_BASE_URL}/${featureId}`, request, {
        withCredentials: true,
      });
      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getFeatureFlagOverview = async (
    accountId: string | undefined,
    params: ListAccountFeatureFlagRequest,
  ): Promise<ListAccountFeatureFlagResponse> => {
    try {
      const result = await client.get<
        ListAccountFeatureFlagRequest,
        AxiosResponse<ListAccountFeatureFlagResponse>
      >(`${FEATURE_FLAGS_BASE_URL}/${accountId}/accounts`, {
        withCredentials: true,
        params: processListingParams(params),
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  return {
    getFeatureFlags,
    getAccountFeatureFlags,
    updateAccountFeatureFlags,
    updateFeatureFlags,
    getFeatureFlagOverview,
  };
};
