import { handleAxiosError } from '@/api/helpers';
import { client } from '@/client';
import { AccountFeatureFlag } from '@/client/feature-flags/types';
import { TableNamesEnum } from '@/common/constants';
import { roleTranslations } from '@/common/constants/roles';
import { StyledInputSwitch } from '@/components/form';
import {
  DataTable,
  DataTableActions,
  DataTableColumnsMultiselect,
  DataTableColumnType,
  DataTableToolbar,
} from '@/components/tables/crud';
import { useAccountsFeatureFlags } from '@/hooks/query/feature-flags.hooks';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { useToast } from '@/hooks/useToast';
import {
  selectCurrentAccount,
  setCurrentAccount,
} from '@/store/features/account';
import { FlexContainer } from '@/ui/styled-ui';
import { AxiosError } from 'axios';
import { MenuItem } from 'primereact/menuitem';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FeatureFlagRolesModal } from '../modals/FeatureFlagRolesModal';

export const AccountFeatureFlagsDatatable: React.FC = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const { id: accountId } = useParams();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const dispatch = useAppDispatch();

  const [isFeatureRolesModalVisible, setIsFeatureRolesModalVisible] =
    useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState<AccountFeatureFlag>();

  const { apiFilters } = useTable();
  const { isLoading, flags, refetch } = useAccountsFeatureFlags(accountId, {
    filters: apiFilters,
  });

  const handleUpdateFeatureFlag = async (
    feature: AccountFeatureFlag,
    roles?: string[],
  ) => {
    if (!accountId) return;

    try {
      setIsSubmitting(true);

      await client.featureFlags.updateAccountFeatureFlags({
        accountId,
        featureFlags: flags?.map((flag: AccountFeatureFlag) => {
          if (flag?.featureFlag?.id === feature?.featureFlag?.id) {
            return {
              featureFlag: feature?.featureFlag?.id ?? '',
              active: !!roles?.length,
              roles: roles || [],
            };
          }

          return {
            featureFlag: flag?.featureFlag?.id ?? '',
            active: flag.active,
            roles: flag.roles?.map(({ id }) => id) ?? [],
          };
        }),
      });

      refetch();
      await updateAccountIfCurrent();
      setIsFeatureRolesModalVisible(false);

      toast?.success(t('toast.success'), t('feature.edit.success'));
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleActivateFeatureFlag = async (
    active: boolean,
    row: AccountFeatureFlag,
  ) => {
    if (!accountId || !row) return;

    try {
      setIsSubmitting(true);

      await client.featureFlags.updateAccountFeatureFlags({
        accountId,
        featureFlags: flags?.map((flag: AccountFeatureFlag) => {
          if (
            flag?.featureFlag?.id === row?.featureFlag?.id &&
            row.featureFlag?.id
          ) {
            return {
              featureFlag: row?.featureFlag?.id ?? '',
              active: active,
              roles:
                row.roles && row.featureFlag?.isRoleBased && active
                  ? row.roles.map((x) => x.id)
                  : [],
            };
          }

          return {
            featureFlag: flag?.featureFlag?.id ?? '',
            active: flag.active,
            roles: flag.roles?.map(({ id }) => id) ?? [],
          };
        }),
      });

      refetch();
      await updateAccountIfCurrent();
      setIsFeatureRolesModalVisible(false);

      toast?.success(
        t('toast.success'),
        active ? t('feature.activated') : t('feature.deactivated'),
      );
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    } finally {
      setIsSubmitting(false);
    }
  };

  const updateAccountIfCurrent = async () => {
    if (!accountId || !currentAccount || accountId !== currentAccount?.id)
      return;
    const response = await client.accounts.getAccount(currentAccount.id);
    dispatch(setCurrentAccount(response));
  };

  const columns: DataTableColumnType[] = [
    {
      field: 'name',
      header: t('feature.name'),
      sortable: false,
      filterable: false,
      render: (row: AccountFeatureFlag) => (
        <span>{t(`features.${row?.featureFlag?.key}`)}</span>
      ),
    },
    {
      field: 'featureFlag.key',
      header: t('feature.key'),
      sortable: false,
      filterable: false,
      render: (row: AccountFeatureFlag) => <span>{row?.featureFlag?.key}</span>,
    },
    {
      field: 'roles',
      header: t('generic.role'),
      style: { width: '380px' },
      sortable: false,
      filterable: false,
      render: (row: AccountFeatureFlag) =>
        row?.roles?.length && row.featureFlag?.isRoleBased ? (
          row.roles?.map((role) => roleTranslations(role, t))?.join(', ')
        ) : (
          <div className="group-row">&#8212;</div>
        ),
    },
    {
      field: 'featureFlag.active',
      header: t('generic.active'),
      sortable: false,
      filterable: false,
      render: (row: AccountFeatureFlag) => (
        <StyledInputSwitch
          checked={row.active}
          onChange={
            !row.featureFlag?.isRoleBased || row.active
              ? async () => await handleActivateFeatureFlag(!row.active, row)
              : () => {
                  setSelectedFeature(row);
                  setIsFeatureRolesModalVisible(true);
                }
          }
        />
      ),
    },
    {
      field: 'actions',
      header: t('generic.actions'),
      sortable: false,
      filterable: false,
      style: {
        width: '80px',
        textAlign: 'center',
      },
      render: (row: AccountFeatureFlag) => (
        <DataTableActions
          disabled={menuItems(row).length < 1}
          menuItems={menuItems(row)}
        />
      ),
    },
  ];

  const menuItems = (feature: AccountFeatureFlag) => {
    const menu: MenuItem[] = [];

    if (feature.featureFlag?.isRoleBased && feature.active) {
      menu.push({
        label: t('feature.editRoles'),
        icon: 'pi pi-pencil',
        command: () => {
          setSelectedFeature(feature);
          setIsFeatureRolesModalVisible(true);
        },
      });
    }

    return menu;
  };

  // Set the preselected columns
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const defaultVisibleColumns = columns.map((column) => column.field);
  const alwaysVisibleColumns = ['name', 'featureFlag.active, actions'];

  const toolbar = (
    <DataTableToolbar>
      <FlexContainer
        justify="space-between"
        gap={8}
        align="flex-start"
        wrap="wrap"
      >
        <div />
        <DataTableColumnsMultiselect
          columns={columns}
          tableName={TableNamesEnum.ACCOUNT_FEATURES_LIST}
          visibleColumns={visibleColumns}
          setVisibleColumns={setVisibleColumns}
          defaultVisibleColumns={defaultVisibleColumns}
          alwaysVisibleColumns={alwaysVisibleColumns}
        />
      </FlexContainer>
    </DataTableToolbar>
  );

  return (
    <>
      <DataTable
        data={flags}
        count={flags?.length}
        isLoading={isLoading}
        columns={columns}
        visibleColumns={visibleColumns}
        toolbar={toolbar}
      />
      <FeatureFlagRolesModal
        currentFeature={selectedFeature}
        visible={isFeatureRolesModalVisible}
        isSubmitting={isSubmitting}
        isAccount
        onSubmit={handleUpdateFeatureFlag}
        onHide={() => setIsFeatureRolesModalVisible(false)}
      />
    </>
  );
};
