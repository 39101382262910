import { equal, isIn, nested, notEqual } from '@/api/helpers';
import { Account } from '@/client/accounts';
import {
  AccountsEnrollFormValues,
  Course,
  CourseEnrollmentTypeEnum,
  CourseScheduleType,
} from '@/client/courses';
import { Subjects } from '@/client/users';
import {
  accountsEnrollSchema,
  accountsUnenrollSchema,
} from '@/components/courses/validations/enroll';
import { FormikSwitch } from '@/components/form';
import { AccountsMultiselectInput } from '@/components/form/selectors';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { AppButton } from '@/ui/buttons';
import { AppCalendar } from '@/ui/calendar';
import { FlexContainer } from '@/ui/styled-ui';
import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import { Dialog, DialogProps } from 'primereact/dialog';
import { Message } from 'primereact/message';
import { FormEvent } from 'primereact/ts-helpers';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledDialog = styled(Dialog)`
  width: 500px;
`;

type AccountsEnrollModalProps = {
  onSubmit: (data: AccountsEnrollFormValues) => void;
  course: Course;
  type?: CourseEnrollmentTypeEnum;
} & DialogProps;

export const CourseAccountsEnrollModal: React.FC<AccountsEnrollModalProps> = ({
  onSubmit,
  onHide,
  course,
  visible,
  type,
}) => {
  const { canUseFeature } = useFeatureFlag();
  const { t } = useTranslation();
  const [initialValues] = useState<AccountsEnrollFormValues>({
    courses: [],
    accounts: [],
    date: null,
    autoEnroll: false,
    type: CourseScheduleType.STATIC,
  });

  const isEnroll = type === CourseEnrollmentTypeEnum.ENROLL;

  const handleAccountsSelect = (
    account: Account[],
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => void,
  ) => {
    setFieldValue('accounts', account);
  };

  const filters = isEnroll
    ? [
        isIn('availableCourses', [course?.id]),
        equal('active', true),
        ...(course && course?.account?.id
          ? [equal('id', course?.account?.id)]
          : []),
      ]
    : [
        equal('available', true),
        equal('active', true),
        nested('schedule', [notEqual('executedAt', null)]),
      ];

  return (
    <StyledDialog
      blockScroll
      visible={visible}
      header={<h1>{t(`courses.${type}Accounts`)}</h1>}
      onHide={onHide}
      draggable={false}
      data-testid={`${type}-accounts-modal`}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={
          isEnroll ? accountsEnrollSchema(t) : accountsUnenrollSchema(t)
        }
        validateOnMount
      >
        {({ setFieldValue, values, errors }) => (
          <>
            <Form>
              <div className="field w-full mb-4">
                <label>{`${t('generic.selected')} (${
                  values.accounts?.length
                    ? values.accounts?.length
                    : t('generic.none')
                })`}</label>
                <AccountsMultiselectInput
                  isUnenroll={!isEnroll}
                  className="w-full"
                  additionalFilters={filters}
                  onChange={(accounts) =>
                    handleAccountsSelect(accounts as Account[], setFieldValue)
                  }
                  selectedOptions={values.accounts}
                  courseId={course?.id}
                />
              </div>
              {isEnroll && (
                <>
                  <div className="field w-full mb-4">
                    <label>{t('generic.enrollDate')}</label>
                    <AppCalendar
                      id="enroll-date"
                      name="enrollDate"
                      className="w-full"
                      placeholder={t('courses.select.enrollDate')}
                      minDate={
                        moment(course.releaseDate).isAfter(moment(), 'days')
                          ? moment(course.releaseDate).toDate()
                          : new Date()
                      }
                      value={values.date}
                      onChange={(event: FormEvent) => {
                        setFieldValue(
                          'date',
                          moment(event.value?.toString()).utc(true).toDate(),
                        );
                      }}
                      readOnlyInput
                      icon="pi pi-calendar"
                    />
                  </div>
                  {!canUseFeature(Subjects.TRAINING_PLANNER) && (
                    <div className="field-checkbox mt-3">
                      <Field
                        inputId="autoEnroll"
                        name="autoEnroll"
                        label={t('courses.autoEnroll')}
                        value={values.autoEnroll}
                        component={FormikSwitch}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue('autoEnroll', e.target.value);
                        }}
                      />
                    </div>
                  )}

                  <Message
                    severity="warn"
                    text={t('courses.enroll.branchesUsers.warning')}
                  />
                </>
              )}
              <FlexContainer justify="flex-end" className="mt-5">
                <AppButton
                  label={t('button.cancel')}
                  severity="secondary"
                  type="outlined"
                  onClick={onHide}
                  className="mr-3"
                  data-testid={`${type}-accounts-cancel-form`}
                />
                <AppButton
                  label={isEnroll ? t('generic.enroll') : t('generic.unenroll')}
                  isDisabled={!!Object.keys(errors).length}
                  isSubmit
                  data-testid={`${type}-accounts-submit-form`}
                />
              </FlexContainer>
            </Form>
          </>
        )}
      </Formik>
    </StyledDialog>
  );
};
