import { handleAxiosError, like } from '@/api/helpers';
import { Account } from '@/client/accounts';
import { Branch } from '@/client/branches';
import {
  ChangeEnrollDateFormValues,
  CourseBranch,
  CourseEnrollmentTypeEnum,
  CourseEntityEnrollEnum,
  CourseEntityScheduleEnum,
  CourseScheduleType,
  EnrollFormValues,
} from '@/client/courses';
import { FilterNamesEnum, getFiltersFromColumns } from '@/client/helpers';
import { HubspotProperty, HubspotValue } from '@/client/hubspot/types';
import { TableNamesEnum } from '@/common/constants';
import { branchStatusOptions } from '@/common/constants/enroll-types';
import { DialogContext } from '@/common/context';
import { ChangeEnrollDateModal } from '@/components/courses/modals/ChangeEnrollDateModal';
import { EnrollModal } from '@/components/courses/modals/EnrollModal';
import {
  DataTable,
  DataTableActions,
  DataTableColumnsMultiselect,
  DataTableColumnType,
  DataTableFilters,
  DataTableToolbar,
  FilterTypeEnum,
} from '@/components/tables/crud';
import {
  useBranchesSchedules,
  useChangeScheduleDate,
  useChangeScheduleEnrollType,
  useCourse,
  useCourseBranches,
  useDeleteEnrollment,
} from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { DateFormats } from '@/system-settings/enums';
import { AppChip } from '@/ui/chip';
import { FormatDate } from '@/ui/date';
import { FlexContainer } from '@/ui/styled-ui';
import { determineBranchStatusColor } from '@/utils/helpers/ui.helper';
import { hubspotTrack } from '@/utils/hubspot/hubspot.helper';
import { AxiosError } from 'axios';
import { debounce } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { MenuItem } from 'primereact/menuitem';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { FormEvent, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CoursesActionHeader } from './CoursesActionHeader';
import { CoursesTabs } from './CoursesTabs';

export const CourseBranchesPage: React.FC<{ isMaterial?: boolean }> = ({
  isMaterial,
}) => {
  const { t } = useTranslation();
  const { id: courseId } = useParams();
  const { setDialogData } = useContext(DialogContext);
  const toast = useToast();
  const account = useAppSelector(selectCurrentAccount);

  const { course, isLoading: isCourseLoading } = useCourse({
    courseId: courseId,
  });

  const { skip, take, sort, apiFilters, onFilter, onSort, onPage, setSkip } =
    useTable();

  const [showChangeEnrollDateModal, setShowChangeEnrollDateModal] =
    useState(false);
  const [showEnrollModal, setShowEnrollModal] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState<
    CourseBranch | undefined
  >(undefined);

  const [multiSearchValue, setMultiSearchValue] = useState('');
  const debouncedSetMultiSearchValue = useMemo(
    () =>
      debounce((event: FormEvent) => {
        setSkip(0);
        setMultiSearchValue((event.target as HTMLInputElement).value);
      }, 300),
    [],
  );

  const {
    isLoading: isCourseBranchesLoading,
    courseBranches,
    refetch,
  } = useCourseBranches(
    {
      take,
      skip,
      filters: [
        ...apiFilters,
        ...(multiSearchValue.length ? [like('name', multiSearchValue)] : []),
      ],
      sort: sort && sort.length > 0 ? [sort.join(',')] : [],
      enabled: true,
    },
    courseId,
    account?.id,
  );

  const changeScheduleDate = useChangeScheduleDate();
  const handleChangeScheduleDate = async (data: ChangeEnrollDateFormValues) => {
    if (selectedBranch?.schedule && data.date) {
      try {
        await changeScheduleDate.edit({
          scheduleId: selectedBranch?.schedule.id,
          scheduleType: CourseEntityScheduleEnum.BRANCH,
          date: data.date,
          type: CourseScheduleType.STATIC,
        });

        await refetch();
        setSelectedBranch(undefined);
        setShowChangeEnrollDateModal(false);
        toast?.success(
          t('toast.success'),
          t('courses.schedule.date.change.success'),
        );
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
      }
    }
  };

  const changeScheduleEnrollType = useChangeScheduleEnrollType();
  const handleChangeScheduleEnrollType = async (courseBranch: CourseBranch) => {
    if (courseBranch?.schedule?.date) {
      try {
        await changeScheduleEnrollType.edit({
          scheduleId: courseBranch.schedule.id,
          scheduleType: CourseEntityScheduleEnum.BRANCH,
          autoEnroll: !courseBranch?.schedule?.autoEnroll,
          type: CourseScheduleType.STATIC,
        });
        await refetch();
        toast?.success(
          t('toast.success'),
          t('courses.enrollType.change.success'),
        );
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
      }
    }
  };

  const branchesSchedules = useBranchesSchedules();
  const handleEnrollBranch = async (data: EnrollFormValues) => {
    if (course && selectedBranch) {
      try {
        await branchesSchedules.add({
          courses: [course.id],
          branches: [selectedBranch.id],
          date: data.date,
          autoEnroll: data.autoEnroll,
          type: data.type,
        });

        await refetch();
        toast?.success(t('toast.success'), t('courses.branch.enroll.success'));

        hubspotTrack(
          HubspotProperty.NEW_USER_ENROLLED_TO_A_COURSE,
          HubspotValue.YES,
        );
        setSelectedBranch(undefined);
        setShowEnrollModal(false);
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
      }
    }
  };

  const deleteEnrollment = useDeleteEnrollment();
  const handleDeleteEnrollment = async (
    courseId: string,
    courseBranch: CourseBranch,
  ) => {
    try {
      await deleteEnrollment.post({
        courseId,
        request: { [CourseEntityEnrollEnum.BRANCHES]: [courseBranch.id] },
      });

      await refetch();
      toast?.success(t('toast.success'), t('courses.branch.unenroll.success'));

      setSelectedBranch(undefined);
      setShowEnrollModal(false);
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const menuItems = (courseBranch: CourseBranch) => {
    const menu: MenuItem[] = [
      {
        label: t('generic.enroll'),
        icon: 'pi pi-calendar-plus',
        command: () => {
          setSelectedBranch(courseBranch);
          setShowEnrollModal(true);
        },
      },
    ];

    if (courseBranch.schedule) {
      menu.push({
        label: courseBranch?.schedule?.autoEnroll
          ? t('courses.enrollType.change.manual')
          : t('courses.enrollType.change.auto'),
        icon: 'pi pi-sync',
        command: () =>
          setDialogData({
            type: 'confirmation',
            show: true,
            header: t('dialog.enrollType.change'),
            message: t('dialog.enrollType.warning'),
            onAccept: async () => {
              await handleChangeScheduleEnrollType(courseBranch);
            },
          }),
      });
    }

    if (
      courseBranch.schedule &&
      !courseBranch.schedule.executedAt &&
      !courseBranch.enrollment
    ) {
      menu.push({
        label: t('courses.change.enrollDate'),
        icon: 'pi pi-calendar',
        command: () => {
          setSelectedBranch(courseBranch);
          setShowChangeEnrollDateModal(true);
        },
      });
    }

    if (courseBranch?.enrollment) {
      menu.push({
        label: t('generic.unenroll'),
        icon: 'pi pi-times',
        command: () => {
          setDialogData({
            type: 'confirmation',
            show: true,
            header: t('dialog.deleteEnrollment'),
            message: t('dialog.deleteEnrollment.branch', {
              name: courseBranch?.name,
            }),
            onAccept: async () => {
              if (!courseBranch?.enrollment || !courseId) return;
              setSelectedBranch(courseBranch);
              await handleDeleteEnrollment(courseId, courseBranch);
            },
          });
        },
      });
    }

    return menu;
  };

  const columns: DataTableColumnType[] = [
    {
      field: 'name',
      header: t('branch'),
      sortable: true,
      filterable: false,
      style: {
        minWidth: '200px',
      },
      render: (row: CourseBranch) => <div>{row?.name}</div>,
    },
    {
      field: 'enrollDate',
      header: t('generic.enrollDate'),
      sortable: true,
      filterable: true,
      filters: {
        label: t('filter.byDate'),
        field: FilterNamesEnum.COURSE_ENTITY_UPCOMING_DATE,
        type: FilterTypeEnum.SELECT_UPCOMING_PAST,
        placeholder: t('filter.date'),
      },
      render: (row: CourseBranch) => (
        <>
          {row?.enrollDate ? (
            <FormatDate format={DateFormats.TIMEDATE} date={row?.enrollDate} />
          ) : (
            <div>&#8212;</div>
          )}
        </>
      ),
    },
    {
      field: 'inheritedSchedule',
      header: t('courses.inheritedFrom'),
      sortable: false,
      filterable: false,
      render: (row: CourseBranch) => (
        <>
          {row?.inheritedSchedule ? (
            <>
              {row?.schedule ? (
                <span>&#8212;</span>
              ) : row?.inheritedSchedule?.source ===
                CourseEntityScheduleEnum.BRANCH ? (
                <span>{t('branch')}</span>
              ) : (
                <span>{t('account')}</span>
              )}
            </>
          ) : (
            <div>&#8212;</div>
          )}
        </>
      ),
    },
    {
      field: 'users',
      header: t('users'),
      sortable: false,
      filterable: false,
      render: (row: CourseBranch) => <div>{row.usersCount || 0}</div>,
    },
    {
      field: 'schedule.autoEnroll',
      header: t('courses.newUsersEnroll'),
      sortable: true,
      filterable: true,
      filters: {
        field: FilterNamesEnum.COURSE_ENTITY_AUTO_ENROLL,
        type: FilterTypeEnum.SELECT_AUTO_MANUAL,
        placeholder: t('filter.enrollType'),
      },
      render: (row: CourseBranch) => {
        const enrollType = row?.schedule?.autoEnroll ? (
          <AppChip label={t('generic.auto')} type="primary" />
        ) : (
          <AppChip label={t('generic.manual')} type="secondary" />
        );

        return (
          <>
            {row?.schedule?.date ? <div>{enrollType}</div> : <div>&#8212;</div>}
          </>
        );
      },
    },
    {
      field: 'status',
      header: t('generic.status'),
      sortable: true,
      filterable: true,
      filters: {
        label: t('filter.byStatus'),
        field: FilterNamesEnum.ENTITY_BY_STATUS,
        type: FilterTypeEnum.MULTI_SELECT,
        options: branchStatusOptions(t),
      },
      render: (row: CourseBranch) => (
        <div>{determineBranchStatusColor(row?.status, t)}</div>
      ),
    },
    {
      field: 'actions',
      header: t('generic.actions'),
      sortable: false,
      filterable: false,
      style: {
        width: '80px',
        textAlign: 'center',
      },
      render: (row: CourseBranch) => (
        <DataTableActions
          menuItems={menuItems(row)}
          disabled={menuItems(row).length < 1}
        />
      ),
    },
  ];

  // Set the preselected columns
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const defaultVisibleColumns = columns.map((column) => column.field);
  const alwaysVisibleColumns = ['name', 'actions'];
  //

  const toolbar = (
    <DataTableToolbar justify="space-between">
      <DataTableFilters
        filters={getFiltersFromColumns(columns)}
        onFilter={onFilter}
        className="flex-initial"
        tableName={
          isMaterial
            ? TableNamesEnum.MATERIAL_BRANCHES
            : TableNamesEnum.COURSE_BRANCHES
        }
      />
      <div className="p-input-icon-left flex-auto min-w-300">
        <InputText
          className="w-full"
          onInput={debouncedSetMultiSearchValue}
          placeholder={t('courses.branches.search')}
          autoComplete="off"
        />
        <i className="pi pi-search" />
      </div>
      <DataTableColumnsMultiselect
        columns={columns}
        tableName={
          isMaterial
            ? TableNamesEnum.MATERIAL_BRANCHES
            : TableNamesEnum.COURSE_BRANCHES
        }
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        defaultVisibleColumns={defaultVisibleColumns}
        alwaysVisibleColumns={alwaysVisibleColumns}
      />
    </DataTableToolbar>
  );

  return (
    <>
      {isCourseLoading && !course && (
        <FlexContainer direction="column" className="mt-5">
          <ProgressSpinner />
          <h3>
            {isMaterial
              ? t('material.branches.loading')
              : t('course.branches.loading')}
          </h3>
        </FlexContainer>
      )}
      {!isCourseLoading && course && (
        <>
          <CoursesActionHeader course={course} onTriggerRefetch={refetch} />
          {courseId && <CoursesTabs courseId={courseId} type={course.type} />}
          <DataTable
            dataKey="dataId"
            data={courseBranches?.result.map((x) => ({
              ...x,
              dataId: `${x.id}-${x?.schedule?.id}-${x?.inheritedSchedule?.date}`,
            }))}
            count={courseBranches?.count as number}
            isLoading={isCourseBranchesLoading}
            toolbar={toolbar}
            columns={columns}
            visibleColumns={visibleColumns}
            onPage={onPage}
            rows={take}
            skip={skip}
            onSort={onSort}
            sort={sort}
          />

          <EnrollModal
            type={CourseEnrollmentTypeEnum.ENROLL}
            account={account as Account}
            branch={selectedBranch as unknown as Branch}
            enrollType={CourseEntityScheduleEnum.BRANCH}
            visible={showEnrollModal && !!selectedBranch}
            onSubmit={handleEnrollBranch}
            onHide={() => setShowEnrollModal(false)}
            course={course}
          />

          <ChangeEnrollDateModal
            date={selectedBranch?.schedule?.date ?? undefined}
            visible={showChangeEnrollDateModal && !!selectedBranch}
            onSubmit={handleChangeScheduleDate}
            onHide={() => setSelectedBranch(undefined)}
          />
        </>
      )}
    </>
  );
};
