import { FiltersType, ListingRequest, ListingResponse } from '@/api/types';

export const ITEMS_PER_REQUEST = 200;
export const ITEMS_PER_PAYLOAD = 200;
export const ITEMS_PER_LAZY_REQUEST = 10;
export const ITEMS_PER_CATALOGUE = 10;
export const ITEMS_PER_TEMPLATES_MODAL = 6;

export const partialRequests = async (
  filters: FiltersType,
  request: (params: ListingRequest) => Promise<ListingResponse<any>>,
  otherParams?: ListingRequest,
) => {
  const itemsToTake = otherParams?.take || ITEMS_PER_REQUEST;
  const initialResult = await request({
    ...(otherParams ? otherParams : {}),
    take: itemsToTake,
    filters,
  });

  const result = initialResult?.result || [];

  if (!initialResult?.result) return [];

  const usersToFetch = initialResult.count - itemsToTake;
  const fetchesToExecute =
    parseInt(`${usersToFetch / itemsToTake}`) +
    (usersToFetch % itemsToTake && usersToFetch > 0 ? 1 : 0);

  for (let i = 1; i <= fetchesToExecute; i++) {
    const response = await request({
      take: itemsToTake,
      skip: itemsToTake * i,
      filters,
      ...(otherParams ? otherParams : {}),
    });

    result.push(...(response.result ?? []));
  }

  return result;
};
