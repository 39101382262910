import { handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import { SendTestEmailFormValues } from '@/client/campaigns';
import { CourseEntityEnrollEnum } from '@/client/courses';
import { SecurityCultureSendSurveyFormValues } from '@/client/security-culture/types';
import {
  LoadingStatuses,
  RedirectPaths,
  RedirectPathsEnum,
} from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { SecurityCultureSendSurveyForm } from '@/components/security-culture';
import { useSendEmail } from '@/hooks/query/security-culture.hooks';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { FlexContainer } from '@/ui/styled-ui';
import { getTargetEntityIdsByType } from '@/utils/targets';
import { AxiosError } from 'axios';
import { t } from 'i18next';
import React, { useState } from 'react';
import styled from 'styled-components';
import { v4 } from 'uuid';

const StyledText = styled.div`
  line-height: var(--small-line-height);
  font-size: var(--small-font-size);
  margin-block: var(--default-padding);
`;

const pathItems = (account: Account, t: TranslationFunctionType) => [
  {
    label: account?.name,
    url: !account?.isSystem
      ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id)
      : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('security.culture.send.survey'),
    url: RedirectPaths[RedirectPathsEnum.SECURITY_CULTURE_SEND_SURVEY](),
    template: AppBreadCrumbTemplate,
  },
];

export const SecurityCultureSendSurveyPage: React.FC = () => {
  const currentAccount = useAppSelector(selectCurrentAccount);
  const toast = useToast();
  const [emailId, setEmailId] = useState<string | undefined>(undefined);

  const handleFormSubmit = async (
    data: SecurityCultureSendSurveyFormValues,
    resetForm: () => void,
    isTypeForm?: boolean,
  ) => {
    await SendEmails(
      {
        target: data.target,
        targets: data.targets,
        typeFormId: data.typeFormId || undefined,
        url: data.url || undefined,
        scheduledFor: data.scheduledFor || undefined,
      },
      resetForm,
      isTypeForm,
    );
  };

  const handleSendTestEmail = async (
    data: SecurityCultureSendSurveyFormValues,
    test: SendTestEmailFormValues,
  ) => {
    await SendEmails({
      branches: [],
      groups: [],
      users: [],
      typeFormId: data.typeFormId || undefined,
      url: data.url || undefined,
      test: {
        email: test.email,
        firstName: test.firstName,
        lastName: test.lastName,
        language: test.language,
      },
    });
  };

  const sendServeyEmails = useSendEmail();

  const SendEmails = async (
    data: SecurityCultureSendSurveyFormValues,
    resetForm?: () => void,
    isTypeForm?: boolean,
  ) => {
    const isSchedule = data.scheduledFor && !data.test?.email;
    const emailId = v4();
    setEmailId(emailId);

    try {
      if (!currentAccount) return;
      const target = data?.target
        ? { [(data.target as any).type]: [data.target.id] }
        : {};

      const targets = data?.targets?.every(({ id }) => id !== currentAccount.id)
        ? {
            branches: getTargetEntityIdsByType(
              data.targets || [],
              CourseEntityEnrollEnum.BRANCHES,
            ),
            groups: getTargetEntityIdsByType(
              data.targets || [],
              CourseEntityEnrollEnum.GROUPS,
            ),
          }
        : {};

      const response = await sendServeyEmails.send({
        accountId: currentAccount?.id as string,
        ...targets,
        ...target,
        typeFormId: data.typeFormId ?? undefined,
        url: data.url ?? undefined,
        scheduledFor: data.scheduledFor,
        test: data.test
          ? {
              email: data.test.email,
              firstName: data.test.firstName,
              lastName: data.test.lastName,
              language: data.test.language,
            }
          : undefined,
        emailId,
      });

      toast?.success(
        t('toast.success'),
        isSchedule
          ? t('security.culture.emailsScheduled')
          : t('security.culture.emailsQueued'),
        5000,
      );
    } catch (e) {
      handleAxiosError(e as AxiosError, toast);
    } finally {
      if (resetForm) resetForm();
      setEmailId(undefined);
    }
  };

  return (
    <FlexContainer direction="column" align="flex-start">
      <div className="w-full">
        <AppBreadCrumb model={pathItems(currentAccount as Account, t)} />
        <h1>{t('security.culture.send.survey')}</h1>
        <StyledText>{t('security.culture.descr')}</StyledText>

        {currentAccount && (
          <SecurityCultureSendSurveyForm
            onSubmit={handleFormSubmit}
            onSendTestEmail={handleSendTestEmail}
            account={currentAccount}
            loading={emailId ? LoadingStatuses.LOADING : LoadingStatuses.IDLE}
          />
        )}
      </div>
    </FlexContainer>
  );
};
