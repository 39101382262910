import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import {
  getExceptionFromAxiosError,
  getFiltersFromColumns,
} from '@/client/helpers';
import { Reseller } from '@/client/resellers';
import { Actions, Subjects } from '@/client/users';
import {
  RedirectPaths,
  RedirectPathsEnum,
  TableNamesEnum,
} from '@/common/constants';
import { DialogContext } from '@/common/context';
import { TranslationFunctionType } from '@/common/types';
import {
  DataTable,
  DataTableColumnsMultiselect,
  DataTableColumnType,
  DataTableFilters,
  DataTableToolbar,
  FilterTypeEnum,
} from '@/components/tables/crud';
import { DataTableActions } from '@/components/tables/crud/DataTableActions';
import {
  useAccountsToAResellers,
  useRemoveAccountsToAReseller,
  useReseller,
} from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppChip } from '@/ui/chip';
import { FormatDate } from '@/ui/date';
import { FlexContainer } from '@/ui/styled-ui';
import { AxiosError } from 'axios';
import { DataTableRowClickEvent } from 'primereact/datatable';
import { MenuItem } from 'primereact/menuitem';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingPage } from '../LoadingPage';

export const AccountsToAResellerPage = () => {
  const { t } = useTranslation();
  const { id: resellerId } = useParams();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const { can } = usePermission();
  const toast = useToast();
  const { setDialogData } = useContext(DialogContext);
  const navigate = useNavigate();

  const { skip, take, sort, apiFilters, onPage, onSort, onFilter } = useTable();

  const { reseller, isLoading: isResellerLoading } = useReseller({
    resellerId,
  });

  const { accounts, isLoading, refetch } = useAccountsToAResellers({
    resellerId,
    take,
    skip,
    filters: apiFilters,
    sort: sort && sort.length > 0 ? [sort.join(',')] : [],
  });

  const removeAccountFromReseller = useRemoveAccountsToAReseller();

  const handleDeleteAccountFromReseller = async (
    accountId: string,
    resellerId: string,
  ) => {
    try {
      await removeAccountFromReseller.remove({
        resellerId,
        accountId,
      });
      toast?.success(t('toast.success'), t('reseller.removedAccount'));
      refetch();
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const onRowClick = (event: DataTableRowClickEvent) => {
    navigate(RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](event.data.id));
  };

  const columns: DataTableColumnType[] = [
    {
      field: 'id',
      header: t('generic.accountId'),
      sortable: false,
      filterable: true,
      filters: { type: FilterTypeEnum.TEXT },
    },
    {
      field: 'name',
      header: t('generic.name'),
      sortable: true,
      filterable: true,
      filters: { type: FilterTypeEnum.TEXT },
    },
    {
      field: 'active',
      header: t('generic.active'),
      sortable: true,
      filterable: true,
      filters: {
        label: t('filter.status'),
        type: FilterTypeEnum.SELECT_ACTIVE_INACTIVE,
      },
      render: (row: Account) =>
        row.active ? t('generic.active') : t('generic.inactive'),
    },
    {
      field: 'updated',
      header: t('generic.updated'),
      sortable: true,
      filterable: false,
      render: (row: Account) =>
        row?.updated ? <FormatDate date={row?.updated} /> : t('generic.never'),
    },
    {
      field: 'blockSystemEmails',
      header: t('account.blockedSystemEmails'),
      sortable: false,
      filterable: true,
      filters: {
        type: FilterTypeEnum.SELECT_YES_NO,
        placeholder: t('generic.select'),
      },
      render: (row: Account) =>
        row.blockSystemEmails ? (
          <AppChip label={t('dialog.yes')} type="primary" />
        ) : (
          <AppChip label={t('dialog.no')} type="secondary" />
        ),
    },
    {
      field: 'actions',
      header: t('generic.actions'),
      sortable: false,
      filterable: false,
      style: {
        width: '80px',
        textAlign: 'center',
      },
      render: (row: Account) => (
        <DataTableActions
          disabled={menuItems(row, reseller as Reseller).length < 1}
          menuItems={menuItems(row, reseller as Reseller)}
        />
      ),
    },
  ];

  // Set the preselected columns
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const defaultVisibleColumns = columns
    .filter((column) => column.field !== 'id')
    .map((column) => column.field);
  const alwaysVisibleColumns = ['name', 'actions'];
  //

  const menuItems = (account: Account, reseller: Reseller) => {
    const actions = [];

    if (
      can(Actions.UPDATE, Subjects.RESELLERS) &&
      currentAccount &&
      currentAccount.isSystem &&
      resellerId
    ) {
      actions.push({
        label: t('generic.remove'),
        icon: 'pi pi-times',
        command: () => {
          setDialogData({
            type: 'confirmation',
            show: true,
            header: t('dialog.remove'),
            message: t('dialog.remove.resellerName.account', {
              name: reseller?.name,
            }),
            onAccept: async () =>
              handleDeleteAccountFromReseller(account.id, resellerId),
          });
        },
      });
    }

    return actions;
  };

  const toolbar = (
    <DataTableToolbar>
      <FlexContainer
        justify="space-between"
        gap={8}
        align="flex-start"
        wrap="wrap"
      >
        <DataTableFilters
          filters={getFiltersFromColumns(columns)}
          onFilter={onFilter}
          defaultValues={{
            name: ' ',
          }}
          tableName={TableNamesEnum.RESELLERS_ACCOUNTS_LIST}
        />
      </FlexContainer>
      <DataTableColumnsMultiselect
        columns={columns}
        tableName={TableNamesEnum.RESELLERS_ACCOUNTS_LIST}
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        defaultVisibleColumns={defaultVisibleColumns}
        alwaysVisibleColumns={alwaysVisibleColumns}
      />
    </DataTableToolbar>
  );

  const getPathItems = (
    reseller: Reseller,
    t: TranslationFunctionType,
  ): MenuItem[] => {
    return [
      {
        label: t('resellers'),
        url: RedirectPaths[RedirectPathsEnum.RESELLERS](),
        template: AppBreadCrumbTemplate,
      },
      {
        label: reseller?.name,
        url: RedirectPaths[RedirectPathsEnum.EDIT_RESELLERS](reseller?.id),
        className: 'active',
        template: AppBreadCrumbTemplate,
      },
      {
        label: t('accounts'),
        url: RedirectPaths[RedirectPathsEnum.RESELLERS_ACCOUNTS](reseller?.id),
        template: AppBreadCrumbTemplate,
      },
    ];
  };

  if (isResellerLoading) {
    return <LoadingPage message={t('generic.loading')} />;
  }

  return (
    <>
      {reseller && <AppBreadCrumb model={getPathItems(reseller, t)} />}
      <h1>{t('reseller.accounts')}</h1>
      <DataTable
        data={accounts?.result}
        count={accounts?.count as number}
        isLoading={isLoading || isResellerLoading}
        toolbar={toolbar}
        columns={columns}
        visibleColumns={visibleColumns}
        onPage={onPage}
        rows={take}
        skip={skip}
        onSort={onSort}
        sort={sort}
        onRowClick={onRowClick}
      />
    </>
  );
};
