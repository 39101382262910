import { handleAxiosError } from '@/api/helpers';
import { client } from '@/client';
import { SendTestEmailFormValues } from '@/client/campaigns';
import { DialogContext } from '@/common/context';
import { SendTestEmailModal } from '@/components/campaigns/SendTestEmailModal';
import { useNotifications } from '@/hooks/notifications.hook';
import { useSendTestEmail } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import { selectCurrentUser } from '@/store/features/users';
import { AppButton } from '@/ui/buttons';
import { AxiosError } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';

type SendTestEmailProps = {
  campaignId: string;
  lastSavedData: {
    emailTemplateId?: string;
    landingPageId?: string;
    sendingProfileId?: string;
    encodeEmailTemplateImages?: boolean;
  };
};
export const SendTestEmail: React.FC<SendTestEmailProps> = ({
  campaignId,
  lastSavedData,
}) => {
  const { t } = useTranslation();
  const { setDialogData } = useContext(DialogContext);
  const toast = useToast();
  const currentUser = useAppSelector(selectCurrentUser);

  const [showSendTestEmail, setShowSendTestEmail] = useState(false);

  const sendTestEmail = useSendTestEmail();
  const {
    lastMessage,
    setNotificationParam: setJobId,
    notificationParam: jobId,
  } = useNotifications(client.senderProfiles.senderProfilesJobNotifyUrl);

  useEffect(() => {
    if (!jobId) return;
    handleNotifyEmailSent(lastMessage);
  }, [JSON.stringify(lastMessage)]);

  const handleNotifyEmailSent = (messages: any) => {
    if (
      !messages?.data?.event ||
      ![
        'sending-profile.email-sent',
        'sending-profile.email-not-sent',
      ].includes(messages?.data?.event)
    )
      return;

    if (messages?.data?.event === 'sending-profile.email-sent')
      toast?.success(t('toast.success'), t('campaign.testEmail.sent'), 5000);
    if (messages?.data?.event === 'sending-profile.email-not-sent')
      toast?.info(t('toast.info'), t('campaign.testEmail.notSent'), 5000);

    setJobId(undefined);
  };

  const handleSendTestEmail = async (data: SendTestEmailFormValues) => {
    if (
      !campaignId ||
      !lastSavedData.emailTemplateId ||
      !lastSavedData.sendingProfileId ||
      !lastSavedData.landingPageId
    )
      return;

    try {
      const response = await sendTestEmail.send({
        email:
          data.emailType === 'custom'
            ? data.email
            : (data.admin?.email as string),
        firstName:
          data.emailType === 'custom' ? data.firstName : data.admin?.firstName,
        lastName:
          data.emailType === 'custom' ? data.lastName : data.admin?.lastName,
        position: data.emailType === 'custom' ? data.position : undefined,
        campaignId: campaignId as string,
        emailTemplateId: lastSavedData.emailTemplateId,
        sendingProfileId: lastSavedData.sendingProfileId,
        landingPageId: lastSavedData.landingPageId,
        encodeEmailTemplateImages: lastSavedData.encodeEmailTemplateImages,
        language: data?.language,
      });

      setJobId(response.jobId);
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }

    setShowSendTestEmail(false);
  };

  const handleValidateTestEmail = async () => {
    const prepareValidationMessage = (fieldLabel: string) => `${fieldLabel}`;

    const schema = object().shape({
      emailTemplateId: string().required(
        prepareValidationMessage(t('templates.email')),
      ),
      landingPageId: string().required(
        prepareValidationMessage(t('templates.pageTemplate')),
      ),
      sendingProfileId: string().required(
        prepareValidationMessage(t('campaign.sender')),
      ),
    });

    if (!campaignId) {
      setDialogData({
        show: true,
        type: 'info',
        header: t('camapign.testEmail.send'),
        message: t('campaign.notCreated'),
      });

      return;
    }

    try {
      await schema.validate(lastSavedData, { abortEarly: false });
      setShowSendTestEmail(true);
    } catch (error: any) {
      setDialogData({
        show: true,
        type: 'info',
        header: t('camapign.testEmail.send'),
        message: t('campaign.testEmail.select', {
          items: error.errors.join(', '),
        }),
      });
    }
  };

  return (
    <>
      <AppButton
        label={t('camapign.testEmail.send')}
        severity="secondary"
        type="outlined"
        onClick={handleValidateTestEmail}
        className="mb-4"
        data-testid="send-test-email-button"
      />
      <SendTestEmailModal
        email={currentUser?.email as string}
        visible={showSendTestEmail}
        onSubmit={handleSendTestEmail}
        onHide={() => setShowSendTestEmail(false)}
      />
    </>
  );
};
