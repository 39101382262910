import { handleAxiosError } from '@/api/helpers';
import { client } from '@/client';
import { LoadingStatuses } from '@/common/constants';
import { keycloakLanguages } from '@/common/constants/languages';
import { LanguageContext } from '@/common/context/LanguageContext';
import { useToast } from '@/hooks/useToast';
import LogoBlack from '@/images/logo-black.svg';
import Logo from '@/images/logo.svg';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { AxiosError } from 'axios';
import { t } from 'i18next';
import { InputText } from 'primereact/inputtext';
import { Menu } from 'primereact/menu';
import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const StyledFlexContainer = styled(FlexContainer)`
  position: relative;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: var(--red-main);

  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    opacity: 0.05;
    top: 10%;
    left: -5%;
    height: 80%;
    width: 300%;
    background: var(--red-main) url(${Logo}) no-repeat center left;
    background-size: contain;
  }
`;

const StyledCard = styled.div`
  width: 500px;
  padding: var(--big-padding);
  border-radius: var(--xxxsmall-border-radius);
  border-top: 5px solid var(--black-main);
  background: var(--white-main);
  box-shadow: var(--box-shadow-black);
  margin: 0 -10px 20px;
  z-index: 1;

  input:focus {
    border-color: var(--gray-darker) !important;
    box-shadow: var(--box-shadow-gray-no-blur) !important;
  }
`;

const StyledTitle = styled.h1`
  color: var(--black-main);
  text-align: left;
  font-size: var(--heading-small-font-size);
  line-height: var(--heading-small-line-height);
  font-weight: 700;
  margin: 0;
  margin-bottom: var(--default-padding);
`;

const StyledAppButton = styled(AppButton)`
  background: var(--black-main) !important;
  border-color: var(--black-main) !important;
  width: 100%;
`;

const StyledLanguageButton = styled(AppButton)`
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  background-color: transparent;
  border: 0;
  padding: 0 !important;
  height: auto !important;

  .p-button-label,
  .p-button-icon {
    color: var(--black-main) !important;
  }
`;

export const LoginPage = () => {
  const toast = useToast();
  const menu = useRef<Menu>(null);
  const [username, setUsername] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { changeLanguage, language, setForceChangeLanguage } =
    useContext(LanguageContext);

  const items = Object.keys(keycloakLanguages).map((lang) => {
    const languageKey = lang as keyof typeof keycloakLanguages;

    return {
      label: keycloakLanguages[languageKey].label,
      value: keycloakLanguages[languageKey].value,
      command: () => {
        changeLanguage(lang);
      },
    };
  });

  const handleUsernameInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handleNextClick = async () => {
    try {
      setIsLoading(true);

      const response = await client.auth.getLoginURL(username);
      const languageKey = language as keyof typeof keycloakLanguages;

      window.location.href = `${response.url}?login_hint=${username}&ui_locales=${keycloakLanguages[languageKey].value}`;
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setForceChangeLanguage(true);
  }, []);

  return (
    <StyledFlexContainer>
      <StyledCard>
        <FlexContainer justify="space-between" style={{ marginBottom: '24px' }}>
          <img src={LogoBlack} height={24} alt="Logo" />
          <div>
            <Menu
              model={items}
              popup
              ref={menu}
              onMouseLeave={(event) => menu.current?.hide(event)}
            />
            <div onMouseEnter={(event) => menu.current?.show(event)}>
              <StyledLanguageButton
                label={
                  keycloakLanguages[language as keyof typeof keycloakLanguages]
                    .label
                }
                type="text"
                icon="pi pi-chevron-down"
                iconPos="right"
                aria-haspopup
              />
            </div>
          </div>
        </FlexContainer>
        <StyledTitle>{t('account.sign.in')}</StyledTitle>
        <div className="field w-full mb-4">
          <label htmlFor="username">{t('generic.username')}</label>
          <InputText
            id="username"
            className="w-full"
            onInput={handleUsernameInput}
            disabled={isLoading}
          />
        </div>
        <StyledAppButton
          label={t('button.next')}
          isDisabled={!username}
          state={isLoading ? LoadingStatuses.LOADING : LoadingStatuses.IDLE}
          onClick={handleNextClick}
        />
      </StyledCard>
    </StyledFlexContainer>
  );
};
