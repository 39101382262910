import { LanguagesEnum } from '@/api/enums';
import { ListingRequest, ListingResponse } from '@/api/types';
import { Account } from '@/client/accounts';
import { Branch } from '@/client/branches';
import { File, FileType } from '@/client/files';
import { Group } from '@/client/groups';
import { LearnerCourseDispatchResponse } from '@/client/learner-course';
import { PlannerType } from '@/client/planner/types';
import { Role } from '@/client/roles';
import { User } from '@/client/users';

export enum CourseAvailabilityOptionsEnum {
  AUTO = 'auto-available',
  CUSTOM = 'custom',
}

export enum CourseAvailabilityTypeEnum {
  MAKE_AVAILABLE = 'make-available',
  MAKE_UNAVAILABLE = 'make-unavailable',
}

export enum CourseEnrollmentOptionsEnum {
  AUTO = 'auto-enrolled',
  CUSTOM = 'custom',
}

export enum CourseEnrollmentTypeEnum {
  ENROLL = 'enroll',
  UNENROLL = 'unenroll',
}

export enum BranchStatusEnum {
  NOT_SCHEDULED = 'not-scheduled',
  SCHEDULED = 'scheduled',
  ENROLLED = 'enrolled',
}

export enum UserStatusEnum {
  IN_PROGRESS = 'in-progress',
  COMPLETED = 'completed',
  FAILED = 'failed',
  ENROLLED = 'enrolled',
  NOT_SCHEDULED = 'not-scheduled',
  SCHEDULED = 'scheduled',
}

export enum MaterialType {
  COURSE = 'course',
  RESOURCE = 'template',
  CUSTOM_MATERIAL = 'document',
  POSTER = 'poster',
}

export type CourseTranslationsType = {
  language: string;
  name: string;
  description: string;
};

export type ModifiedCourseTranslationsType = {
  [language: string]: {
    name: string;
    description: string;
  };
};

export type CourseFilesType = {
  id?: string;
  language: string;
  file: File | null;
  fileMeta?: CourseFileMeta;
  priority?: number;
};

export type CourseFileModule = {
  type?: keyof typeof FileType;
  scormDetails?: LearnerCourseDispatchResponse;
} & CourseFilesType;

export type ModifiedCourseFilesType = {
  [language: string]: {
    file: File | null;
    fileMeta?: CourseFileMeta;
    priority?: number;
  };
}[];

type CourseFileMeta = {
  dispatchFile: string;
  remote: string;
};

type CourseMeta = {
  eFrontCourseIds: string[];
  eFrontCourses: EfrontCourse[];
  eFrontMigrationStatus: number;
  eFrontMigrationErrors: string[];
};

export type CourseAccountSchedule = {
  id: string;
  account: { id: string };
  autoEnroll: boolean;
  course: Course;
  scheduledBy: User;
};

export enum CourseStatusAttemptEnum {
  IN_PROGRESS = 'in-progress',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export type FilterableCourseAttemptStatus = Extract<
  CourseStatusAttemptEnum,
  CourseStatusAttemptEnum.IN_PROGRESS | CourseStatusAttemptEnum.COMPLETED
>;

export type CourseAttempt = {
  id: string;
  course: Course | null;
  user: User | null;
  status: CourseStatusAttemptEnum;
  created: Date;
  updated: Date;
  payload: Record<string, any>;
  score: number;
  language?: LanguagesEnum;
  completed?: Date;
};

export type Course = {
  id: string;
  name: string;
  description: string;
  courseTranslations: CourseTranslationsType[];
  courseLanguages: LanguagesEnum[];
  active: boolean;
  courseFiles: CourseFilesType[];
  courseThumbnail: File;
  attempts: CourseAttempt[];
  created: Date;
  updated: Date | null;
  courseCreatedAt: Date;
  courseLastUpdatedAt: Date;
  type: MaterialType;
  trial: boolean;
  draft: boolean;
  retired: boolean;
  selfHosted: boolean;
  isStandardCatalogue: boolean;
  releaseDate: Date | null;
  availableToAccounts: Account[];
  account: Account;
  meta: CourseMeta;
  hubspotProperties?: {
    hubspotProperty?: string;
    hubspotDateProperty?: string;
  };
  scheduledAccountsPivot: CourseAccountSchedule[];
  autoEnroll: boolean | null;
  priority?: number | null;
  label?: PlannerType[];
  categories?: PosterCategory[];
  completionPercentage: number | null;
  totalNumberOfEnrollments: number | null;
};

export enum PosterCategory {
  COURSE_POSTERS = 'course-posters',
  CYBERSECURITY = 'cybersecurity',
  GDPR = 'gdpr',
  INFO_SCREEN = 'info-screen',
  INFOGRAPHICS = 'infographics',
}

export type ListCoursesRequest = ListingRequest;
export type ListCoursesResponse = ListingResponse<Course>;

export type CourseFormState = {
  id?: string;
  courseTranslations: ModifiedCourseTranslationsType[];
  courseLanguages?: LanguagesEnum[];
  courseThumbnail?: string;
  courseFiles: ModifiedCourseFilesType;
  courseCreatedAt?: Date;
  courseLastUpdatedAt?: Date;
  type: MaterialType;
  trial?: boolean;
  retired?: boolean;
  customContent?: boolean;
  customAccount?: string | null;
  selfHosted: boolean;
  isStandardCatalogue?: boolean;
  releaseDate: Date | null;
  eFrontCourses?: EfrontCourse[];
  hubspotProperty?: string;
  hubspotDateProperty?: string;
  priority?: number | null;
  label?: PlannerType[];
  categories?: PosterCategory[];
};

export type SaveCourseRequest = {
  courseTranslations: CourseTranslationsType[];
  courseLanguages?: LanguagesEnum[];
  courseThumbnail?: string | null;
  courseFiles: CourseFilesType[];
  courseCreatedAt?: Date;
  courseLastUpdatedAt?: Date;
  type?: MaterialType;
  trial?: boolean;
  isStandardCatalogue?: boolean;
  releaseDate: Date | null;
  account: string | null;
  eFrontCourseIds?: string[];
  hubspotProperty?: string;
  hubspotDateProperty?: string;
  priority?: number | null;
  label?: PlannerType[];
  categories?: PosterCategory[];
};

export type UpdateCourse = {
  courseTranslations?: CourseTranslationsType[];
  courseLanguages?: LanguagesEnum[];
  courseThumbnail?: string | null;
  courseFiles?: CourseFilesType[];
  courseCreatedAt?: Date;
  courseLastUpdatedAt?: Date;
  type?: MaterialType;
  trial?: boolean;
  isStandardCatalogue?: boolean;
  releaseDate?: Date | null;
  account?: string | null;
  selfHosted?: boolean;
  retired?: boolean;
  eFrontCourseIds?: string[];
  hubspotProperty?: string;
  hubspotDateProperty?: string;
  priority?: number | null;
  label?: PlannerType[];
  categories?: PosterCategory[];
};

export type UpdateCourseRequest = {
  updates: UpdateCourse;
  courseId: string;
};

export type CourseAvailabilityFormValues = {
  accounts: Account[];
};

export type MakeAvailableAccountsRequest = {
  courseId: string;
  accounts: string[];
};

export type MakeAvailableCoursesToAccountRequest = {
  courses: string[];
  accountId: string;
};

export type MakeAvailableCoursesToAccountFormValues = {
  courses: Course[];
  isCatalogueEnabled: boolean;
  isPosterCatalogueEnabled: boolean;
};

// Course Schedule

export enum CourseEntityScheduleEnum {
  USER = 'user',
  BRANCH = 'branch',
  GROUP = 'group',
  ACCOUNT = 'account',
}

export enum CourseEntityEnrollEnum {
  USERS = 'users',
  BRANCHES = 'branches',
  GROUPS = 'groups',
  ACCOUNT = 'account',
}

export const mapScheduleEntityEnumToEnrollEnum = {
  [CourseEntityScheduleEnum.USER]: CourseEntityEnrollEnum.USERS,
  [CourseEntityScheduleEnum.GROUP]: CourseEntityEnrollEnum.GROUPS,
  [CourseEntityScheduleEnum.BRANCH]: CourseEntityEnrollEnum.BRANCHES,
  [CourseEntityScheduleEnum.ACCOUNT]: CourseEntityEnrollEnum.ACCOUNT,
};

export enum CourseScheduleType {
  STATIC = 'static',
  DYNAMIC = 'dynamic',
}

export type CourseEntity = {
  id: string;
  name: string;
  thumbnailUrl?: string;
  releaseDate: Date | null;
};

export type CourseSchedule = {
  account?: { id: string; name: string };
  course?: CourseEntity;
  id: string;
  date: Date;
  unEnrolledAt: Date;
  dueDate: Date | null;
  executedAt: Date;
  autoEnroll: boolean;
  entity: { id: string; name: string; type: CourseEntityScheduleEnum };
  scheduledBy: { id: string; name: string };
  usersCount: number;
  type: CourseScheduleType;
};

export type CourseAccount = {
  available: boolean;
  branchesCount: number;
  groupsCount: number;
  usersCount: number;
  enrolledBranchesCount: number;
  enrolledGroupsCount: number;
  enrolledUsersCount: number;
  id: string;
  name: string;
  schedule: {
    id: string;
    date: Date | null;
    executedAt: Date | null;
    autoEnroll: boolean;
  };
};

export type CourseBranch = {
  enrolledUsersCount: number;
  id: string;
  name: string;
  usersCount: number;
  schedule: {
    id: string;
    date: Date | null;
    executedAt: Date | null;
    autoEnroll: boolean;
  } | null;
  enrollment: {
    enrollmentDate: Date;
    source: CourseEntityScheduleEnum;
  } | null;
  status: BranchStatusEnum;
  enrollDate: Date;
  inheritedSchedule: {
    date: Date;
    source: CourseEntityScheduleEnum;
  } | null;
};

export type CourseUser = {
  id: string;
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  role: Role;
  schedule: {
    id: string;
    date: Date | null;
    executedAt: Date | null;
  } | null;
  enrollment: {
    dueDate: Date | null;
    enrollmentDate: Date;
    source: CourseEntityScheduleEnum;
  } | null;
  status: UserStatusEnum;
  enrollDate: Date;
  inheritedSchedule: {
    date: Date;
    source: CourseEntityScheduleEnum;
  } | null;
};

export type BaseEnrollFormValues = {
  courses: Course[];
  date: Date | null;
  type: CourseScheduleType;
  autoEnroll?: boolean;
};

export type AccountsEnrollFormValues = {
  accounts: Account[];
} & BaseEnrollFormValues;

export type EnrollFormValues = {
  accounts?: Account[];
  branches?: Branch[];
  groups?: Group[];
  users?: User[];
} & BaseEnrollFormValues;

export type EnrollManyToManyFormValues = {
  enrollTo: CourseEntityScheduleEnum;
} & EnrollFormValues;

type BaseScheduleRequest = {
  courses: string[];
  date: Date | null;
  autoEnroll?: boolean;
  type: CourseScheduleType;
};

export type AccountsSchedulesRequest = {
  accounts: string[];
} & BaseScheduleRequest;

export type BranchesSchedulesRequest = {
  branches: string[];
} & BaseScheduleRequest;

export type GroupsSchedulesRequest = {
  groups: string[];
} & BaseScheduleRequest;

export type UsersSchedulesRequest = {
  users: string[];
} & BaseScheduleRequest;

export type ChangeEnrollDateFormValues = {
  date: Date | undefined;
  type: CourseScheduleType;
};

export type ChangeScheduleDateRequest = {
  scheduleId: string;
  scheduleType: CourseEntityScheduleEnum;
  date: Date;
  type: CourseScheduleType;
};

export type ChangeScheduleEnrollTypeRequest = {
  scheduleId: string;
  scheduleType: CourseEntityScheduleEnum;
  autoEnroll?: boolean;
  type: CourseScheduleType;
};

export type DeleteScheduleRequest = {
  entity: any;
  id: string;
  source: CourseEntityScheduleEnum;
};

export type DeleteEnrollmentRequest = {
  account?: string[];
  branches?: string[];
  groups?: string[];
  users?: string[];
};

export type DeleteEnrollmentPayload = {
  courseId: string;
  request: DeleteEnrollmentRequest;
};

export type DeleteEnrollmentFormValues = {
  courseId: string;
  entity: CourseEntityEnrollEnum;
  value: Account[] | Branch[] | Group[] | User[];
};

export type ForceCompleteFormValues = { score: number };

export type ListCourseScheduleRequest = ListingRequest;
export type ListCourseScheduleResponse = ListingResponse<CourseSchedule>;

export type ListAccountScheduleRequest = ListingRequest;
export type ListAccountScheduleResponse = ListingResponse<CourseSchedule>;

export type ListCourseAccountRequest = ListingRequest;
export type ListCourseAccountResponse = ListingResponse<CourseAccount>;

export type ListCourseBranchesRequest = ListingRequest;
export type ListCourseBranchesResponse = ListingResponse<CourseBranch>;

export type ListCourseGroupsRequest = ListingRequest;
export type ListCourseGroupsResponse = ListingResponse<CourseBranch>;

export type ListCourseUsersRequest = ListingRequest;
export type ListCourseUsersResponse = ListingResponse<CourseUser>;

// Efront Courses
export type EfrontCourse = {
  id: string;
  name: string;
  active: boolean;
  created: Date;
  updated?: Date | null;
  meta: {
    eFrontId: string;
    eFrontPublicId: string;
  };
};

export type ForceCompleteRequest = {
  courseId: string;
  userId: string;
  score: number;
  accountId: string;
};
