import { LanguagesEnum } from '@/api/enums';
import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardType, LAST_SELECTED_DASHBOARD } from '../constants';
import { learnerOnlyLanguages } from '../constants/languages';

export const LanguageContext = createContext<{
  language: LanguagesEnum | string;
  changeLanguage: (language?: LanguagesEnum | string) => void;
  setForceChangeLanguage: (isForced: boolean) => void;
}>({
  language: '',
  changeLanguage: () => ({}),
  setForceChangeLanguage: () => ({}),
});

export const LanguageContextComponent: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const [forceChangeLanguage, setForceChangeLanguage] = useState(false);

  const handleChangeLanguage = (language?: LanguagesEnum | string) => {
    if (!language) {
      setLanguage(i18n.language);
      return;
    }

    const isAdminDashboard =
      localStorage.getItem(LAST_SELECTED_DASHBOARD) === DashboardType.ADMIN;
    if (
      !forceChangeLanguage &&
      isAdminDashboard &&
      learnerOnlyLanguages.includes(language as LanguagesEnum)
    ) {
      setLanguage(LanguagesEnum.EN);
    } else {
      setLanguage(language);
    }
  };

  useEffect(() => {
    const changeLanguage = async () => {
      await i18n.changeLanguage(language);
    };

    changeLanguage();
  }, [language]);

  return (
    <LanguageContext.Provider
      value={{
        language,
        changeLanguage: handleChangeLanguage,
        setForceChangeLanguage,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
