import { Conditions } from '@/api/enums';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import { HubspotProperty, HubspotValue } from '@/client/hubspot';
import { Actions, Subjects } from '@/client/users';
import {
  LoadingStatuses,
  RedirectPaths,
  RedirectPathsEnum,
} from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { CampaignList, TryForFreePhishing } from '@/components/campaigns';
import { CampaignsReports } from '@/components/dashboards/admin';
import { useCampaigns, useCampaignsSummary } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { usePermission } from '@/hooks/usePermission';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUserState } from '@/store/features/users';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { hubspotTrack } from '@/utils/hubspot';
import { MenuItem } from 'primereact/menuitem';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const getBreadcrumbs = (
  account: Account,
  t: TranslationFunctionType,
): MenuItem[] => {
  return [
    {
      label: account?.name,
      url: !account?.isSystem
        ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id)
        : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('campaigns'),
      url: RedirectPaths[RedirectPathsEnum.CAMPAIGNS](),
      template: AppBreadCrumbTemplate,
    },
  ];
};

export const CampaignsPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const account = useAppSelector(selectCurrentAccount);
  const { can } = usePermission();
  const { canUseFeature } = useFeatureFlag();
  const { user } = useAppSelector(selectCurrentUserState);

  const { isLoading: areCampaignsLoading, campaigns } = useCampaigns({
    withTemplates: 0,
    take: 0,
    skip: 0,
    enabled: !account?.isSystem,
    filters: [
      {
        field: 'account',
        condition: Conditions.EQUAL,
        value: (account as Account)?.id,
      },
    ],
  });

  const { isLoading: isLoadingSummary, campaignsSummary } = useCampaignsSummary(
    {
      accountId: account?.id,
    },
  );

  useEffect(() => {
    hubspotTrack(HubspotProperty.PHISHING_CAMPAIGN_VIEW, HubspotValue.YES);
  }, []);

  return (
    <FlexContainer
      direction="column"
      align="flex-start"
      justify="flex-start"
      style={{ minHeight: '100%' }}
    >
      <AppBreadCrumb model={getBreadcrumbs(account as Account, t)} />
      {!campaigns?.count &&
      canUseFeature(Subjects.TRY_FOR_FREE) &&
      !user?.account?.isSystem ? (
        <TryForFreePhishing isLoading={areCampaignsLoading} />
      ) : (
        <>
          <FlexContainer
            align="baseline"
            justify="space-between"
            className="mt-2"
          >
            <h1 className="mb-0">{t('campaigns')}</h1>
            {!account?.isSystem &&
              canUseFeature(Subjects.CAMPAIGNS_CATALOGUE) && (
                <AppButton
                  label={t('campaigns.catalogue')}
                  severity="secondary"
                  state={
                    areCampaignsLoading
                      ? LoadingStatuses.LOADING
                      : LoadingStatuses.IDLE
                  }
                  onClick={() =>
                    navigate(
                      RedirectPaths[RedirectPathsEnum.CAMPAIGNS_CATALOGUE](),
                    )
                  }
                />
              )}
          </FlexContainer>
          {can(Actions.READ, Subjects.ACCOUNT_CAMPAIGNS_SUMMARY) &&
            !isLoadingSummary &&
            campaignsSummary &&
            !account?.isSystem &&
            account && (
              <CampaignsReports
                campaignsSummary={campaignsSummary}
                skipADConfigFetch
                isLoading={isLoadingSummary}
                t={t}
                className="mt-4 w-full"
              />
            )}
          <CampaignList />
        </>
      )}
    </FlexContainer>
  );
};
