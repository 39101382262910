import { extractSubdomain, isKeycloakEnabled } from '@/auth';
import { SystemRoles } from '@/client/users';
import { LAST_SELECTED_LANGUAGE } from '@/common/constants';
import { TOKEN_EXPIRES_KEY } from '@/common/constants/user';
import { LanguageContext } from '@/common/context/LanguageContext';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { LoadingPage } from '@/pages';
import {
  getAccountBySubdomainAction,
  selectCurrentAccount,
  setCurrentAccount,
} from '@/store/features/account';
import {
  loadCurrentUserAction,
  selectCurrentUserState,
} from '@/store/features/users';
import { isOutsideOfEfront } from '@/utils/helpers';
import { identifyHubspotContact } from '@/utils/hubspot';
import * as Sentry from '@sentry/browser';
import moment from 'moment/moment';
import { Message } from 'primereact/message';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { Outlet } from 'react-router-dom';
import { Md5 } from 'ts-md5';

export const AuthLoadUser: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const tokenExpiresOn = sessionStorage.getItem(TOKEN_EXPIRES_KEY);
  const account = useAppSelector(selectCurrentAccount);
  const { changeLanguage, setForceChangeLanguage } =
    useContext(LanguageContext);
  const { signoutSilent } = useAuth();

  const { loading, loaded, user, error } = useAppSelector(
    selectCurrentUserState,
  );

  useEffect(() => {
    if (
      !isKeycloakEnabled() &&
      (!tokenExpiresOn || moment(tokenExpiresOn).utc().isBefore(moment().utc()))
    ) {
      return;
    }

    loadCurrentUserAction(dispatch);
  }, [tokenExpiresOn]);

  useEffect(() => {
    if (!user) {
      Sentry.setUser(null);
      return;
    }

    setForceChangeLanguage(false);
    changeLanguage(user.language);
    Sentry.setUser({ id: Md5.hashStr(user.id) });

    if (user?.role?.code !== SystemRoles.LEARNER || user?.account.isFreeTrial) {
      identifyHubspotContact(user);
    }
  }, [user]);

  useEffect(() => {
    if (!user) {
      return;
    }

    if (!account) {
      const subdomain = extractSubdomain(window.location);

      if (
        isOutsideOfEfront() &&
        !!subdomain &&
        subdomain !== 'app' &&
        subdomain !== 'awareness'
      ) {
        dispatch(getAccountBySubdomainAction(window.location));
        return;
      }

      dispatch(setCurrentAccount(user.account));
    }
  }, [user, account]);

  useEffect(() => {
    if (!error) {
      return;
    }

    signoutSilent();
    sessionStorage.clear();
  }, [error]);

  if (error) {
    return <Message text={t('user.loadingError')} severity="error" />;
  }

  if (loading || !loaded || !account) {
    return <LoadingPage message={t('user.loading')} />;
  }

  if (loaded && user?.language) {
    localStorage.setItem(LAST_SELECTED_LANGUAGE, user.language);
  }

  return <Outlet />;
};
