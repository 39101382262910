import { handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import {
  CourseEntityScheduleEnum,
  EnrollManyToManyFormValues,
} from '@/client/courses';
import { HubspotProperty, HubspotValue } from '@/client/hubspot/types';
import { Actions, Subjects } from '@/client/users';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { ManyToManyEnrollModal } from '@/components/courses/modals/ManyToManyEnrollModal';
import {
  useAccountsSchedules,
  useBranchesSchedules,
  useGroupsSchedules,
  useUsersSchedules,
} from '@/hooks/query';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { hubspotTrack } from '@/utils/hubspot/hubspot.helper';
import { AxiosError } from 'axios';
import moment from 'moment';
import { MenuItem } from 'primereact/menuitem';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type AccountEnrollmentActionHeaderProps = {
  account: Account;
  onTriggerRefetch?: () => void;
  isMaterial?: boolean;
  isTrainingPlanner?: boolean;
  setIsCreatePromptVisible?: (isVisible: boolean) => void;
};

const StyledFlexContainer = styled(FlexContainer)`
  margin-bottom: var(--small-padding);
`;

const getBreadcrumbs = (
  account: Account,
  t: TranslationFunctionType,
  canUseFeature: (subject: Subjects) => boolean,
  isMaterial?: boolean,
  isTrainingPlanner?: boolean,
): MenuItem[] => [
  {
    label: account?.name,
    url: !account?.isSystem
      ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id)
      : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
    template: AppBreadCrumbTemplate,
  },
  {
    label: isMaterial ? t('resources') : t('courses'),
    url: RedirectPaths[
      isMaterial ? RedirectPathsEnum.RESOURCES : RedirectPathsEnum.COURSES
    ](),
    className: 'active',
    template: AppBreadCrumbTemplate,
  },
  ...(isTrainingPlanner
    ? [
        {
          label: t('courses.trainingPlanner'),
          url: RedirectPaths[RedirectPathsEnum.ACCOUNT_COURSE_PLANNER](),
          template: AppBreadCrumbTemplate,
        },
      ]
    : [
        {
          label: canUseFeature(Subjects.TRAINING_PLANNER)
            ? t('courses.eventLog')
            : t('schedule'),
          url: RedirectPaths[
            isMaterial
              ? RedirectPathsEnum.ACCOUNT_DOCUMENTS_SCHEDULE
              : RedirectPathsEnum.ACCOUNT_COURSE_SCHEDULE
          ](),
          template: AppBreadCrumbTemplate,
        },
      ]),
];

export const AccountEnrollmentActionHeader: React.FC<
  AccountEnrollmentActionHeaderProps
> = ({
  account,
  onTriggerRefetch,
  isMaterial,
  isTrainingPlanner,
  setIsCreatePromptVisible,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { can } = usePermission();
  const { canUseFeature } = useFeatureFlag();

  const [showEnrollModal, setEnrollModal] = useState(false);

  const checkAndTrackHubspotEvent = () => {
    if (!isMaterial) {
      hubspotTrack(
        HubspotProperty.NEW_USER_ENROLLED_TO_A_COURSE,
        HubspotValue.YES,
      );
    }
  };

  const accountsSchedules = useAccountsSchedules();
  const handleSubmitEnrollAccount = async (
    data: EnrollManyToManyFormValues,
  ) => {
    try {
      await accountsSchedules.add({
        courses: data?.courses?.map((course) => course.id) ?? [],
        accounts: data?.accounts?.map((account) => account.id) ?? [],
        date: data.date,
        autoEnroll: data.autoEnroll,
        type: data.type,
      });

      toast?.success(t('toast.success'), t('courses.account.enroll.success'));
      setEnrollModal(false);
      checkAndTrackHubspotEvent();

      if (onTriggerRefetch) {
        onTriggerRefetch();
      }
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const branchesSchedules = useBranchesSchedules();
  const handleSubmitEnrollBranches = async (
    data: EnrollManyToManyFormValues,
  ) => {
    try {
      await branchesSchedules.add({
        courses: data?.courses?.map((course) => course.id) ?? [],
        branches: data?.branches?.map((branch) => branch.id) ?? [],
        date: data.date,
        autoEnroll: data.autoEnroll,
        type: data.type,
      });

      toast?.success(t('toast.success'), t('courses.branches.enroll.success'));
      setEnrollModal(false);
      checkAndTrackHubspotEvent();

      if (onTriggerRefetch) {
        onTriggerRefetch();
      }
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const groupsSchedule = useGroupsSchedules();
  const handleSubmitEnrollGroups = async (data: EnrollManyToManyFormValues) => {
    try {
      await groupsSchedule.add({
        courses: data?.courses?.map((course) => course.id) ?? [],
        groups: data?.groups?.map((group) => group.id) ?? [],
        date: data.date,
        autoEnroll: data.autoEnroll,
        type: data.type,
      });

      toast?.success(t('toast.success'), t('courses.groups.enroll.success'));
      setEnrollModal(false);

      checkAndTrackHubspotEvent();

      if (onTriggerRefetch) {
        onTriggerRefetch();
      }
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const usersSchedules = useUsersSchedules();
  const handleSubmitEnrollUsers = async (data: EnrollManyToManyFormValues) => {
    try {
      await usersSchedules.add({
        courses: data?.courses?.map((course) => course.id) ?? [],
        users: data?.users?.map((user) => user.id) ?? [],
        date: data.date,
        autoEnroll: data.autoEnroll,
        type: data.type,
      });

      toast?.success(
        t('toast.success'),
        !isMaterial && moment(data.date).isSame(moment(), 'day')
          ? t('courses.users.enroll.success.notify')
          : t('courses.users.enroll.success'),
      );
      setEnrollModal(false);
      checkAndTrackHubspotEvent();

      if (onTriggerRefetch) {
        onTriggerRefetch();
      }
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const handleEnrollModalSubmit = async (data: EnrollManyToManyFormValues) => {
    switch (data.enrollTo) {
      case CourseEntityScheduleEnum.ACCOUNT: {
        await handleSubmitEnrollAccount(data);
        break;
      }
      case CourseEntityScheduleEnum.BRANCH: {
        await handleSubmitEnrollBranches(data);
        break;
      }
      case CourseEntityScheduleEnum.GROUP: {
        await handleSubmitEnrollGroups(data);
        break;
      }
      case CourseEntityScheduleEnum.USER: {
        await handleSubmitEnrollUsers(data);
        break;
      }
      default:
        break;
    }
  };

  return (
    <>
      <AppBreadCrumb
        model={getBreadcrumbs(
          account as Account,
          t,
          canUseFeature,
          isMaterial,
          isTrainingPlanner,
        )}
      />

      <StyledFlexContainer justify="space-between">
        <FlexContainer gap={12} justify="flex-start">
          <h2 className="m-0">
            {isTrainingPlanner
              ? t('courses.trainingPlanner')
              : canUseFeature(Subjects.TRAINING_PLANNER)
              ? t('courses.eventLog')
              : t('schedule')}
          </h2>
        </FlexContainer>

        <FlexContainer gap={12} justify="flex-end">
          {canUseFeature(Subjects.TRAINING_PLANNER) &&
          can(Actions.CREATE, Subjects.COURSE_PLANNER) ? (
            isTrainingPlanner && (
              <AppButton
                label={t('button.create')}
                onClick={() =>
                  setIsCreatePromptVisible && setIsCreatePromptVisible(true)
                }
              />
            )
          ) : (
            <AppButton
              label={t('generic.enroll')}
              iconPos="right"
              aria-controls="overlay_menu"
              onClick={() => setEnrollModal(true)}
            />
          )}
        </FlexContainer>

        <ManyToManyEnrollModal
          account={account as Account}
          visible={showEnrollModal}
          onSubmit={handleEnrollModalSubmit}
          onHide={() => setEnrollModal(false)}
          isMaterial={isMaterial}
        />
      </StyledFlexContainer>
    </>
  );
};
