import { client } from '@/client';
import { Course, CourseStatusAttemptEnum } from '@/client/courses';
import {
  HubspotEventType,
  HubspotProperty,
  HubspotValue,
  HubspotValueType,
} from '@/client/hubspot/types';
import { User } from '@/client/users';
import moment from 'moment';

export const hubspotTrack = (
  event: HubspotProperty,
  value: HubspotValue | string,
  type = HubspotEventType.GENERIC,
  valueType?: HubspotValueType,
) => {
  const track = async () => {
    await client.hubspot.trackEvent({
      type,
      event,
      value,
      valueType,
    });
  };

  track();
};

export const hubspotTrackCourseCompletion = (
  course: Course,
  status: CourseStatusAttemptEnum,
) => {
  const hubspotProperty = course?.hubspotProperties?.hubspotProperty;

  if (status === CourseStatusAttemptEnum.COMPLETED && !!hubspotProperty) {
    hubspotTrack(
      hubspotProperty as HubspotProperty,
      HubspotValue.YES,
      HubspotEventType.COURSE,
    );
  }
};

export const hubspotTrackMaterialDownloaded = (course: Course) => {
  const hubspotProperty = course?.hubspotProperties?.hubspotProperty;
  const hubspotDateProperty = course.hubspotProperties?.hubspotDateProperty;

  if (hubspotProperty) {
    hubspotTrack(
      hubspotProperty as HubspotProperty,
      HubspotValue.YES,
      HubspotEventType.COURSE,
      HubspotValueType.BOOLEAN,
    );
  }

  if (hubspotDateProperty) {
    hubspotTrack(
      hubspotDateProperty as HubspotProperty,
      hubspotTimestamp(),
      HubspotEventType.COURSE,
      HubspotValueType.DATE,
    );
  }
};

export const identifyHubspotContact = (user: User) => {
  const _hsq =
    typeof window !== 'undefined' && (window as any)._hsq
      ? (window as any)._hsq
      : [];

  _hsq.push([
    'identify',
    {
      [HubspotProperty.ID]: user.id,
      [HubspotProperty.EMAIL]: user.email,
      [HubspotProperty.FIRSTNAME]: user.firstName,
      [HubspotProperty.LASTNAME]: user.lastName,
      [HubspotProperty.COMPANY]: user?.account?.name,
      [HubspotProperty.USER_LOGIN]: HubspotValue.YES,
    },
  ]);
  _hsq.push(['trackPageView']);
};

export const revokeHubspotCookies = () => {
  const _hsq =
    typeof window !== 'undefined' && (window as any)._hsq
      ? (window as any)._hsq
      : [];

  _hsq.push(['revokeCookieConsent']);
};

export const hubspotTimestamp = () =>
  moment().startOf('day').utc(true).toISOString();
