import { FiltersType, ListingRequest } from '@/api/types';
import { client } from '@/client';
import { Account } from '@/client/accounts';
import { AppContext } from '@/common/context/AppContext';
import React, { HTMLAttributes, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LazyLoadingMultiselect } from '../generic';

type AccountSelectInputProps = {
  onChange: (accounts: Account[]) => void;
  selectedOptions?: Account[];
  additionalFilters?: FiltersType;
  courseId?: string;
  isUnenroll?: boolean;
  getOnlyUnavailableAccounts?: boolean;
  forceRefetch?: boolean;
  isDisabled?: boolean;
} & HTMLAttributes<HTMLInputElement>;

export const AccountsMultiselectInput: React.FC<AccountSelectInputProps> = ({
  selectedOptions,
  onChange,
  additionalFilters,
  courseId,
  isUnenroll,
  getOnlyUnavailableAccounts,
  forceRefetch,
  isDisabled,
}) => {
  const { t } = useTranslation();
  const { state, clearTrigger } = useContext(AppContext);
  const [refetchKey, setRefetchKey] = useState<number>(0);
  const [selectedAccounts, setSelectedAccounts] = useState<Account[]>(
    selectedOptions || [],
  );

  const fetchUnavailableAccounts = isDisabled
    ? undefined
    : async (params: ListingRequest) =>
        await client.courses.getUnavailableAccounts(params, courseId);

  const fetchAccounts = isDisabled
    ? undefined
    : async (params: ListingRequest) =>
        isUnenroll
          ? await client.courses.getCourseAccounts(params, courseId)
          : await client.accounts.getAccounts(params);

  useEffect(() => {
    if (!forceRefetch && !state.isAccountsLengthChanged) {
      return;
    }

    setRefetchKey(refetchKey + 1);

    if (state.isAccountsLengthChanged && clearTrigger) {
      clearTrigger();
    }
  }, [forceRefetch, state.isAccountsLengthChanged]);

  useEffect(() => {
    setSelectedAccounts(selectedOptions || []);
    onChange(selectedOptions as Account[]);
  }, [selectedOptions]);

  return (
    <LazyLoadingMultiselect
      fetchService={
        getOnlyUnavailableAccounts ? fetchUnavailableAccounts : fetchAccounts
      }
      selectedValues={selectedAccounts || []}
      handleOnChange={onChange}
      filter
      filterPlaceholder={t('accounts.search')}
      optionLabel="name"
      placeholder={t('accounts.select')}
      refetchKey={refetchKey}
      additionalFilters={additionalFilters}
      isDisabled={isDisabled}
      className="w-full"
      maxSelectedItems={5}
    />
  );
};
