import { App } from '@/app';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import initializeHeap from './initHeap';
import initSentry from './initSentry';
import reportWebVitals from './reportWebVitals';

initSentry();
initializeHeap();

// eslint-disable-next-line
(window as any).renderApp = (id: string) => {
  const root = ReactDOM.createRoot(document.getElementById(id) as HTMLElement);

  root.render(<App />);
};

if (document.getElementById('root')) {
  // eslint-disable-next-line
  (window as any).renderApp('root');
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
