export const processUrl = (url: string, params: Record<string, any>) => {
  if (Object.values(params).length === 0) {
    return url;
  }

  return Object.entries(params).reduce(
    (a, [key, value]) => a.replace(`:${key}`, value),
    url,
  );
};

export const removeParamsFromPath = (
  path: string | null,
  paramsToRemove: string[],
) => {
  if (!path) {
    return '';
  }

  const urlObj = new URL(path, window.location.origin);
  const searchParams = urlObj.searchParams;

  // Remove specific parameters
  paramsToRemove.forEach((param) => searchParams.delete(param));

  // Construct the path again
  return `${urlObj.pathname}${
    searchParams.toString() ? `?${searchParams.toString()}` : ''
  }`;
};
