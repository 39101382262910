import {
  EMAIL_REGEX,
  KEYCLOAK_USERNAME_REGEX,
  PASSWORD_REGEX,
} from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { boolean, date, object, string } from 'yup';

export const createUserSchema = (
  t: TranslationFunctionType,
  isBranchAdmin: boolean,
) =>
  object({
    firstName: string().required(t('error.requiredField')),
    lastName: string().required(t('error.requiredField')),
    username: string()
      .required(t('error.requiredField'))
      .min(3, t('error.minLength', { length: '3' }))
      .matches(KEYCLOAK_USERNAME_REGEX, t('error.onlyLowercase')),
    email: string()
      .required(t('error.requiredField'))
      .matches(EMAIL_REGEX, t('error.invalidEmail')),
    password: string(),
    role: object().required(t('error.requiredField')),
    language: string().required(t('error.requiredField')),
    branch: isBranchAdmin
      ? object().required(t('error.requiredField'))
      : object(),
    freeTrialEndsAt: date().notRequired().nullable(),
    active: boolean().default(true),
    eFrontSync: boolean().default(false),
    isManual: boolean().default(false),
    expiresOn: date().when(['isTemporary'], ([isTemporary], schema) =>
      isTemporary
        ? schema.required(t('error.requiredField'))
        : schema.notRequired().nullable(),
    ),
  });

export const updateUserSchema = (
  t: TranslationFunctionType,
  isBranchAdmin: boolean,
) =>
  object({
    firstName: string().required(t('error.requiredField')),
    lastName: string().required(t('error.requiredField')),
    username: string()
      .required(t('error.requiredField'))
      .min(3, t('error.minLength', { length: '3' }))
      .matches(KEYCLOAK_USERNAME_REGEX, t('error.onlyLowercase')),
    email: string()
      .required(t('error.requiredField'))
      .matches(EMAIL_REGEX, t('error.invalidEmail')),
    password: string()
      .matches(PASSWORD_REGEX, t('error.password.complexity'))
      .min(8, t('error.password.length', { length: 8 })),
    role: object().required(t('error.requiredField')),
    language: string().required(t('error.requiredField')),
    branch: isBranchAdmin
      ? object().required(t('error.requiredField'))
      : object(),
    freeTrialEndsAt: date().when(
      ['freeTrialCheck'],
      ([freeTrialCheck], schema) =>
        freeTrialCheck
          ? schema.required(t('user.error.select.freeTrial.endDate'))
          : schema.notRequired().nullable(),
    ),
    active: boolean(),
    eFrontSync: boolean(),
    expiresOn: date().when(['isTemporary'], ([isTemporary], schema) =>
      isTemporary
        ? schema.required(t('error.requiredField'))
        : schema.notRequired().nullable(),
    ),
  });

export const updateUserPropertiesSchema = (t: TranslationFunctionType) =>
  object({
    companyName: string().nullable(),
    department: string().nullable(),
    manager: string().nullable(),
    country: string().nullable(),
    jobTitle: string().nullable(),
    mobilePhone: string().nullable(),
    officeLocation: string().nullable(),
  });
