import { LanguagesEnum } from '@/api/enums';
import { Subjects } from '@/client/users';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import {
  PHISHING_BOOK_DEMO_DK,
  PHISHING_BOOK_DEMO_EN,
} from '@/common/constants/external-urls';
import { LanguageContext } from '@/common/context/LanguageContext';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import TryForFreeImage from '@/images/placeholders/try-phishing.svg';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StyledContainer = styled(FlexContainer)`
  height: auto;
  width: 100%;
  background-color: var(--beige-main);
  border-radius: var(--medium-border-radius);
  padding: var(--big-padding);

  p {
    text-align: center;
    line-height: var(--medium-line-height);
    font-weight: 500;
  }

  img {
    height: auto;
    width: 100%;
    margin-bottom: var(--default-padding);
  }

  .p-button {
    padding-inline: var(--big-padding);
    font-size: var(--large-font-size);
  }
`;

export const TryForFreePhishing: React.FC<{ isLoading: boolean }> = ({
  isLoading,
}) => {
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);
  const { canUseFeature } = useFeatureFlag();
  const navigate = useNavigate();

  return (
    <StyledContainer direction="column" flex="1">
      {isLoading ? (
        <ProgressSpinner />
      ) : (
        <>
          <img
            style={{ maxHeight: '240px' }}
            src={TryForFreeImage}
            alt={t('campaign.tryForFree')}
          />
          <p>{t('campaign.tryForFree.text')}</p>
          <FlexContainer gap={8}>
            {canUseFeature(Subjects.CAMPAIGNS_CATALOGUE) && (
              <AppButton
                label={t('campaigns.catalogue.title')}
                severity="secondary"
                type="outlined"
                onClick={() =>
                  navigate(
                    RedirectPaths[RedirectPathsEnum.CAMPAIGNS_CATALOGUE](),
                  )
                }
              />
            )}
            <a
              href={
                language === LanguagesEnum.DK
                  ? PHISHING_BOOK_DEMO_DK
                  : PHISHING_BOOK_DEMO_EN
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <AppButton label={t('campaign.tryForFree')} />
            </a>
          </FlexContainer>
        </>
      )}
    </StyledContainer>
  );
};
