import { LanguagesEnum } from '@/api/enums';
import { FiltersType, ListingRequest, ListingResponse } from '@/api/types';
import {
  Campaign,
  CampaignMeta,
  CampaignTemplate,
  CampaignUserResult,
  SenderProfileType,
} from '@/client/campaigns';
import { SendingProfile } from '@/client/sender-profiles';
import { User } from '@/client/users';
import {
  ExcludeFormValue,
  TargetEntities,
  TargetEntitiesRequest,
  TargetFormValue,
} from '@/common/types';
import { TargetGroupStateType } from '@/utils/helpers';

export type ListCampaignsRequest = ListingRequest;
export type ListCampaignUserResultsRequest = ListingRequest;
export type ListCampaignsResponse = ListingResponse<Campaign>;
export type ListCampaignTemplatesResponse = ListingResponse<CampaignTemplate>;
export type ListCampaignUserResultsResponse =
  ListingResponse<CampaignUserResult>;

export type CampaignFormValues = {
  emailTemplate?: string;
  landingPage?: string;
  targetGroup?: TargetGroupStateType;
  targets?: TargetFormValue[];
  excludes?: ExcludeFormValue[];
  sendingProfile?: SendingProfile;
  periodType?: CampaignEmailingType;
  sendByDate?: Date;
  launchDate?: Date;
  name?: string;
  language?: LanguagesEnum;
  difficulty?: number;
  senderProfileType?: SenderProfileType;
  clickedLinksPercentage?: number;
  submittedDataPercentage?: number;
  description?: string;
  encodeEmailTemplateImages?: boolean;
  useDynamicTarget?: boolean;
  meta?: CampaignMeta;
  phishingType?: CampaignPhishingTypeEnum;
  phishingSign?: CampaignPhishingSignEnum;
  benchmarkSummary?: {
    clickedLinksPercentage: number;
    submittedDataPercentage: number;
    emailsReportedPercentage: number;
  };
  isAutoPhishing?: boolean;
  isPublished?: boolean;
};
export const CampaignEmailingEnum = {
  DRIP: 'drip',
  BLAST: 'blast',
};

export const CampaignActionsEnum = {
  LAUNCH: 'launch',
  COMPLETE: 'complete',
};

export type CampaignEmailingType =
  | typeof CampaignEmailingEnum.DRIP
  | typeof CampaignEmailingEnum.BLAST;

export type CampaignActionsType =
  | typeof CampaignActionsEnum.LAUNCH
  | typeof CampaignActionsEnum.COMPLETE;

export type UpdateCampaignAction = {
  campaignId: string;
  action: CampaignActionsType;
};

export type SaveCampaignRequest = {
  encodeEmailTemplateImages?: boolean;
  name?: string;
  launchDate?: Date;
  sendByDate?: Date | null;
  emailTemplate?: string;
  landingPage?: string;
  sendingProfile?: string;
  url?: string;
  account?: string;
  users?: string[] | null;
  branches?: string[];
  groups?: string[];
  targets?: TargetEntitiesRequest;
  excludes?: TargetEntitiesRequest;
  useDynamicTarget?: boolean;
  language?: LanguagesEnum;
  difficulty?: number;
  senderProfileType?: SenderProfileType;
  clickedLinksPercentage?: number;
  submittedDataPercentage?: number;
  description?: string;
  phishingType?: CampaignPhishingTypeEnum;
  phishingSign?: CampaignPhishingSignEnum;
  isAutoPhishing?: boolean;
  isPublished?: boolean;
};

export type CloneCampaignRequest = {
  campaignId: string;
  account: string;
};

export type SendTestEmailFormValues = {
  emailType: 'admin' | 'custom';
  email: string;
  firstName?: string;
  lastName?: string;
  position?: string;
  admin?: User;
  language?: LanguagesEnum;
};

export type SendTestEmail = {
  campaignId: string;
  email: string;
  firstName?: string;
  lastName?: string;
  position?: string;
  emailTemplateId: string;
  sendingProfileId: string;
  landingPageId: string;
  encodeEmailTemplateImages?: boolean;
  language?: LanguagesEnum;
};

export type UpdateCampaign = {
  encodeEmailTemplateImages?: boolean;
  name?: string;
  launchDate?: Date;
  sendByDate?: Date | null;
  emailTemplate?: string;
  landingPage?: string;
  sendingProfile?: string;
  url?: string;
  account?: string;
  users?: string[] | null;
  branches?: string[];
  groups?: string[];
  targets?: TargetEntities;
  excludes?: TargetEntities;
  useDynamicTarget?: boolean;
  language?: LanguagesEnum;
  difficulty?: number;
  senderProfileType?: SenderProfileType;
  clickedLinksPercentage?: number;
  submittedDataPercentage?: number;
  description?: string;
  phishingType?: CampaignPhishingTypeEnum;
  phishingSign?: CampaignPhishingSignEnum;
  isWhitelisted?: boolean;
};

export type UpdateCampaignRequest = {
  updates: UpdateCampaign;
  campaignId: string;
};

export type MigrationCampaignRequest = {
  accountId: string;
  campaignIds: string[];
};

export type ExportCampaignsReportRequest = {
  filters: FiltersType | undefined;
  exportKey: string;
  columns?: string[];
};

export enum CampaignPhishingSignEnum {
  REWARDS = 'rewards',
  MEDIA = 'media',
  DO_THE_RIGHT_THING = 'do-the-right-thing',
  FEAR = 'fear',
  CURIOSITY = 'curiosity',
  CONTEXT = 'context',
  TRUST_OR_AUTHORITY = 'trust-or-authority',
  TIME_SENSITIVITY = 'time-sensitivity',
}
export enum CampaignPhishingTypeEnum {
  ACCESS_TO_SYSTEM_MS_TEAMS = 'access-to-system-ms-teams',
  AI_WORKSHOP_SECURE_LOG = 'ai-workshop-secure-log',
  CHRISTMAS_CALENDER_VENUE_LIGHT = 'christmas-calender-venue-light',
  CHRISTMAS_GIFT_LOGIN_CATALOGUE_MATERIAL_THINGS = 'christmas-gift-login-catalogue-material-things',
  CHRISTMAS_GIFT_LOGIN_CATALOGUE_MATERIAL_THINGS_CUSTOM = 'christmas-gift-login-catalogue-material-things-custom',
  CHRISTMAS_GIFT_DOWNLOAD_CATALOGUE_MATERIAL_THINGS = 'christmas-gift-download-catalogue-material-things',
  CHRISTMAS_GIFT_EXPERIENCES_AND_STAYS = 'christmas-gift-experiences-and-stays',
  COMPANY_PARTY_CUSTOM = 'company-party-custom',
  CONNECTION_REQUEST_LINKEDIN = 'connection-request-linkedin',
  EMAIL_LOGIN_ATTEMPT_INDONESIA = 'email-login-attempt-indonesia',
  EMPLOYEE_BENEFITS_PERSONALEGODER_OR_PERSONALEGOODS = 'employee-benefits-personalegoder-or-personalegoods',
  EMPLOYEE_SURVEY_OPEN_OPINION = 'employee-survey-open-opinion',
  FILE_SHARING_GALACTIC_CLOUD = 'file-sharing-galactic-cloud',
  FILE_SHARING_MS_360_SYSTEMS = 'file-sharing-ms-360-systems',
  FIRE_EVACUATION_PLAN_FIRE_PREPARE = 'fire-evacuation-plan-fire-prepare',
  COMPETITION_VENUE_LIGHT = 'competition-venue-light',
  DOCUMENT_TO_SIGN_PEMEO = 'document-to-sign-pemeo',
  MFA_UPDATE_SYSTEM_SUPPORT = 'mfa-update-system-support',
  NEW_ESG_INITIATIVE_ARCADIA_DAWN_PERFORMANCE_PARTNERS = 'new-esg-initiative-arcadia-dawn-performance-partners',
  NEW_IT_SYSTEM_CUSTOM = 'new-it-system-custom',
  NEW_IT_SYSTEM_WORKFLOW = 'new-it-system-workflow',
  NEW_PARKING_CONDITIONS_HR_AFDDELINGINGEN_HR_DEPARTMENT = 'new-parking-conditions-hr-afddelingingen-hr-department',
  NEW_PASSWORD_POLICY_CUSTOM = 'new-password-policy-custom',
  NEW_PASSWORD_POLICY_IT_HELPDESK = 'new-password-policy-it-helpdesk',
  SUPPORT_TICKET_SERVICE_DESK = 'support-ticket-service-desk',
  TIME_REGISTRATION_TIME_PLANNER = 'time-registration-time-planner',
  UPDATE_YOUR_WEAK_PASSWORDS_365AUTH = 'update-your-weak-passwords-365auth',
  VACATION_REQUEST_DENIED_CUSTOM = 'vacation-request-denied-custom',
  YOUR_FILE_HAS_EXPIRED_MS_TEAMS = 'your-file-has-expired-ms-teams',
  YOU_WERE_MENTIONED_MS_365 = 'you-were-mentioned-ms-365',
  EASTER = 'easter',
  EASTER_EGG_DIT_PAASKE_AEG = 'easter-egg-dit-paaske-aeg',
  EASTER_EVENT_HR_DEPARTMENT = 'easter-event-hr-department',
  EASTER_EGG_GIFTS_AND_TREATS = 'easter-egg-gifts-and-treats',
  WHISTLEBLOWER_SECURE_LOG = 'whistleblower-secure-log',
  ALARM_SYSTEM_SYSTEM_SUPPORT = 'alarm-system-system-support',
  UPDATE_EMPLOYEE_INFORMATION_BORGER_SERVICE = 'update-employee-information-borger-service',
}
