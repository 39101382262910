import { FeatureFlag } from '@/client/feature-flags/types';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { Dialog, DialogProps } from 'primereact/dialog';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FeatureFlagOverviewDatatable } from '../datatables/FeatureFlagOverviewDatatable';

const StyledDialog = styled(Dialog)`
  width: 100%;
  min-height: 100dvh;

  .p-dialog-content {
    overflow: auto;
    display: flex;
    flex-direction: column;
  }
`;

type FeatureFlagOverviewModalProps = {
  currentFeature?: FeatureFlag | null;
  isSubmitting?: boolean;
  setIsSubmitting?: (isSubmitting: boolean) => void;
} & DialogProps;

export const FeatureFlagOverviewModal: React.FC<
  FeatureFlagOverviewModalProps
> = ({ currentFeature, setIsSubmitting, isSubmitting, onHide, visible }) => {
  const { t } = useTranslation();

  return (
    <StyledDialog
      blockScroll
      visible={visible}
      header={
        <h1>
          {t('feature.activated.accounts.for.featureName', {
            featureName: t(`features.${currentFeature?.key}`),
          })}
        </h1>
      }
      onHide={onHide}
      draggable={false}
      closable={!isSubmitting}
      data-testid="feature-flag-overview-modal"
    >
      <FlexContainer
        gap={24}
        justify="flex-start"
        direction="column"
        className="mb-4"
      >
        <FeatureFlagOverviewDatatable
          currentFeature={currentFeature}
          setIsSubmitting={setIsSubmitting}
          isSubmitting={isSubmitting}
        />
      </FlexContainer>

      <FlexContainer justify="flex-end" className="mt-auto">
        <AppButton
          label={t('button.close')}
          severity="secondary"
          type="outlined"
          onClick={onHide}
          className="mr-3 mt-5"
          isDisabled={isSubmitting}
          data-testid="featire-flag-overview-close"
        />
      </FlexContainer>
    </StyledDialog>
  );
};
