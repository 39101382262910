//---------- NOTE: UNCOMMENT A VARIABLE TO USE IT ----------//

//---------- Font related ----------//
export const platformFontFamily = (docStyle: CSSStyleDeclaration) =>
  docStyle.getPropertyValue('--font-family');
// export let iconFontFamily = (docStyle: CSSStyleDeclaration) =>  docStyle.getPropertyValue('--icon-font-family')
// export let headingXLargeFontSize = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue(
//   '--heading-xlarge-font-size',
// );
export const headingLargeFontSize = (docStyle: CSSStyleDeclaration) =>
  docStyle.getPropertyValue('--heading-large-font-size');
// export let headingLargeLineHeight = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue(
//   '--heading-large-line-height',
// );
export const headingMediumFontSize = (docStyle: CSSStyleDeclaration) =>
  docStyle.getPropertyValue('--heading-medium-font-size');
// export const headingMediumLineHeight = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue(
//   '--heading-medium-line-height',
// );
// export const headingSmallFontSize = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue(
//   '--heading-small-font-size',
// );
// export const headingSmallLineHeight = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue(
//   '--heading-small-line-height',
// );
// export const bodyFontSize = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--body-font-size');
// export const bodyLineHeight = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--body-line-height');
// export const mediumFontSize = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--medium-font-size');
// export const mediumLineHeight = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue(
//   '--medium-line-height',
// );
export const smallFontSize = (docStyle: CSSStyleDeclaration) =>
  docStyle.getPropertyValue('--small-font-size');
// export const smallLineHeight = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--small-line-height');
// export const xSmallFontSize = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--xsmall-font-size');
// export const xSmallLineHeight = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--xsmall-line-height');
// export const xxSmallFontSize = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--xxsmall-font-size');

//---------- Colors ----------//
export const colorBlackMain = (docStyle: CSSStyleDeclaration) =>
  docStyle.getPropertyValue('--black-main');
// export const colorWhiteMain = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--white-main');
// export const colorBeigeMain = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--beige-main');
// export const colorGrayPale = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--gray-pale');
export const colorGrayDark = (docStyle: CSSStyleDeclaration) =>
  docStyle.getPropertyValue('--gray-dark');
export const colorGrayOpacity = (docStyle: CSSStyleDeclaration) =>
  docStyle.getPropertyValue('--gray-opacity');
// export const colorGrayDarker = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--gray-darker');
// export const colorPurpleLight = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--purple-light');
// export const colorPurpleMiddle = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--purple-middle');
export const colorPurpleDark = (docStyle: CSSStyleDeclaration) =>
  docStyle.getPropertyValue('--purple-dark');
// export const colorPurpleHighlight = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--purple-highlight');
// export const colorRedPale = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--red-pale');
export const colorRedLight = (docStyle: CSSStyleDeclaration) =>
  docStyle.getPropertyValue('--red-light');
export const colorRedMain = (docStyle: CSSStyleDeclaration) =>
  docStyle.getPropertyValue('--red-main');
// export const colorRedMiddle = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--red-middle');
// export const colorRedDark = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--red-dark');
// export const colorRedOpacity = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--red-opacity');
// export const colorGreenPale = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--green-pale');
// export const colorGreenMain = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--green-main');
// export const colorGreenDark = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--green-dark');
// export const colorOrangePale = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--orange-pale');
// export const colorOrangeMain = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--orange-main');
// export const avatarBackground = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--avatar-background');
// export const avatarText = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--avatar-text');

//---------- Box Shadows ----------//
// export const boxShadowRed = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--box-shadow-red');
// export const boxShadowRedInset = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--box-shadow-red-inset');
// export const boxShadowRedNoBlur = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--box-shadow-red-no-blur');
// export const boxShadowBlack = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--box-shadow-black');

//---------- Paddings ----------//
export const bigPadding = (docStyle: CSSStyleDeclaration) =>
  docStyle.getPropertyValue('--big-padding');
export const defaultPadding = (docStyle: CSSStyleDeclaration) =>
  docStyle.getPropertyValue('--default-padding');
export const mediumPadding = (docStyle: CSSStyleDeclaration) =>
  docStyle.getPropertyValue('--medium-padding');
// export const smallPadding = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--small-padding');
// export const xSmallPadding = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--xsmall-padding');
// export const xxSmallPadding = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--xxsmall-padding');

//---------- Borders ----------//
// export const bigBorderRadius = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--big-border-radius');
// export const defaultBorderRadius = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--default-border-radius');
// export const mediumBorderRadius = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--medium-border-radius');
// export const smallBorderRadius = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--small-border-radius');
// export const xSmallBorderRadius = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--xsmall-border-radius');
// export const xxSmallBorderRadius = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--xxsmall-border-radius');
export const xxxSmallBorderRadius = (docStyle: CSSStyleDeclaration) =>
  docStyle.getPropertyValue('--xxxsmall-border-radius');

//---------- Components ----------//
export const topNavHeight = (docStyle: CSSStyleDeclaration) =>
  docStyle.getPropertyValue('--top-nav-height');

//---------- Buttons ----------//
// export const defaultInputHeight = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--default-input-height');
// export const smallButtonHeight = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--small-button-height');
// export const mediumButtonHeight = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--medium-button-height');
// export const defaultTextareaHeight = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--default-textarea-height');

//---------- Widths ----------//
// export const dWidth = (docStyle: CSSStyleDeclaration) => docStyle.getPropertyValue('--max-dashboard-width');
