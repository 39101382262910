import { GroupCondition } from '@/api/enums';
import {
  contains,
  equal,
  greater,
  group,
  handleAxiosError,
  lower,
  notEqual,
} from '@/api/helpers';
import { client } from '@/client';
import { Account } from '@/client/accounts';
import { Course, CourseEntityEnrollEnum, MaterialType } from '@/client/courses';
import {
  CoursePlanner,
  CourseSchedulePeriodUnit,
  PlannerCRUDCourse,
  PlannerTemplateType,
  PlannerType,
} from '@/client/planner/types';
import { Actions, Subjects } from '@/client/users';
import { LoadingStatuses } from '@/common/constants';
import { DialogContext } from '@/common/context';
import { ExcludeFormValue, TargetFormValue } from '@/common/types';
import { useCoursesPartialRequest } from '@/hooks/query';
import {
  useCreatePlanner,
  useDeletePlanner,
  useUpdatePlanner,
} from '@/hooks/query/planner.hooks';
import { useAppSelector } from '@/hooks/store';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { AppButton } from '@/ui/buttons';
import { EditableText } from '@/ui/editable-text';
import { FlexContainer } from '@/ui/styled-ui';
import { TargetEntitiesSelector } from '@/ui/target-selector';
import {
  getTargetEntityIdsByType,
  processIncludeExcludeTargetEntities,
} from '@/utils/targets';
import { AxiosError } from 'axios';
import { t } from 'i18next';
import moment from 'moment';
import { Dialog, DialogProps } from 'primereact/dialog';
import { InputSwitch } from 'primereact/inputswitch';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { PlannerCRUDTimeline } from '../PlannerCRUDTimeline';

const StyledDialog = styled(Dialog)`
  width: 100%;
  max-width: 1400px;
  max-height: 650px;
  height: 100%;

  .p-dialog-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
  }

  .p-dialog-header .p-dialog-header-icons {
    margin: auto;
    margin-right: 0;
  }
`;

const StyledGrayFlexContainer = styled(FlexContainer)`
  background-color: var(--beige-main);
  padding: var(--big-padding);
  padding-bottom: 0;
  margin-top: var(--default-padding);
  height: 100%;
`;

const StyledBottomDiv = styled.div`
  width: 100%;
  margin-top: var(--default-padding);
  padding: var(--default-padding);
  padding-top: 0;
`;

const StyledDeleteInfoSpan = styled.span`
  color: var(--gray-darker);
`;

const StyledHeaderContainer = styled(FlexContainer)`
  gap: var(--default-padding);
  align-items: center;
  flex-wrap: wrap;

  h1 {
    max-width: 100%;
  }
`;

const StyledHeaderDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  p {
    font-size: 14px;
    font-weight: 500;
  }
`;

type CreateUpdatePlannerModalProps = {
  plan?: CoursePlanner;
  refetch?: () => void;
  plannerType: PlannerType | undefined;
  plannerTemplateType?: PlannerTemplateType;
} & DialogProps;

export const CreateUpdatePlannerModal: React.FC<
  CreateUpdatePlannerModalProps
> = ({ plan, visible, onHide, refetch, plannerType, plannerTemplateType }) => {
  const { can } = usePermission();
  const { setDialogData } = useContext(DialogContext);
  const toast = useToast();
  const account = useAppSelector(selectCurrentAccount);
  const [planName, setPlanName] = useState<string>('');
  const [autoEnroll, setAutoEnroll] = useState(false);
  const [autoEnrollTemp, setAutoEnrollTemp] = useState(false);
  const [isAutoEnrollConfirmVisibile, setIsAutoEnrollConfirmVisibile] =
    useState(false);

  const [includedEntities, setIncludedEntities] = useState<TargetFormValue[]>(
    [],
  );
  const [excludedEntities, setExcludedEntities] = useState<ExcludeFormValue[]>(
    [],
  );

  const [selectedCourses, setSelectedCourses] = useState<PlannerCRUDCourse[]>(
    [],
  );

  const today = useMemo(() => new Date(), []);

  const { courses: upcomingCourses, isLoading: areUpcomingCoursesLoading } =
    useCoursesPartialRequest({
      filters: [
        equal('deletedAt', null),
        equal('retired', false),
        equal('type', MaterialType.COURSE),
        notEqual('releaseDate', null),
        greater('releaseDate', today),
      ],
      enabled: visible && plannerType === PlannerType.TRAINING_PLAN,
    });

  const {
    courses: recommendedCourses,
    isLoading: areRecommendedCoursesLoading,
  } = useCoursesPartialRequest({
    filters: [
      equal('availableToAccounts', account?.id),
      equal('retired', false),
      group(GroupCondition.OR, [
        equal('type', MaterialType.COURSE),
        equal('type', MaterialType.CUSTOM_MATERIAL),
      ]),
      contains('label', [plannerType]),
      ...(plannerType === PlannerType.ONBOARDING_PLAN
        ? [
            group(GroupCondition.AND, [
              group(GroupCondition.OR, [
                lower('releaseDate', today, true),
                equal('releaseDate', null),
              ]),
              contains('label', [plannerType]),
            ]),
          ]
        : []),
    ],
    sort: [['priority', 'asc'].join(',')],
    enabled: plannerTemplateType === PlannerTemplateType.RECOMMENDED && visible,
  });

  useEffect(() => {
    setIncludedEntities([]);
    setExcludedEntities([]);
    setSelectedCourses([]);

    if (plan?.id) {
      return;
    }

    const handleGeneratePlannerName = async () => {
      const response = await client.planner.getPlanners(
        {
          skip: 0,
          take: 0,
          filters: [equal('type', plannerType)],
        },
        account?.id,
      );

      if (response?.count !== undefined) {
        setPlanName(
          plan?.name ?? plannerType === PlannerType.TRAINING_PLAN
            ? `${t('account.trainingPlan')} ${t('generic.for')} ${
                account?.name
              } #${response?.count + 1}`
            : `${t('planner.type.onboardingPlan')} ${t('generic.for')} ${
                account?.name
              } #${response?.count + 1}`,
        );
      }
    };

    handleGeneratePlannerName();
  }, [visible]);

  useEffect(() => {
    if (plan) {
      setPlanName(plan.name);
      setAutoEnroll(!!plan.autoEnroll);

      const { includedEntities, excludedEntities } =
        processIncludeExcludeTargetEntities({
          target: plan.targets,
          excludes: plan.excludes,
          account: account as Account,
        });

      setIncludedEntities(includedEntities);
      setExcludedEntities(excludedEntities);

      const planCourses =
        plannerType === PlannerType.ONBOARDING_PLAN
          ? plan.dynamicCourseSchedules
          : plan.staticCourseSchedules;

      setSelectedCourses(
        planCourses.map((item) => ({
          ...item,
          ...item.course,
        })),
      );
    }
  }, [plan]);

  useEffect(() => {
    if (plan) {
      return;
    }
    // Set auto enroll to be enabled for new training plans
    setAutoEnroll(plannerType === PlannerType.TRAINING_PLAN);

    // Set recommended courses or/and upcoming on new plan creation
    if (
      selectedCourses.length ||
      areUpcomingCoursesLoading ||
      areRecommendedCoursesLoading
    )
      return;

    const upcomingToAdd =
      upcomingCourses?.map((course) => ({
        ...course,
        enrollDate: course?.releaseDate
          ? moment.utc(course.releaseDate).set({ h: 9, m: 0 }).toDate()
          : undefined,
        isUpcoming: true,
      })) ?? [];

    const recommendedToAdd: Course[] =
      recommendedCourses?.filter(
        (course) => !upcomingToAdd?.find((x) => x.id === course.id),
      ) ?? [];

    setSelectedCourses([
      ...(plannerTemplateType === PlannerTemplateType.RECOMMENDED
        ? recommendedToAdd
        : []),
      ...(plannerType === PlannerType.TRAINING_PLAN ? upcomingToAdd : []),
    ]);
  }, [
    areUpcomingCoursesLoading,
    areRecommendedCoursesLoading,
    recommendedCourses,
    upcomingCourses,
    plannerTemplateType,
    plannerType,
  ]);

  const updateCourseDate = (date: Date, item: PlannerCRUDCourse) => {
    const enrollDate = moment(date).isSame(moment(), 'day')
      ? moment.utc(new Date())
      : moment.utc(date).set({ h: 9, m: 0 });

    const courses = selectedCourses.map((course: PlannerCRUDCourse) => {
      if (course.id === item.id && course.enrollDate === item.enrollDate) {
        return { ...course, enrollDate: enrollDate.toDate() };
      }
      return course;
    });

    setSelectedCourses(courses as PlannerCRUDCourse[]);
  };

  const updateCoursePeriod = (
    periodValue: number,
    periodUnit: CourseSchedulePeriodUnit,
    itemId: string,
  ) => {
    setSelectedCourses(() => {
      return selectedCourses.map((course: PlannerCRUDCourse) => {
        if (course.id === itemId) {
          return { ...course, periodValue, periodUnit };
        }

        return course;
      });
    });
  };

  const addUpcoming = (courses?: PlannerCRUDCourse[]) => {
    let upcomingToAdd = upcomingCourses?.map((course) => ({
      ...course,
      enrollDate: course?.releaseDate
        ? moment.utc(course.releaseDate).set({ h: 9, m: 0 }).toDate()
        : undefined,
      isUpcoming: true,
    }));

    if (courses?.length) {
      upcomingToAdd = upcomingToAdd?.filter(
        (course) => !courses.find((x) => x.id === course.id),
      );
    }

    if (upcomingToAdd) {
      setSelectedCourses([...(courses ?? []), ...upcomingToAdd]);
    }
  };

  const removeUpcoming = (courses?: PlannerCRUDCourse[]) => {
    if (courses?.length) {
      setSelectedCourses(courses.filter((course) => !course.isUpcoming));
    }
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const createPlanner = useCreatePlanner();
  const updatePlanner = useUpdatePlanner();

  const handleSavePlanner = async () => {
    if (!account?.id) return;

    const payload = {
      accountId: account.id,
      id: plan?.id,
      name: planName,
      targets: {
        branches: getTargetEntityIdsByType(
          includedEntities,
          CourseEntityEnrollEnum.BRANCHES,
        ),
        groups: getTargetEntityIdsByType(
          includedEntities,
          CourseEntityEnrollEnum.GROUPS,
        ),
      },
      courses: selectedCourses.map((course: any) => ({
        id: course.id,
        enrollDate:
          plannerType === PlannerType.TRAINING_PLAN
            ? course.enrollDate || moment().utc(true).toDate()
            : undefined,
        enrollOffset:
          plannerType === PlannerType.ONBOARDING_PLAN
            ? {
                value: course.periodValue || 0,
                unit: course.periodUnit || CourseSchedulePeriodUnit.DAYS,
              }
            : undefined,
      })),
      excludes: {
        branches: getTargetEntityIdsByType(
          excludedEntities,
          CourseEntityEnrollEnum.BRANCHES,
        ),
        groups: getTargetEntityIdsByType(
          excludedEntities,
          CourseEntityEnrollEnum.GROUPS,
        ),
        users: getTargetEntityIdsByType(
          excludedEntities,
          CourseEntityEnrollEnum.USERS,
        ),
      },
      type: plannerType || PlannerType.TRAINING_PLAN,
      autoEnroll,
    };

    setIsSubmitting(true);

    try {
      const action = !plan?.id ? createPlanner.create : updatePlanner.update;
      await action(payload);

      if (refetch) {
        await refetch();
      }
      plan?.id
        ? toast?.success(t('toast.success'), t('planner.updated'))
        : toast?.success(t('toast.success'), t('planner.created'));
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    } finally {
      onHide();
      setIsSubmitting(false);
    }
  };

  const [isDeleting, setIsDeleting] = useState(false);
  const deletePlanner = useDeletePlanner();
  const handleDeletePlanner = async () => {
    if (!account?.id || !plan?.id) return;

    setIsDeleting(true);

    try {
      await deletePlanner.delete({
        accountId: account.id,
        planId: plan?.id,
      });

      if (refetch) {
        await refetch();
      }

      toast?.success(t('toast.success'), t('planner.deleted'));
      onHide();
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    } finally {
      setIsDeleting(false);
    }
  };

  const headerTemplate = () => (
    <StyledHeaderContainer justify="space-between" wrap="wrap">
      <h1 className="flex-1">
        <EditableText
          key={planName}
          value={planName}
          saveValue={(value: string) => setPlanName(value)}
        />
      </h1>
      {plannerType === PlannerType.TRAINING_PLAN && (
        <StyledHeaderDiv>
          <p>
            {t(
              t('planner.upcomingCourses', {
                platformName: account?.platformSettings?.name,
              }),
            )}
          </p>
          <InputSwitch
            checked={autoEnroll}
            onChange={(e) => {
              setIsAutoEnrollConfirmVisibile(true);
              setAutoEnrollTemp(e.value);
            }}
            disabled={areUpcomingCoursesLoading}
          />
        </StyledHeaderDiv>
      )}
    </StyledHeaderContainer>
  );

  const isDeletionDisabled = () => {
    if (plannerType === PlannerType.TRAINING_PLAN) {
      return (
        !!plan?.staticCourseSchedules?.length &&
        plan?.staticCourseSchedules?.some((course) => !course.executedAt)
      );
    }

    return !!plan?.dynamicCourseSchedules?.length;
  };

  return (
    <StyledDialog
      header={headerTemplate}
      blockScroll
      visible={visible}
      onHide={onHide}
      draggable={false}
      closable={!isSubmitting}
      dismissableMask={!isSubmitting}
      closeOnEscape={!isSubmitting}
      data-testid="create-update-planner-modal"
    >
      <TargetEntitiesSelector
        className="planner"
        title={t('planner.enrollTo')}
        required
        includedEntities={includedEntities}
        setIncludedEntities={setIncludedEntities}
        excludedEntities={excludedEntities}
        setExcludedEntities={setExcludedEntities}
      />
      <StyledGrayFlexContainer direction="column" gap={12} width="100%">
        <PlannerCRUDTimeline
          items={selectedCourses}
          isOnboardingPlan={plannerType === PlannerType.ONBOARDING_PLAN}
          onItemAdd={(item: PlannerCRUDCourse) => {
            if (
              selectedCourses.find(
                (course) =>
                  course.id === item.id &&
                  course.enrollDate === item.enrollDate,
              )
            ) {
              item.enrollDate = moment(item.enrollDate).add(1, 'day').toDate();
            }

            setSelectedCourses([...selectedCourses, item]);
          }}
          onItemDateChange={updateCourseDate}
          onItemPeriodChange={updateCoursePeriod}
          onItemRemove={(item: PlannerCRUDCourse) =>
            setSelectedCourses(
              selectedCourses.filter(
                (course: PlannerCRUDCourse) =>
                  !(
                    course.id === item.id &&
                    course.enrollDate === item.enrollDate
                  ),
              ),
            )
          }
        />
      </StyledGrayFlexContainer>
      <StyledBottomDiv>
        <FlexContainer justify="space-between">
          {can(Actions.DELETE, Subjects.COURSE_PLANNER) && plan?.id && (
            <FlexContainer justify="flex-start" align="center" gap={12}>
              <AppButton
                type="outlined"
                icon="pi pi-trash"
                isDisabled={isDeletionDisabled()}
                state={
                  isDeleting ? LoadingStatuses.LOADING : LoadingStatuses.IDLE
                }
                onClick={() => {
                  setDialogData({
                    show: true,
                    type: 'confirmation',
                    header: t('dialog.delete'),
                    message: t('planner.delete.confirm'),
                    onAccept: handleDeletePlanner,
                  });
                }}
              />
              {isDeletionDisabled() ? (
                <StyledDeleteInfoSpan>
                  {t('planner.delete.info')}
                </StyledDeleteInfoSpan>
              ) : null}
            </FlexContainer>
          )}
          <FlexContainer justify="flex-end" align="center" gap={12}>
            <AppButton
              label={t('button.close')}
              severity="secondary"
              type="outlined"
              onClick={onHide}
              isDisabled={isSubmitting}
              className="mr-3"
              data-testid="create-update-planner-cancel"
            />
            <AppButton
              label={t('button.save')}
              isDisabled={
                isSubmitting ||
                !includedEntities?.length ||
                (plannerType === PlannerType.TRAINING_PLAN &&
                  !!selectedCourses.find((x) => !x.enrollDate)) ||
                !planName
              }
              state={
                isSubmitting ? LoadingStatuses.LOADING : LoadingStatuses.IDLE
              }
              data-testid="save-create-update-planner-cancel"
              onClick={handleSavePlanner}
            />
          </FlexContainer>
        </FlexContainer>
      </StyledBottomDiv>
      <Dialog
        blockScroll
        visible={isAutoEnrollConfirmVisibile}
        header={
          autoEnrollTemp
            ? t('planner.toggle.autoEnrollOn.warn.heading', {
                platformName: account?.platformSettings?.name,
              })
            : t('planner.toggle.autoEnrollOff.warn.heading', {
                platformName: account?.platformSettings?.name,
              })
        }
        draggable={false}
        data-testid="auto-enroll-confirm-modal"
        onHide={() => setIsAutoEnrollConfirmVisibile(false)}
        style={{ width: '600px' }}
      >
        {autoEnrollTemp ? (
          <>
            <p>
              {t('planner.toggle.autoEnrollOn.warn.text.1', {
                platformName: account?.platformSettings?.name,
              })}
            </p>
            <p>
              {t('planner.toggle.autoEnrollOn.warn.text.2', {
                platformName: account?.platformSettings?.name,
              })}
            </p>
          </>
        ) : (
          <>
            <p>
              {t('planner.toggle.autoEnrollOff.warn.text.1', {
                platformName: account?.platformSettings?.name,
              })}
            </p>
            <p>
              {t('planner.toggle.autoEnrollOff.warn.text.2', {
                platformName: account?.platformSettings?.name,
              })}
            </p>
          </>
        )}
        <FlexContainer justify="flex-end" className="mt-5">
          <>
            <AppButton
              label={t('button.cancel')}
              type="outlined"
              className="mr-3"
              onClick={() => {
                setIsAutoEnrollConfirmVisibile(false);
              }}
            />
            <AppButton
              label={
                autoEnrollTemp ? t('generic.enable') : t('generic.disable')
              }
              onClick={() => {
                setAutoEnroll(autoEnrollTemp);
                setIsAutoEnrollConfirmVisibile(false);
                autoEnrollTemp
                  ? addUpcoming(selectedCourses)
                  : removeUpcoming(selectedCourses);
              }}
            />
          </>
        </FlexContainer>
      </Dialog>
    </StyledDialog>
  );
};
