import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { FeatureFlagsDatatable } from '@/components/feature-flags/datatables/FeatureFlagsDatatable';
import { useAppSelector } from '@/hooks/store';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { FlexContainer } from '@/ui/styled-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

const pathItems = (account: Account, t: TranslationFunctionType) => [
  {
    label: account?.name,
    url: RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('features'),
    url: RedirectPaths[RedirectPathsEnum.FEATURES](),
    template: AppBreadCrumbTemplate,
  },
];

export const FeaturesPage: React.FC = () => {
  const { t } = useTranslation();
  const account = useAppSelector(selectCurrentAccount);

  return (
    <FlexContainer direction="column" align="flex-start">
      <div className="w-full">
        <AppBreadCrumb model={pathItems(account as Account, t)} />
        <h1>{t('features')}</h1>

        <FeatureFlagsDatatable />
      </div>
    </FlexContainer>
  );
};
