import { FiltersType } from '@/api/types';
import { client } from '@/client';
import { MessageResponseModel } from '@/client/models';
import {
  DeleteSurveyMutation,
  DeleteSurveyRequest,
  ListSurveyCategoriesResponse,
  ListSurveyCategoryQuestionsResponse,
  ListSurveyCategoryRecommendationsResponse,
  ListSurveyFeedbackResponse,
  ListSurveysResponse,
  SecurityCultureEmailMutation,
  SecurityCultureSendSurveyRequest,
  SecurityCultureSendSurveyResponse,
  SecurityCultureSurvey,
  SecurityCultureUploadSurveyMutation,
  SecurityCultureUploadSurveyRequest,
  SurveyCategory,
  SurveyCategoryEnum,
} from '@/client/security-culture/types';
import {
  useMutation,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { GenericForbiddenApiError } from './types';

const singleQueryKey = 'survey';
const queryKey = 'surveys';

export const useSendEmail = (): {
  send: (
    payload: SecurityCultureSendSurveyRequest,
  ) => Promise<SecurityCultureSendSurveyResponse>;
} & SecurityCultureEmailMutation => {
  const { mutateAsync, ...rest } = useMutation<
    SecurityCultureSendSurveyResponse,
    GenericForbiddenApiError,
    SecurityCultureSendSurveyRequest
  >('sendSecurityCultureEmails', (payload: SecurityCultureSendSurveyRequest) =>
    client.securityCulture.sendServeyMails(payload),
  );

  return { send: mutateAsync, ...(rest as any) };
};

export const useUploadSurvey = (): {
  upload: (
    payload: SecurityCultureUploadSurveyRequest,
  ) => Promise<SecurityCultureSurvey>;
} & SecurityCultureUploadSurveyMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    SecurityCultureUploadSurveyRequest
  >('uploadSurvey', (payload: SecurityCultureUploadSurveyRequest) =>
    client.securityCulture.uploadSurvey(payload),
  );

  return { upload: mutateAsync, ...(rest as any) };
};

export const useEditSurvey = (): {
  edit: (
    payload: SecurityCultureUploadSurveyRequest,
  ) => Promise<SecurityCultureSurvey>;
} & SecurityCultureUploadSurveyMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    SecurityCultureUploadSurveyRequest
  >('editSurvey', (payload: SecurityCultureUploadSurveyRequest) =>
    client.securityCulture.editSurvey(payload),
  );

  return { edit: mutateAsync, ...(rest as any) };
};

export const useDeleteSurvey = (): {
  delete: (payload: DeleteSurveyRequest) => Promise<MessageResponseModel>;
} & DeleteSurveyMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    DeleteSurveyRequest
  >('deleteSurvey', (payload: DeleteSurveyRequest) =>
    client.securityCulture.deleteSurvey(payload),
  );

  return { delete: mutateAsync, ...(rest as any) };
};

export const useSurveys = (
  params: UseQueryOptions & {
    accountId?: string;
    take?: number;
    skip?: number;
    sort?: string[];
    filters?: FiltersType;
  },
): UseQueryResult<ListSurveysResponse> & {
  surveys: ListSurveysResponse | undefined;
} => {
  const {
    accountId,
    take = 5,
    skip = 0,
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [queryKey, take, skip, JSON.stringify(sort), JSON.stringify(filters)],
    () => client.securityCulture.getSurveys(accountId, params),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    surveys: data,
    ...(rest as UseQueryResult<ListSurveysResponse>),
  };
};

export const useSurvey = (
  params: UseQueryOptions & {
    accountId?: string;
    surveyId?: string;
  },
): UseQueryResult<SecurityCultureSurvey> & {
  survey: SecurityCultureSurvey | undefined;
} => {
  const {
    accountId,
    surveyId,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [singleQueryKey, surveyId],
    () =>
      surveyId
        ? client.securityCulture.getSurvey(accountId, surveyId)
        : undefined,
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as UseQueryResult<SecurityCultureSurvey>),
    },
  );

  return {
    survey: data,
    ...(rest as UseQueryResult<SecurityCultureSurvey>),
  };
};

export const useSurveyCategories = (
  params: UseQueryOptions & {
    accountId?: string;
    surveyId?: string;
  },
): UseQueryResult<ListSurveyCategoriesResponse> & {
  categories: ListSurveyCategoriesResponse | undefined;
} => {
  const {
    accountId,
    surveyId,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [`${singleQueryKey}-categories`, surveyId],
    () =>
      surveyId
        ? client.securityCulture.getSurveyCategories(accountId, surveyId)
        : undefined,
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as UseQueryResult<ListSurveyCategoriesResponse>),
    },
  );

  return {
    categories: data,
    ...(rest as UseQueryResult<ListSurveyCategoriesResponse>),
  };
};

export const useSurveyCategory = (
  params: UseQueryOptions & {
    accountId?: string;
    surveyId?: string;
    categoryName?: SurveyCategoryEnum;
  },
): UseQueryResult<SurveyCategory> & {
  surveyCategory: SurveyCategory | undefined;
} => {
  const {
    accountId,
    surveyId,
    categoryName,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    ['surveyCategory', accountId, surveyId, categoryName],
    () =>
      accountId && surveyId && categoryName
        ? client.securityCulture.getSurveyCategory(
            accountId,
            surveyId,
            categoryName,
          )
        : undefined,
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as UseQueryResult<SurveyCategory>),
    },
  );

  return {
    surveyCategory: data,
    ...(rest as UseQueryResult<SurveyCategory>),
  };
};

export const useSurveyCategoryQuestions = (
  params: UseQueryOptions & {
    accountId?: string;
    surveyId?: string;
    categoryName?: SurveyCategoryEnum;
    take?: number;
    skip?: number;
    sort?: string[];
    filters?: FiltersType;
  },
): UseQueryResult<ListSurveyCategoryQuestionsResponse> & {
  questions: ListSurveyCategoryQuestionsResponse | undefined;
} => {
  const {
    accountId,
    surveyId,
    categoryName,
    take = 5,
    skip = 0,
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [
      `${queryKey}CategoryQuestions`,
      accountId,
      surveyId,
      categoryName,
      take,
      skip,
      JSON.stringify(sort),
      JSON.stringify(filters),
    ],
    () =>
      accountId && surveyId && categoryName
        ? client.securityCulture.getSurveyCategoryQuestions({
            ...params,
            accountId,
            surveyId,
            categoryName,
          })
        : undefined,
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    questions: data,
    ...(rest as UseQueryResult<ListSurveyCategoryQuestionsResponse>),
  };
};

export const useSurveyCategoryRecommendations = (
  params: UseQueryOptions & {
    accountId?: string;
    surveyId?: string;
    categoryName?: SurveyCategoryEnum;
    take?: number;
    skip?: number;
    sort?: string[];
    filters?: FiltersType;
  },
): UseQueryResult<ListSurveyCategoryRecommendationsResponse> & {
  recommendations: ListSurveyCategoryRecommendationsResponse | undefined;
} => {
  const {
    accountId,
    surveyId,
    categoryName,
    take = 5,
    skip = 0,
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [
      `${queryKey}CategoryRecommendations`,
      accountId,
      surveyId,
      categoryName,
      take,
      skip,
      JSON.stringify(sort),
      JSON.stringify(filters),
    ],
    () =>
      accountId && surveyId && categoryName
        ? client.securityCulture.getSurveyCategoryRecommendations({
            ...params,
            accountId,
            surveyId,
            categoryName,
          })
        : undefined,
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    recommendations: data,
    ...(rest as UseQueryResult<ListSurveyCategoryRecommendationsResponse>),
  };
};

export const useSurveyFeedback = (
  params: UseQueryOptions & {
    accountId?: string;
    surveyId?: string;
    take?: number;
    skip?: number;
    sort?: string[];
    filters?: FiltersType;
  },
): UseQueryResult<ListSurveyFeedbackResponse> & {
  feedback: ListSurveyFeedbackResponse | undefined;
} => {
  const {
    accountId,
    surveyId,
    take = 5,
    skip = 0,
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [
      `${queryKey}Feedback`,
      accountId,
      surveyId,
      take,
      skip,
      JSON.stringify(sort),
      JSON.stringify(filters),
    ],
    () =>
      accountId && surveyId
        ? client.securityCulture.getSurveyFeedback({
            ...params,
            accountId,
            surveyId,
          })
        : undefined,
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as UseQueryResult<ListSurveyFeedbackResponse>),
    },
  );

  return {
    feedback: data,
    ...(rest as UseQueryResult<ListSurveyFeedbackResponse>),
  };
};
