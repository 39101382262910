import { FiltersType } from '@/api/types';
import { client } from '@/client';
import {
  Campaign,
  CampaignSummary,
  CloneCampaignRequest,
  GetCampaignRequest,
  ListCampaignsResponse,
  ListCampaignUserResultsResponse,
  MigrationCampaignRequest,
  SaveCampaignRequest,
  SendTestEmail,
  UpdateCampaignAction,
  UpdateCampaignRequest,
} from '@/client/campaigns';
import { MessageResponseModel } from '@/client/models';
import {
  CloneCampaignMutation,
  DeleteCampaignMutation,
  GenericForbiddenApiError,
  SaveCampaignMutation,
  SendTestEmailCampaignMutation,
} from '@/hooks/query';
import {
  useMutation,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';

const queryKey = 'campaigns';
const singleQueryKey = 'campaign';

export const useCampaigns = (
  params: UseQueryOptions & {
    withTemplates?: 0 | 1;
    take?: number;
    skip?: number;
    sort?: string[];
    filters?: FiltersType;
  },
): UseQueryResult<ListCampaignsResponse> & {
  campaigns: ListCampaignsResponse | undefined;
} => {
  const {
    take = 5,
    skip = 0,
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [queryKey, take, skip, JSON.stringify(sort), JSON.stringify(filters)],
    () => client.campaigns.getCampaigns(params),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    campaigns: data,
    ...(rest as UseQueryResult<ListCampaignsResponse>),
  };
};

export const useCampaignUserResults = (
  params: UseQueryOptions & {
    take?: number;
    skip?: number;
    sort?: string[];
    filters?: FiltersType;
  },
  campaignId?: string,
): UseQueryResult<ListCampaignUserResultsResponse> & {
  campaignUserResults: ListCampaignUserResultsResponse | undefined;
} => {
  const {
    take = 10,
    skip = 0,
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [queryKey, take, skip, JSON.stringify(sort), JSON.stringify(filters)],
    () => client.campaigns.getCampaignUserResults(params, campaignId),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    campaignUserResults: data,
    ...(rest as UseQueryResult<ListCampaignUserResultsResponse>),
  };
};

export const useCampaignsSummary = (
  params: UseQueryOptions & {
    accountId?: string;
  },
): UseQueryResult<CampaignSummary> & {
  campaignsSummary: CampaignSummary | undefined;
} => {
  const {
    accountId,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [singleQueryKey, accountId],
    () =>
      accountId ? client.campaigns.getCampaignsSummary(accountId) : undefined,
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as UseQueryResult<CampaignSummary>),
    },
  );

  return {
    campaignsSummary: data,
    ...(rest as UseQueryResult<CampaignSummary>),
  };
};

export const useCampaign = (
  campaignId?: string,
  params?: UseQueryOptions & GetCampaignRequest,
): UseQueryResult<Campaign> & {
  campaign: Campaign | undefined;
} => {
  const {
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    filters,
    ...restOptions
  } = params || {};

  const { data, ...rest } = useQuery(
    [singleQueryKey, campaignId, JSON.stringify(filters)],
    () =>
      campaignId ? client.campaigns.getCampaign(campaignId, params) : undefined,
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as UseQueryResult<Campaign>),
    },
  );

  return {
    campaign: data,
    ...(rest as UseQueryResult<Campaign>),
  };
};

export const useSaveCampaign = (): {
  create: (payload: SaveCampaignRequest) => Promise<Campaign>;
} & SaveCampaignMutation => {
  const { mutateAsync, ...rest } = useMutation<
    Campaign,
    GenericForbiddenApiError,
    SaveCampaignRequest
  >('createCampaign', (payload: SaveCampaignRequest) =>
    client.campaigns.saveCampaign(payload),
  );

  return { create: mutateAsync, ...(rest as any) };
};

export const useCopyCampaign = (): {
  copy: (payload: CloneCampaignRequest) => Promise<Campaign>;
} & CloneCampaignMutation => {
  const { mutateAsync, ...rest } = useMutation<
    Campaign,
    GenericForbiddenApiError,
    CloneCampaignRequest
  >('copyCampaign', (payload: CloneCampaignRequest) =>
    client.campaigns.copyCampaign(payload),
  );

  return { copy: mutateAsync, ...(rest as any) };
};

export const useCloneCampaign = (): {
  clone: (payload: CloneCampaignRequest) => Promise<Campaign>;
} & CloneCampaignMutation => {
  const { mutateAsync, ...rest } = useMutation<
    Campaign,
    GenericForbiddenApiError,
    CloneCampaignRequest
  >('cloneCampaign', (payload: CloneCampaignRequest) =>
    client.campaigns.cloneCampaign(payload),
  );

  return { clone: mutateAsync, ...(rest as any) };
};

export const useCampaignAction = (): {
  updateStatus: (
    payload: UpdateCampaignAction,
  ) => Promise<MessageResponseModel>;
} & UseQueryResult => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    UpdateCampaignAction
  >('updateCampaignStatus', (payload) =>
    client.campaigns.saveCampaignAction(payload),
  );

  return { updateStatus: mutateAsync, ...(rest as any) };
};

export const useSendTestEmail = (): {
  send: (
    payload: SendTestEmail,
  ) => Promise<{ jobId: string } & MessageResponseModel>;
} & SendTestEmailCampaignMutation => {
  const { mutateAsync, ...rest } = useMutation<
    { jobId: string } & MessageResponseModel,
    GenericForbiddenApiError,
    SendTestEmail
  >('sendTestEmailCampaign', (payload: SendTestEmail) =>
    client.campaigns.sendTestEmail(payload),
  );

  return { send: mutateAsync, ...(rest as any) };
};

export const useUpdateCampaign = (): {
  update: (payload: UpdateCampaignRequest) => Promise<Campaign>;
} & SaveCampaignMutation => {
  const { mutateAsync, ...rest } = useMutation<
    Campaign,
    GenericForbiddenApiError,
    UpdateCampaignRequest
  >('updateCampaign', (payload: UpdateCampaignRequest) =>
    client.campaigns.updateCampaign(payload),
  );

  return { update: mutateAsync, ...(rest as any) };
};

export const useDeleteCampaign = (): {
  delete: (campaignId: string) => Promise<MessageResponseModel>;
} & DeleteCampaignMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    string
  >('deleteCampaign', (campaignId: string) =>
    client.campaigns.deleteCampaign(campaignId),
  );

  return { delete: mutateAsync, ...(rest as any) };
};

export const useMigrateCampaign = (): {
  migrate: (payload: MigrationCampaignRequest) => Promise<MessageResponseModel>;
} => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    MigrationCampaignRequest
  >('migrateCampaign', (payload: MigrationCampaignRequest) =>
    client.campaigns.migrateCampaign(payload),
  );

  return { migrate: mutateAsync, ...(rest as any) };
};
