import { client } from '@/client';
import {
  AccountFeatureFlag,
  FeatureFlag,
  FeatureFlagRoles,
} from '@/client/feature-flags/types';
import { LoadingStatuses } from '@/common/constants';
import { allRolesOptions } from '@/common/constants/roles';
import { FormikMultiSelect } from '@/components/form';
import { useRoles } from '@/hooks/query';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import {
  selectCurrentAccount,
  setCurrentAccount,
} from '@/store/features/account';
import { setCurrentUser } from '@/store/features/users';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { Field, Form, Formik } from 'formik';
import { Dialog, DialogProps } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { featureFlagRolesValidationSchema } from '../validations';

const StyledDialog = styled(Dialog)`
  width: 500px;
`;

type FeatureFlagRolesModalProps = {
  currentFeature?: AccountFeatureFlag | FeatureFlag | null;
  isSubmitting?: boolean;
  isAccount?: boolean;
  onSubmit: (feature: any, roles?: string[]) => void;
} & DialogProps;

export const FeatureFlagRolesModal: React.FC<FeatureFlagRolesModalProps> = ({
  currentFeature,
  isSubmitting,
  isAccount,
  onSubmit,
  onHide,
  visible,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentAccount = useAppSelector(selectCurrentAccount);

  const [initialValues, setInitialValues] = useState<FeatureFlagRoles>({
    featureRoles: [],
  });

  const { roles } = useRoles({
    take: 20,
  });

  useEffect(() => {
    if (isAccount) {
      const accountFeatureRoles =
        (currentFeature as AccountFeatureFlag)?.roles?.map(
          ({ code }) => code,
        ) || [];

      setInitialValues({
        featureRoles: accountFeatureRoles,
      });
    } else {
      const featureDefaultRoles = (currentFeature as FeatureFlag)
        ?.defaultAccountSettings?.roles;

      if (!featureDefaultRoles) return;

      setInitialValues({
        featureRoles: featureDefaultRoles,
      });
    }
  }, [currentFeature]);

  const handleEditFeatureFlag = async (data: FeatureFlagRoles) => {
    if (!currentFeature) return;
    const rolesIds = data.featureRoles
      .map((role) => roles?.result.find(({ code }) => code === role)?.id || '')
      .filter(Boolean);

    await onSubmit(currentFeature, isAccount ? rolesIds : data.featureRoles);

    // Refetch permissions
    const response = await client.accounts.getAccount(currentAccount?.id);
    dispatch(setCurrentAccount(response));

    const user = await client.default.getMe();
    dispatch(setCurrentUser(user));
  };

  return (
    <StyledDialog
      blockScroll
      visible={visible}
      header={
        <h1>
          {t(
            `features.${
              isAccount
                ? (currentFeature as AccountFeatureFlag)?.featureFlag?.key
                : (currentFeature as FeatureFlag)?.key
            }`,
          )}
        </h1>
      }
      onHide={onHide}
      draggable={false}
      data-testid="features-roles-modal"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={featureFlagRolesValidationSchema(t)}
        validateOnMount
        onSubmit={handleEditFeatureFlag}
      >
        {({ errors }) => {
          return (
            <Form>
              <FlexContainer justify="flex-start" direction="column">
                <div className="field w-full">
                  <Field
                    id="feature-roles"
                    name="featureRoles"
                    label={t('generic.role')}
                    className="w-full"
                    display="chip"
                    placeholder={t('roles.select')}
                    required
                    component={FormikMultiSelect}
                    options={allRolesOptions(t)}
                    optionLabel="label"
                    dataKey="id"
                  />
                </div>
              </FlexContainer>

              <FlexContainer justify="flex-end" className="mt-2">
                <AppButton
                  label={t('button.cancel')}
                  severity="secondary"
                  type="outlined"
                  onClick={onHide}
                  className="mr-3"
                  isDisabled={isSubmitting}
                />
                <AppButton
                  label={t('button.save')}
                  state={
                    isSubmitting
                      ? LoadingStatuses.LOADING
                      : LoadingStatuses.IDLE
                  }
                  isSubmit
                  isDisabled={isSubmitting || !!Object.keys(errors).length}
                />
              </FlexContainer>
            </Form>
          );
        }}
      </Formik>
    </StyledDialog>
  );
};
