import { handleAxiosError } from '@/api/helpers';
import { client } from '@/client';
import { AccountFeatureFlag, FeatureFlag } from '@/client/feature-flags/types';
import { LoadingStatuses, TableNamesEnum } from '@/common/constants';
import { roleTranslations } from '@/common/constants/roles';
import { DialogContext } from '@/common/context';
import {
  DataTable,
  DataTableColumnsMultiselect,
  DataTableColumnType,
  DataTableToolbar,
} from '@/components/tables/crud';
import { useFeatureFlagOverview } from '@/hooks/query/feature-flags.hooks';
import { useAppDispatch } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { useToast } from '@/hooks/useToast';
import { setCurrentUser } from '@/store/features/users';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { AxiosError } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type FeatureFlagsModalProps = {
  currentFeature?: FeatureFlag | null;
  setIsSubmitting?: (isSubmitting: boolean) => void;
  isSubmitting?: boolean;
};

export const FeatureFlagOverviewDatatable: React.FC<FeatureFlagsModalProps> = ({
  currentFeature,
  setIsSubmitting,
  isSubmitting,
}) => {
  const { t } = useTranslation();
  const { setDialogData } = useContext(DialogContext);
  const { skip, take, sort, apiFilters, onSort, onPage } = useTable();

  const dispatch = useAppDispatch();
  const toast = useToast();

  const { isLoading, flags, refetch } = useFeatureFlagOverview(
    currentFeature?.id,
    {
      filters: apiFilters,
      take,
      skip,
      sort: sort && sort.length > 0 ? [sort.join(',')] : [],
    },
  );

  useEffect(() => {
    if (currentFeature) {
      refetch();
    }
  }, [currentFeature]);

  const [selectedAccount, setSelectedAccount] = useState<string | null>(null);

  const columns: DataTableColumnType[] = [
    {
      field: 'account.name',
      header: t('account'),
      sortable: false,
      filterable: false,
      style: {
        minWidth: '180px',
      },
      render: (row: AccountFeatureFlag) => <span>{row?.account?.name}</span>,
    },
    ...((currentFeature?.isRoleBased
      ? [
          {
            field: 'roles',
            header: t('generic.role'),
            sortable: false,
            filterable: false,
            render: (row: AccountFeatureFlag) =>
              row?.roles?.length && row.featureFlag?.isRoleBased ? (
                row.roles?.map((role) => roleTranslations(role, t))?.join(', ')
              ) : (
                <div className="group-row">&#8212;</div>
              ),
          },
        ]
      : []) as DataTableColumnType[]),
    {
      field: 'actions',
      header: t('generic.actions'),
      sortable: false,
      filterable: false,
      style: {
        width: '80px',
        textAlign: 'center',
      },
      render: (row: AccountFeatureFlag) => (
        <AppButton
          severity="secondary"
          type="text"
          icon="pi pi-trash"
          state={
            selectedAccount === row.id && isSubmitting
              ? LoadingStatuses.LOADING
              : LoadingStatuses.IDLE
          }
          onClick={() =>
            setDialogData({
              show: true,
              type: 'confirmation',
              header: t('feature.confirm.delete'),
              message: t('feature.confirm.delete.message'),
              closeImmediatelyAfterAccept: true,
              onAccept: async () => {
                if (!currentFeature || !row.account?.id) return;
                try {
                  setSelectedAccount(row.id);
                  setIsSubmitting?.(true);
                  await client.featureFlags.updateFeatureFlags(
                    currentFeature.id,
                    {
                      accountMappings: [
                        {
                          id: row.account.id,
                          roles: [],
                          active: false,
                        },
                      ],
                    },
                  );

                  refetch();

                  // Refetch permissions
                  const user = await client.default.getMe();
                  dispatch(setCurrentUser(user));

                  toast?.success(
                    t('toast.success'),
                    t('feature.delete.success'),
                  );
                } catch (e) {
                  handleAxiosError(e as AxiosError, toast);
                } finally {
                  setIsSubmitting?.(false);
                  setSelectedAccount(null);
                }
              },
            })
          }
        />
      ),
    },
  ];

  // Set the preselected columns
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);

  const defaultVisibleColumns = columns.map((column) => column.field);
  const alwaysVisibleColumns = ['account.name', 'actions'];
  const toolbar = (
    <DataTableToolbar>
      <FlexContainer
        justify="space-between"
        gap={8}
        align="flex-start"
        wrap="wrap"
      >
        <div />
        <DataTableColumnsMultiselect
          columns={columns}
          tableName={TableNamesEnum.FEATURE_OVERVIEW_LIST}
          visibleColumns={visibleColumns}
          setVisibleColumns={setVisibleColumns}
          defaultVisibleColumns={defaultVisibleColumns}
          alwaysVisibleColumns={alwaysVisibleColumns}
        />
      </FlexContainer>
    </DataTableToolbar>
  );

  return (
    <>
      <DataTable
        data={flags?.result}
        count={flags?.count}
        isLoading={isLoading}
        columns={columns}
        visibleColumns={visibleColumns}
        toolbar={toolbar}
        rows={take}
        skip={skip}
        onPage={onPage}
        onSort={onSort}
        sort={sort}
      />
    </>
  );
};
