import { AppIconTooltip } from '@/ui/icon-tooltip';
import classNames from 'classnames';
import { get } from 'lodash';
import { Dropdown, DropdownProps } from 'primereact/dropdown';
import React from 'react';

export type FormikDropdownProps = {
  field: {
    name: string;
    value: object;
    onChange: () => void;
    onBlur: () => void;
  };
  form: { touched: Record<string, any>; errors: Record<string, any> };
  label?: string;
  tooltip?: string;
  id?: string;
};

export const FormikDropdown: React.FC<FormikDropdownProps & DropdownProps> = ({
  field,
  form,
  label,
  required,
  className,
  tooltip,
  id,
  ...rest
}) => {
  const fieldError = get(form.errors, field.name);
  const fieldTouched = get(form.touched, field.name);

  return (
    <>
      <label
        htmlFor={field.name}
        className={classNames({
          'p-error': fieldTouched && !!fieldError,
        })}
      >
        {label}
        {required ? <span className="red"> *</span> : ''}
      </label>
      {tooltip && id && (
        <AppIconTooltip target={`${id}-icon`} content={tooltip} />
      )}
      <Dropdown
        {...field}
        className={className}
        value={field.value ?? undefined}
        virtualScrollerOptions={
          rest?.options?.length && rest?.options?.length > 10
            ? { itemSize: 50, autoSize: true }
            : undefined
        }
        {...rest}
        dataKey="id"
      />
      {fieldTouched && !!fieldError ? (
        <small className="p-error">{fieldError}</small>
      ) : null}
    </>
  );
};
